import * as React from "react"

function UserPhotoSvg(props) {
    return (
        <svg
            width={39}
            height={35}
            viewBox="0 0 39 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M34.5 4.5h-5.944l-2.325-2.531A3.733 3.733 0 0023.475.75h-7.95c-1.05 0-2.063.45-2.775 1.219L10.444 4.5H4.5A3.761 3.761 0 00.75 8.25v22.5A3.761 3.761 0 004.5 34.5h30a3.761 3.761 0 003.75-3.75V8.25A3.761 3.761 0 0034.5 4.5zm-15 24.375c-5.175 0-9.375-4.2-9.375-9.375s4.2-9.375 9.375-9.375 9.375 4.2 9.375 9.375-4.2 9.375-9.375 9.375z"
                fill="#fff"
            />
        </svg>
    )
}

export default UserPhotoSvg

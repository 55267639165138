import React, {useState} from "react";
import Wrapper from "views/Wrapper";
import ContainerBigWrapper from "views/shared/ContainerBigWrapper";
import VideoComponent from "views/shared/VideoComponent";
import VideoCard from "./VideoCard";
import "./style.scss";
import HorizontalScroll from 'react-scroll-horizontal'
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import ButtonWithSvg from "../shared/ButtonWithSvg";

const data = [{
    index: '01',
    title: 'NEWLY SANDED',
    description: 'Wood flooring should be newly sanded to completion with 100-120 grit. Water pop the grain for ' + 'deeper penetration of Oil or intensive coloring as necessary otherwise, prepare the wood floor surface by ' + 'thoroughly vacuuming and tacking the wood flooring with a damp cloth.', // video: 'https://youtu.be/Bzdu2CIJHow?list=PLHRxjv8rMTwZKqpi75MhPERK7IwMUbUrY',
    // rightPice: true,
    withOutVideo: true
}, {
    title: 'MAINTENANCE COAT',
    description: 'If performing a maintenance coat, prepare the Prefinished wood floor surface with Vesting Intensive ' + 'Cleaner. Add 75 cc of Intensive Cleaner to approximately 10 liters of lukewarm water. (½ cup Intensive Cleaner per' + ' 2 ½ gallons of water). Increase the amount of Intensive Cleaner on very dirty floors. Clean the floor with a well-wrung ' + 'cloth. Leave the wood floor to dry. Depending on the surface condition of the floors, you may need additionally buffing with a ' + 'maroon abrasive pad or lightly screening the surface of the floors before applying Vesting LED Hardwax Oil over the surface. Once ' + 'all surfaces ready for LED oil application, make sure to cover windows from direct sunlight and sunlight reflection prior ' + 'application of the LED oil‘s.', // video: 'https://youtu.be/YWOeM_Qarpg',
    // leftPice: true,
    withOutVideo: true,
}, {
    index: '02',
    title: 'Shake and thoroughly mix',
    description: 'Shake and thoroughly mix the container of Vesting LED Hardwax Oil, before application. Apply the oil with a very thin coat using a low nap ⅜ roller or use a buffer with a red pad to apply the oil.\n' + 'Work the oil into the wood with a red pad with either s buffer or manually hand polish the floor until it appears saturated with oil.', // video: 'https://youtu.be/tJPCUP-c2Zg?list=PLHRxjv8rMTwZKqpi75MhPERK7IwMUbUrY',
    // rightPice: true,
    withOutVideo: true,
}, {
    index: '03',
    title: 'Remove all excess oil',
    description: 'Remove all excess oil with lint-free cloths, or do squeegee of the oil into the next section that needs to be oiled — Buff the ' + 'flooring with a white pad and lint-free cloths under the white polishing pad. The floor should appear dry, and all excess oil removed after' + ' polishing. Use SMELT LED light to instantly dry and cure the finish on each section, avoid direct LED light to your eyes, and wear LED light ' + 'protection goggles. Continue each section until finishing the whole floor.',
    withOutVideo: true,
}, {
    index: '04',
    title: 'To apply the second coat',
    description: 'To apply the second coat of clear or color oil, buff the floor with a white polishing pad by adding ' + 'additional oil onto the wood floor and remove all excess oil as in step 3. Use SMELT LED light to instantly ' + 'dry and cure the LED Hardwax Oil.Cover walking areas first with Brown Paper, tape the seams, and put on top ' + 'of it Corrugated Cardboard or Masonite boards as necessary to protect the finished wood flooring from other trades or movers.',
    withOutVideo: true,
}, {
    index: '05', title: 'Clean all tools in Solvent', description: 'Clean all tools in Solvent', withOutVideo: true,
},]

const HowToUse = () => {
    const [more, setMore] = useState(false);
    const handleViewMore = () => setMore(!more);

    return (<Wrapper>
        <ContainerBigWrapper>
            <div className='how-to-use'>
                <h2 className='how-to-use-title'>
                    How to Apply LED-Cured Oil on a Newly Sanded Floor
                </h2>
                <div className='bigVideo'>
                    <VideoComponent video={'https://www.youtube.com/watch?v=9vt0VCIsLv8'}/>
                </div>
                <div className='coat-system'>
                    <h3 className='coat-system-title'>
                        The LED-cured finish is a two-coat system.
                    </h3>
                    <div className='system-desc'>
                        <span>1) Sand (using a correct grit sequence) to 120-grit.</span>
                        Ending sanding at 120 before applying the oil gives the wood the correct size of scratch for
                        the oil finish—the scratch is big enough to create a pocket for the oil to collect in and
                        absorb into the wood. Ending with a lower grit creates scratches that are too prominent and
                        will be visible. Ending with a grit higher than 120 burnishes the grain of the wood and
                        doesn’t allow the oil to absorb into the wood. It is extremely important to
                        <a href='https://www.woodfloorbusiness.com/sanding/techniques/article/15287598/wood-floor-sanding-101-part-1'>
                            use a correct sanding sequence.
                        </a>
                    </div>
                    <div className='system-desc'>
                        <span>2) If you want a deeper color, water-pop</span>
                        Water-pop after you sand and before applying the oil. Use a bug sprayer or a wet towel on an
                        applicator to get the surface of the floor evenly wet, then go over the entire floor with a
                        T-bar to even it out and wipe off any excess water (be careful not to leave any puddles,
                        which can pull the tannic acids in floors such as white oak and show as yellow spots). Just
                        as when applying finish, start at a far wall and do not walk across the area you
                        water-popped, and be particularly careful to not drag anything hard over the water-popped
                        surface, which will close the grain and show as a lighter area in the floor. Popping the
                        grain opens the grain of the wood, allowing it to absorb more finish and therefore giving a
                        deeper color. Take moisture meter readings of the floor before water-popping the floor, then
                        wait to coat the floor until the moisture reading is the same as it was before
                        water-popping—overnight is usually a safe amount of time for the floor to dry. Then tack the
                        floor and vacuum it before applying the finish.
                    </div>
                    {more && <>
                        <div className='system-desc'>
                            <span>3) If direct sunlight will hit the floor, cover windows with dark plastic or paper.</span>
                        </div>
                        <div className='system-desc'>
                            <span>4) Apply the oil on the floor. </span>
                            Ensure the finish is evenly mixed, including any waxes on the lid of the finish. While
                            wearing clean, non-marking shoes, use a roller with a 1/4-inch nap to spread the finish
                            evenly over the floor (spread by hand at wall lines). Then use a red pad on the buffer
                            to further spread the finish into the grain (spread by hand with part of a red pad at
                            wall lines). There is not one set spread rate for the oil finish—spread rates vary by
                            species, the grain of the wood, if the flooring has bevels, whether the wood is
                            wire-brushed, etc. While buffing, if there is a lot of oil being pushed around the top
                            of the floor, there is too much product on the floor. If you don’t see any finish being
                            pushed by the buffer, there is not enough oil on the floor. When in doubt, err on the
                            side of a little more finish instead of too little, which will not protect the floor
                            enough.
                        </div>
                        <div className='system-desc'>
                            <span>5) Wipe the finish with a white pad. </span>
                            After buffing with the red pad, use a white pad on the buffer to wipe off any excess
                            finish that is still on the floor (use a white pad by hand at wall lines). If there is
                            too much oil on the floor, wipe off the excess with a towel or piece of carpeting (a
                            squeegee may be used on floors without gaps). Then use the white pad on a small
                            oscillating buffer to ensure even application of the oil.
                        </div>
                        <div className='system-desc'>
                            <span>6) Cure the finish with the LED light. </span>
                            Wear UV-protecting glasses to protect your eyes and never point the light at yourself or
                            anyone else. Be sure your shoes do not have any wet finish on them from coating the
                            floor—wet spots tracked onto the floor will be visible when the finish is cured. The
                            bottoms of your shoes can be cured by going over them with the light; you can also cure
                            any wet finish in your walk-on/walk-off area with the light. Cure the floor in front of
                            you with the light so that you are always walking on cured finish. The light should be
                            1–4 inches from the floor; hold the front of the light a little higher to feather the
                            light into the uncured area. Go back and forth over the same path. Then start curing a
                            new row,
                            <span>
                                        always overlapping the previous row by 50%. Always keep the light moving to avoid burning the wood.
                                    </span>
                        </div>
                        <div className='system-desc'>
                            <span>7) Apply the second coat. </span>
                            Once the first coat has been cured, the next coat can be applied. The second coat can be
                            a clear coat, the same color, or a different color. Then cure the second coat with the
                            same process.
                            <div className='system-desc'>
                                <span>Helpful Tips: </span>
                                <p>
                                    If adjustments to the color are desired after the second coat is cured, an
                                    additional coat can be applied. No abrasion is necessary—the finish bonds to the
                                    previous coat. This process can be repeated as necessary with subsequent coats
                                    to achieve the desired color.
                                </p>
                                <p>
                                    If you find an area (such as a sanding mark) that needs a repair while curing
                                    the first coat, you can sand that area and reapply the finish to that area. The
                                    repair will be visible in the first coat but will blend in after the second coat
                                    of finish is applied and cured.
                                </p>
                                <p>
                                    If you see differences in color between the field and perimeter areas (often
                                    referred to as a “halo”), that means there was an inconsistency in the abrasion
                                    between those areas. The field and perimeter must be sanded to the same grit
                                    with the same scratch pattern for a consistent appearance in the color.
                                </p>
                                <p>
                                    Leaving extra oil on the floor will not improve the finish performance. The oil is
                                    designed to protect the floor by being absorbed into the wood. Too much oil left on
                                    the surface can lead to uneven sheen
                                </p>
                                <p>
                                    If you are spreading the finish with the red pad and the pad is getting stuck on the
                                    floor, the pad is overworked and should be replaced.
                                </p>
                            </div>
                        </div>
                    </>}
                    <div onClick={handleViewMore}>
                        <ButtonWithSvg buttonClass='readMore' title={!more ? 'Read More' : 'Read Less'}/>
                    </div>
                </div>
                {/*<VideoCard {...data[1]} reverse/>*/}
                {/*<VideoCard {...data[2]} />*/}
                {/*<VideoCard {...data[3]} reverse/>*/}
                {/*<VideoCard {...data[4]} />*/}
                {/*<VideoCard {...data[5]} />*/}
                {/*<div className='other-videos-content'>*/}
                {/*    <p className="title">Other Videos</p>*/}
                {/*    <div className="videos">*/}
                {/*        <div className='video'>*/}
                {/*            <VideoComponent video='https://www.youtube.com/watch?v=9vt0VCIsLv8'/>*/}
                {/*        </div>*/}
                {/*        <div className='video'>*/}
                {/*            <VideoComponent video='https://www.youtube.com/watch?v=9i9NevrnUZw'/>*/}
                {/*        </div>*/}
                {/*        <div className='video'>*/}
                {/*            <VideoComponent video='https://www.youtube.com/watch?v=ZQ-C4N-rzuU'/>*/}
                {/*        </div>*/}
                {/*        <div className='video'>*/}
                {/*            <VideoComponent*/}
                {/*                video='https://youtu.be/tJPCUP-c2Zg?list=PLHRxjv8rMTwZKqpi75MhPERK7IwMUbUrY'/>*/}
                {/*        </div>*/}
                {/*        <div className='video'>*/}
                {/*            <VideoComponent*/}
                {/*                video='https://youtu.be/Bzdu2CIJHow?list=PLHRxjv8rMTwZKqpi75MhPERK7IwMUbUrY'/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}


            </div>

        </ContainerBigWrapper>
        <div className='bottom-video'>
            <div className='bottom-video-container'>
                <div className='video-desc'>
                    <h2>MAINTENANCE COATS</h2>
                    <p>
                        1) Clean the wood floor surface with Vesting Intensive Cleaner to remove any dirt and
                        residues
                        that are on the surface of the floor. Add 75 cc of Intensive Cleaner to approximately 10
                        liters
                        of lukewarm water (½ cup Intensive Cleaner per 2½ gallons of water). Use well-wrung cloths
                        and/or microfiber mops to apply the cleaner to the floor, then let the floor completely dry.

                    </p>
                    <p>
                        2) If needed, perform spot repairs with the LED hardwax oil, curing each repair area as you
                        go.
                        Then buff the floor with a maroon pad. Differences in sheen that appear at this point will
                        disappear when the final coat of oil is applied.
                    </p>
                    <p>
                        3) The color of the floor may be adjusted with the new coat. Once you have determined if the
                        next coat will be a colored oil or a clear coat, apply the new coat of LED hardwax oil to
                        the
                        entire floor following the application instructions (remember to always cover windows where
                        any
                        sunlight may directly hit the floor).
                    </p>
                </div>
                <div className='video'>
                    <VideoComponent video={'https://www.youtube.com/watch?v=sWTqLrhaurA'}/>
                </div>
            </div>
        </div>
    </Wrapper>)
}

export default HowToUse;

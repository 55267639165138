import {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import validationSchema from "utils/yupLocalised/scheme/address";
import {showModal} from "../../state/modal";
import {sendZipCode} from "../../state/zip/operations";

function useContainer({shippingMode}) {
    // const {sendReviewsLoader} = useSelector(({products}) => products);
    const {totalPrice, isFetchingBasket, basketData} = useSelector(({basket}) => basket);
    const dispatch = useDispatch();

    const onSubmit = async (values) => {

        values.phone = +values.phone
        values.zip_code = +values.zip_code
        const {payload} = await dispatch(sendZipCode({values:values, shippingMode}))


        if (payload.errorMessage) {
            console.log(7777)
            formik.setFieldError('zip_code', 'Invalid ZIP code')
        }else {
                dispatch(showModal({
                    modalType: 'PAYMENT_MODAL',
                    modalProps: {
                        amount: totalPrice,
                        basketData,
                        addressValues:values,
                        shippingMode:shippingMode
                    }
                }))

        }




    }


    /** Formik initialization */
    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            address_first: '',
            address_last: '',
            city: '',
            zip_code: '',
            phone: '',
            region: ''
        },
        validationSchema,
        onSubmit,
    });


    return {
        formik,


        // loader: sendReviewsLoader,
    }
}

export default useContainer;

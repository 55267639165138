import * as React from "react"

function MobileMenuISvg(props) {
    return (
        <svg
            width={25}
            height={16}
            viewBox="0 0 25 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M.28 16v-2.667h24V16h-24zm0-6.667V6.667h24v2.666h-24zm0-6.666V0h24v2.667h-24z"
                fill="#68885D"
            />
        </svg>
    )
}

export default MobileMenuISvg

import React from 'react';
import "./style.scss";
import Wrapper from "views/Wrapper";
import ContainerBigWrapper from "views/shared/ContainerBigWrapper";
import image1 from 'assets/images/downloadButtonLeft.png'
import image2 from 'assets/images/downloadButtonRight.png'
import image3 from 'assets/images/downloadBottom.png'

const TechnicalData = () => {
    return (
        <Wrapper>
            <ContainerBigWrapper>
                <div className='technical-date'>
                    <h1 className='title'>Technical Data Sheets</h1>
                    <div className="pdfs">
                        <button type='text' className='pdf-title' style={{backgroundImage: `url(${image1})`}}>
                            <a target="_blank" rel="noreferrer"
                               href={'https://static1.squarespace.com/static/5c633ce57980b34556be6fca/t/5e39ecea26a25924c7aa8564/1580854523340/MSDS+Color+%288.5x11%29.pdf'}>
                                Over de Vesting
                                <p>5 resources</p>
                            </a>
                        </button>
                        <button type='pdfs' className='pdf-title' style={{backgroundImage: `url()`}}>
                            <a target="_blank" rel="noreferrer"
                               href={'https://static1.squarespace.com/static/5c633ce57980b34556be6fca/t/5e39ecea26a25924c7aa8564/1580854523340/MSDS+Color+%288.5x11%29.pdf'}>
                                Introductiesheets
                                <p>5 resources</p>
                            </a>
                        </button>
                        <button type='pdfs' className='pdf-title' style={{backgroundImage: `url(${image2})`}}>
                            <a target="_blank" rel="noreferrer"
                               href={'https://static1.squarespace.com/static/5c633ce57980b34556be6fca/t/5e39ecea26a25924c7aa8564/1580854523340/MSDS+Color+%288.5x11%29.pdf'}>
                                Productsheets
                                <p>5 resources</p>
                            </a>
                        </button>
                    </div>
                </div>
            </ContainerBigWrapper>
        </Wrapper>
    );
};

export default TechnicalData;

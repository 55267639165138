import React from 'react';
import {Skeleton} from "antd";
import ContainerBigWrapper from "views/shared/ContainerBigWrapper";
import Wrapper from "views/Wrapper";

const SkeletonLoader = ({withImage}) => {
    return (
        <Wrapper withBackgroundCircleLeft>
            <ContainerBigWrapper>
                <div style={{width: '100%', minHeight: 400, marginTop: 50}}>
                    {withImage && <Skeleton.Image style={{width: 320, height: 320}} active />}
                    <Skeleton style={{marginTop: 10}} active />
                </div>
            </ContainerBigWrapper>
        </Wrapper>
    );
};

export default SkeletonLoader;
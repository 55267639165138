import React from 'react';
import {Link} from "react-router-dom";
import {isEmpty} from "lodash";
import Wrapper from "views/Wrapper";
import PaymentCard from "views/Payments/PaymentCard";
import ContainerBigWrapper from "views/shared/ContainerBigWrapper";
import BasketEmptySvg from "assets/svg/BasketEmptySvg";
import SkeletonLoader from "views/shared/Skeleton";
import useContainer from "./hook";
import './style.scss';

const Payments = () => {
    const {fetchPaymentsLoader, paymentHistory} = useContainer();
    if(fetchPaymentsLoader) return <SkeletonLoader />

    return (
        <Wrapper withBackgroundCircleRight>
            <ContainerBigWrapper>
                <div className='payments-page'>
                    <div className='payments-page-top-content'>
                        <h2 className='content-title'>Payments history</h2>
                        <h6>{paymentHistory?.length || 0} Items in history</h6>
                    </div>
                    <div className='history-header'>
                        <div className='item product'><p className='column-name'>Product</p></div>
                        <div className='item'><p className='column-name'>Date</p></div>
                        <div className='item'><p className='column-name'>Count</p></div>
                        <div className='item'><p className='column-name'>Payment Amount</p></div>
                    </div>
                    {!isEmpty(paymentHistory) ?
                        <div className='payment-cards'>
                            {paymentHistory.map(item => <PaymentCard key={item.id} {...item}/>)}
                        </div>
                        :
                        <div className='basketEmpty'>
                            <BasketEmptySvg fill={'#dce0e6'}/>
                            <h5>There are no items in your payments history.</h5>
                            <Link to='/products' className='goShop'>Go To Shop</Link>
                        </div>
                    }
                </div>
            </ContainerBigWrapper>
        </Wrapper>
    );
};

export default Payments;

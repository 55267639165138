import * as yup from 'yup';
import {revertToYupMessageFormat} from "utils/helpers";

yup.setLocale({
    mixed: {
        required: ({path}) => `${revertToYupMessageFormat(path)} is required`,
    },
    string: {
        min: ({ min, path }) => `${revertToYupMessageFormat(path)} must be at least ${min} characters`,
    },
});

export default yup;

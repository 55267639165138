import React from 'react';
import {Link} from "react-router-dom";
import {API_HOST} from "constants/genral";
import "./style.scss";

const CategoryCard = ({value, label, img_path}) => {
    return (
        <Link to={{pathname: '/products', search: `?categories=${value}`}} className='category-card'>
            <div className='image' style={{backgroundImage: `url(${API_HOST}${img_path})`}}/>
            <h4 className='name'>{label}</h4>
        </Link>
    );
};

export default CategoryCard;

import React from "react";
import {Link} from "react-router-dom";
import {Dropdown} from "antd";
import {isEmpty} from "lodash";
import {LogoutOutlined} from "@ant-design/icons";
import BasketSvg from "assets/svg/BasketSvg";
import {API_HOST} from "constants/genral";
import {customUserName} from "utils/helpers";
import useContainer from "./hook";
import "./style.scss";
import UserAccountSvg from "../../../../assets/svg/UserAccountSvg";

const BasketOrSignIn = () => {
    const {opeSignUpModal, opeSignInModal, authorized, handleLogAuth, basketData, currentUser} = useContainer();
    const dropDownItems = [
        {
            key: '1',
            label: (
                <span onClick={handleLogAuth}>Log Out</span>
            ),
        },
    ];
    const dropDownSignInItems = [
        {
            key: '1',
            label: (
                <span onClick={opeSignInModal}>Sign in</span>
            ),
        },
        {
            key: '2',
            label: (
                <span onClick={opeSignUpModal}>Sign up</span>
            ),
        },
    ];



    return (
        <div className='basket-or-sign-in'>
            {authorized ? (
                <>
                    <div className='mobile-authorized'>
                        <Link to='/user/basket'>
                            <p>Basket
                                {!isEmpty(basketData?.data) &&
                                    <span className='count-circle'>{basketData?.data?.length}</span>}
                            </p>
                        </Link>
                        <Link to='/user/profile'><p>Account</p></Link>
                        <div className='log-out-content' onClick={handleLogAuth}>
                            <LogoutOutlined className='icon'/>
                            <span className='text'>Log Out</span>
                        </div>
                    </div>

                    <div className='authorized'>
                        <Link to='/user/basket' className='basket'>
                            <BasketSvg fill={'#FFFFFF'} width={31} height={32}/>
                            <div
                                className={!isEmpty(basketData?.data) ? 'basketCount' : 'none'}>{basketData?.data?.length || 0}</div>
                        </Link>
                        <Link to='/user/profile'>
                            <Dropdown menu={{items: dropDownItems}}>
                                {/*<div className='user-avatar' style={{background: "red"}}/>*/}
                                <div className='user-img'>
                                    {
                                        currentUser.img_path !== null ?
                                            <img src={`${API_HOST}/${currentUser.img_path}`} alt=""/> :
                                            <span>{customUserName(currentUser.first_name, currentUser.last_name)}</span>
                                    }
                                </div>
                            </Dropdown>
                        </Link>
                    </div>
                </>
            ) : (
                <>
                    <div className='authorized'>
                        <Link to='/user/basket' className='basket'>
                            <BasketSvg fill={'#FFFFFF'} width={31} height={32}/>
                            <div
                                className={!isEmpty(basketData?.data) ? 'basketCount' : 'none'}>{basketData?.data?.length || 0}</div>
                        </Link>
                        <div className='profileIcon'>
                            <Dropdown menu={{items: dropDownSignInItems}}>
                                <UserAccountSvg color={'white'}/>
                            </Dropdown>
                        </div>
                    </div>

                    {/*<button type='text' className='sign-in-button' onClick={opeSignInModal}>*/}
                    {/*    Sign in*/}
                    {/*</button>*/}
                    {/*<button type='text' className='sign-in-button sign-up-button' onClick={opeSignUpModal}>*/}
                    {/*    Sign up*/}
                    {/*</button>*/}
                </>
            )}
        </div>
    )
}

export default BasketOrSignIn;

import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {showModal} from "state/modal";
import {getAllBasket} from "state/basket/operations";

function useContainer() {
    const dispatch = useDispatch();
    const {totalPrice, isFetchingBasket, basketData} = useSelector(({basket}) => basket);
    const [showCountPopup, setShowCountPopup] = useState('');
    const [checkedPolicy, setCheckedPolicy] = useState(false);
    const {isUserLogged} = useSelector(({users}) => users);
    const [coupon, setCoupon] = useState('')




    const countPopupHandler = useCallback((count, id) => {
        setShowCountPopup('');
        if(count < 10) return;
        setShowCountPopup(id);
    }, [])

    const opePaymentModal = useCallback(() => {
        if (!isUserLogged) {
            dispatch(showModal({
                modalType: 'SIGN_IN_MODAL',
            }))
            return;
        }
        //
        // dispatch(showModal({
        //     modalType: 'PAYMENT_MODAL',
        //     modalProps: {
        //         amount: totalPrice,
        //         basketData
        //     }
        // }))

        dispatch(showModal({
            modalType: 'PAYMENT_ADDRESS_MODAL',
            modalProps: {
                amount: totalPrice,
                basketData
            }
        }))
    }, [totalPrice, basketData, isUserLogged]);

    const checkPolicy = ({target: {checked}}) => {
        setCheckedPolicy(checked)
    }

    useEffect(() => {
        dispatch(getAllBasket())
    }, []);

    return {
        totalPrice,
        isFetchingBasket,
        basketData,
        opePaymentModal,
        showCountPopup,
        countPopupHandler,
        setShowCountPopup,
        checkPolicy,
        checkedPolicy,
        setCoupon,
        coupon
    }
}

export default useContainer;

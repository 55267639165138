import {createAsyncThunk} from "@reduxjs/toolkit";
import httpClient from "state/httpClient";


export const getColors = createAsyncThunk(
    'colorLab/getColors', async () => {
        try {
            const {data} = await httpClient.get(`/default/get-colors`);
            return data
        } catch (e) {
            console.log(e)
        }
    });


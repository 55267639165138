import * as React from "react"

function UserAccountSvg(props) {
    return (
        <svg
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M16 0C7.164 0 0 7.163 0 16c0 8.836 7.164 16 16 16 8.837 0 16-7.164 16-16 0-8.837-7.163-16-16-16zm0 4.784a5.292 5.292 0 110 10.585 5.292 5.292 0 010-10.585zm-.004 23.032a11.742 11.742 0 01-7.646-2.82 2.255 2.255 0 01-.791-1.714c0-2.963 2.398-5.335 5.363-5.335h6.158a5.33 5.33 0 015.354 5.335 2.25 2.25 0 01-.79 1.714 11.738 11.738 0 01-7.647 2.82z"
                fill={props.color || "#AECB7B"}
            />
        </svg>
    )
}

export default UserAccountSvg

import React from "react";
import {Link} from "react-router-dom";
import {isEmpty} from "lodash";
import ProductCard from "views/shared/ProductCard";
import ButtonWithSvg from "views/shared/ButtonWithSvg";
import CarouselContent from "views/shared/CarouselContent";
import "./style.scss";

const Products = ({products}) => {
    return (
        <div className='products-home'>
            <div className='title-content'><h2 className='content-title title'>Products</h2></div>
            <div className='cards'>
                {!isEmpty(products) && products.map((item, index) => (
                    <ProductCard key={item.title + index} redirectRoute='/product-details' {...item} />
                ))}
            </div>
            <div className='product-carousel'>
                <CarouselContent data={products} card={ProductCard} redirectRoute='/product-details'/>
            </div>
            <div className='button-content'>
                <Link to='/products-categories'><ButtonWithSvg title='View all'/></Link>
            </div>
        </div>
    )
}

export default Products;

import React from "react";
import {isEmpty} from "lodash";
import UserAccount from "views/shared/UserAccount";
import {API_HOST} from "constants/genral";
import {expressDateDuration} from "utils/helpers";
import {Rate} from "antd";
import "./style.scss";

const ReviewCard = ({user_full_name, description, title, stars, img_path, user_img_path, time_difference}) => {
    return (
        <div className='review-card'>
            <div className='account-info'>
                <UserAccount image={user_img_path} userName={user_full_name} size={55} />
                <div className='right'>
                    <p className='user-name'>{user_full_name}</p>
                    <p className='date'>{expressDateDuration(time_difference)}</p>
                    <Rate className='rating' value={+stars} allowHalf disabled/>
                </div>
            </div>
            <div className='message-content'>
                <p className='message-title'>{title}</p>
                <p className='message' dangerouslySetInnerHTML={{__html: description}}></p>
            </div>
            {!isEmpty(img_path) &&
                <div className='images-content'>
                    {img_path.map((item, index) =>
                        <img className='image' key={index} alt='..' src={`${API_HOST}/${item}`} />)}
                </div>
            }
        </div>
    )
}

export default ReviewCard;
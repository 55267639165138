import {useFormik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import validationSchema from "utils/yupLocalised/scheme/serviceRequest";
import uponValidationSchema from "utils/yupLocalised/scheme/uponRequest";
import {fetchServices, sendServiceRequest} from "state/services/operations";
import {useEffect, useState} from "react";
import {isEmpty} from "lodash";
import Account from "utils/account";
import {notification} from 'antd';

function useContainer({serviceId, title, id}) {
    const dispatch = useDispatch();
    const {loader} = useSelector(({users}) => users);
    const {services, sendLoader, fetchLoader} = useSelector(({services}) => services);
    const [selectOptions, setSelectOptions] = useState([]);

    const openNotificationWithIcon = (type, message) => {
        notification.open({
            message,
            type,
            duration: 2,

        });
    };


    useEffect(() => {
        if (isEmpty(services)) {
            dispatch(fetchServices());
            return;
        }
        const options = services?.models.map(item => {
            item = {
                label: item.title,
                value: item.id,
            }
            return item;
        });
        setSelectOptions(options);
    }, [services])

    const onSubmit = (values) => {

        if(!title){
            openNotificationWithIcon('success', 'Request successfully sent')
            dispatch(sendServiceRequest(values));
        }else {
            const obj = {...values, services_id:null, products_id: id }
            openNotificationWithIcon('success', 'Request successfully sent')

            dispatch(sendServiceRequest(obj));

        }

    }

    /** Formik initialization */
    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            phone: '',
            email: '',
            services_id: '',
            description: '',
        },
        validationSchema: title ? uponValidationSchema : validationSchema,
        onSubmit,
    });

    const onMountHandler = () => {
        if (serviceId) formik.setFieldValue('services_id', serviceId);
        const account = Account.getAccount();
        if (isEmpty(account)) return;
        formik.setFieldValue('first_name', account?.first_name);
        formik.setFieldValue('last_name', account?.last_name);
        formik.setFieldValue('email', account?.email);
    }

    useEffect(onMountHandler, [services]);

    return {
        formik,
        loader,
        selectOptions,
        sendLoader,
        fetchLoader,
    }
}

export default useContainer;

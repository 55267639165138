import {createSlice} from "@reduxjs/toolkit";
import {getAllBasket, deleteBasket, fetchPaymentHistory} from "./operations";

const initialState = {
    basketData: {},
    totalPrice: 0,
    isFetchingBasket: false,
    paymentHistory: [],
    fetchPaymentsLoader: false,
};

export const basketSlice = createSlice({
    name: 'basket',
    initialState,
    reducers: {
        addPrice: (state, action) => {
            if (action.payload.operator === 'minus') {
                state.totalPrice -= action.payload.price
                return;
            }
            state.totalPrice += action.payload.price
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPaymentHistory.fulfilled, (state, {payload}) => {
                state.paymentHistory = payload;
                state.fetchPaymentsLoader = false;
            })
            .addCase(fetchPaymentHistory.pending, (state) => {
                state.fetchPaymentsLoader = true;
            })
            .addCase(deleteBasket.fulfilled, (state, {payload}) => {
                state.basketData.data = state.basketData.data?.filter(item => {
                    return +item.id !== +payload.id;
                })
            })
            .addCase(getAllBasket.pending, (state) => {
                state.isFetchingBasket = true
            })
            .addCase(getAllBasket.fulfilled, (state, action) => {
                state.basketData = action.payload
                state.totalPrice = action.payload.total
                state.isFetchingBasket = false
            })
    }
});

export const {addPrice} = basketSlice.actions;
export default basketSlice.reducer;
import * as React from "react"

function Pinterest(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_833_977)">
                <path
                    d="M12 0C5.372 0 0 5.372 0 12c0 5.086 3.164 9.427 7.627 11.175-.103-.951-.202-2.405.042-3.44.22-.938 1.406-5.963 1.406-5.963s-.361-.717-.361-1.781c0-1.669.966-2.916 2.17-2.916 1.022 0 1.519.769 1.519 1.692 0 1.032-.656 2.569-.994 3.994-.281 1.195.6 2.17 1.777 2.17 2.133 0 3.773-2.25 3.773-5.494 0-2.873-2.062-4.884-5.01-4.884-3.413 0-5.42 2.56-5.42 5.208 0 1.031.399 2.138.896 2.738a.359.359 0 01.085.341c-.09.38-.295 1.195-.333 1.36-.052.22-.174.267-.403.16-1.5-.699-2.438-2.888-2.438-4.65 0-3.788 2.752-7.262 7.926-7.262 4.163 0 7.398 2.967 7.398 6.933 0 4.135-2.607 7.462-6.226 7.462-1.213 0-2.357-.632-2.751-1.378 0 0-.6 2.293-.745 2.855-.273 1.04-1.004 2.349-1.491 3.145A12.03 12.03 0 0012 24c6.628 0 12-5.372 12-12S18.628 0 12 0z"
                    fill="#636270"
                />
            </g>
            <defs>
                <clipPath id="clip0_833_977">
                    <path fill="#fff" d="M0 0H24V24H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Pinterest

import React from 'react';
import {isEmpty} from "lodash";
import {API_HOST} from "constants/genral";
import Wrapper from "views/Wrapper";
import ContainerWrapper from "views/ContainerWrapper";
import SkeletonLoader from "views/shared/Skeleton";
import SEO from "views/shared/SEO";
import ButtonWithSvg from "views/shared/ButtonWithSvg";
import useContainer from "./hook";
import "./style.scss";

const ServiceDetails = () => {
    const {fetchLoader, serviceById, handleShowModal, showServicesPdfModal} = useContainer();

    if (fetchLoader) {
        return <SkeletonLoader/>
    }

    return (
        <>
            <SEO title={`LED Coating Solutions - ${serviceById?.title}`} description={serviceById?.description}/>
            <Wrapper>
                <div className='service-details-page'>
                    <ContainerWrapper>
                        {!isEmpty((serviceById)) &&
                            <>
                                <div className="title-content">
                                    <h2 className="title">{serviceById?.title}</h2>
                                </div>
                                <p className="description description-text">{serviceById?.description}</p>
                                <div className="images">
                                    <div className='image'
                                         style={{backgroundImage: `url(${API_HOST}/${serviceById?.img_path})`}}/>
                                    <div className='image'
                                         style={{backgroundImage: `url(${API_HOST}/${serviceById?.img_path_2})`}}/>
                                </div>
                                <div className="downloads">
                                    <p className="title content-title">View/Download Catalogs</p>
                                    <div className="buttons">
                                        {!isEmpty(serviceById.pdf_data) && (
                                            Object.keys(serviceById.pdf_data).map((item, index) => (
                                                <button key={item + index} onClick={() => showServicesPdfModal(serviceById.pdf_data[item])}
                                                        className='button'>{item}</button>
                                            ))
                                        )}
                                    </div>
                                </div>
                            </>
                        }
                        <div className="send">
                            <ButtonWithSvg title='Send Request' onClick={handleShowModal}/>
                        </div>
                    </ContainerWrapper>
                </div>
            </Wrapper>
        </>
    );
};

export default ServiceDetails;

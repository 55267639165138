import {createAsyncThunk} from "@reduxjs/toolkit";
import httpClient from "state/httpClient";
import {appendFormData} from "utils/helpers";
import {hideModal, showModal} from "state/modal";
import Account from "utils/account";
import {getAllBasket} from "../basket/operations";

export const userSignIn = createAsyncThunk(
    'users/userSignIn', async (payload, {dispatch}) => {
        try {
            const {data} = await httpClient.post(`/user/login`, appendFormData(payload.data.values));
            dispatch(hideModal());
            return {
                data,
                rememberMe: payload.data.rememberMe,
            };
        } catch (e) {
            payload.callback('Incorrect email or password');
        }
    });

export const userSignUp = createAsyncThunk(
    'users/userSignUp', async (payload, {dispatch}) => {

        try {
            const {data} = await httpClient.post(`/user/create`, appendFormData(payload.data.values));
            dispatch(hideModal());

            return {
                data: data.data,
                rememberMe: payload.data.rememberMe,
            };
        } catch (e) {
            payload.callback(e.response.data?.message);
        }
    });

export const userLogAuth = createAsyncThunk(
    'users/userLogAuth', async (_, {getState, dispatch}) => {
        const {users: {currentUser}} = getState();
        try {
            await httpClient.post(`/user/logout`, appendFormData({id: currentUser?.id}));
            Account.delete();
            await dispatch(getSessionToken())
            await dispatch(getAllBasket())
        } catch {
            //
        }
    });

export const getUser = createAsyncThunk(
    'users/getUser', async () => {
        try {
           const {data} =  await httpClient.post(`/user/user-get`);
           return data
        } catch {
            //
        }
    });
export const getSessionToken = createAsyncThunk(
    'users/getSessionToken', async () => {
        try {
           const {data} =  await httpClient.get(`/default/session`);
           localStorage.setItem('sessionToken', data.token)
            console.log('sessionToken===', data)
           // return data
        } catch {
            //
        }
    });

export const sendEmailForForgotPassword = createAsyncThunk(
    'users/sendEmailForForgotPassword', async (payload) => {
        try {
            await httpClient.post(`/user/forgot-password`, appendFormData(payload.values));
            payload.callback('success');
        } catch (e) {
            payload.callback(e.response.data?.message);
        }
    });

export const forgotPasswordRequest = createAsyncThunk(
    'users/forgotPasswordRequest', async (payload, {dispatch}) => {
        try {
            await httpClient.post(`/user/new-password`, appendFormData(payload.values));
            dispatch(showModal({
                modalType: "SIGN_IN_MODAL"
            }))
        } catch (e) {
            payload.callback(e.response.data?.message);
        }
    });

import {createSlice} from "@reduxjs/toolkit";
import {getColors} from "./operations";

const initialState = {
    colorLabData: [],
    colorLabStatus:''
};

export const colorLabSlice = createSlice({
    name: 'basket',
    initialState,

    extraReducers: (builder) => {
        builder
            .addCase(getColors.pending, (state) => {
                state.colorLabStatus = 'pending'
            })
            .addCase(getColors.fulfilled, (state, action) => {
               state.colorLabData = action.payload.user
                state.colorLabStatus = 'ok'

            })
            .addCase(getColors.rejected, (state, action) => {
                state.basketStatus = 'fail'
            })


    }
});

export default colorLabSlice.reducer;
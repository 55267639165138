import {createAsyncThunk} from "@reduxjs/toolkit";
import httpClient from "state/httpClient";

export const fetchHomePageData= createAsyncThunk(
    'home/fetchHomePageData', async () => {
        try {
            const {data} = await httpClient.get(`/default/home`);
            return data;
        } catch (e) {
            //
        }
    });
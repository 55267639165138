import React from 'react';
import {Modal, Form, Input} from "antd";
import {FormikProvider} from "formik";
import InputFiled from "views/shared/forms/InputField";
import ButtonWithSvg from "views/shared/ButtonWithSvg";
import SelectFiled from "views/shared/forms/selectFiled";
import PhoneNumberFiled from "views/shared/forms/phoneNumberFiled";
import Loader from "views/shared/Loader";
import useContainer from "./hook";
import "./style.scss";

const ServiceRequestModal = ({onClose, serviceId, title, id, customColor}) => {





    const {
        formik,
        selectOptions,
        sendLoader,
        fetchLoader
    } = useContainer({serviceId, title, id});

    return (
        <Modal onCancel={onClose} footer={null} open width={'750px'} className='service-request-modal'>
            {fetchLoader ? <Loader/> :
                <div className='service-request-modal-main'>
                    <div className="title-content">
                        <p className='title'>{title? 'Upon Request': customColor ? 'Custom Color Request': 'Service Request'}</p>
                    </div>
                    <Form onFinish={formik.handleSubmit} className='form'>
                        <FormikProvider value={formik}>
                            <div className="two-inputs">
                                <InputFiled
                                    className="service-input"
                                    name="first_name"
                                    formItemClassName="form-item"
                                    placeholder="First name"
                                />
                                <InputFiled
                                    className="service-input"
                                    name="last_name"
                                    formItemClassName="form-item"
                                    placeholder="Last name"
                                />
                            </div>
                            <div className="two-inputs">
                                <InputFiled
                                    className="service-input"
                                    name="email"
                                    formItemClassName="form-item"
                                    placeholder="Email"
                                />
                                <PhoneNumberFiled
                                    className="service-input"
                                    name="phone"
                                    formItemClassName="form-item"
                                    placeholder="Phone Number"
                                />
                            </div>
                            {title ?
                                <div className='one-input'>
                                    <p>{title}</p>
                                </div>:
                                <SelectFiled
                                className="service-input-select"
                                name="services_id"
                                formItemClassName="form-item"
                                placeholder="Request Type"
                                options={selectOptions}
                        />}


                        <InputFiled
                            className="text-area"
                            name="description"
                            formItemClassName="form-item"
                            placeholder="Notes For Request"
                            asComponent={Input.TextArea}
                        />
                        <div className='button-content'>
                            <ButtonWithSvg loader={sendLoader} buttonClass='button' title='Request'
                                           htmlType='submit'/>
                        </div>
                    </FormikProvider>
                </Form>
                </div>}
        </Modal>
    )
}

export default ServiceRequestModal;

import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import httpClient from "../httpClient";
import {appendFormData} from "../../utils/helpers";


// export const sendZipCode = createAsyncThunk(
//     'zip/sendZipCode', async (payload, {rejectWithValue, dispatch}) => {
//         try {
//             const {data} = await axios.get(`https://led-backend.brainfors.am/api/fedex/zip-code?zip=${payload.zip}`)
//             if (data.discount) {
//                 return { errorZipMessage: '', zip:payload.zip}
//             } else {
//                 return {errorZipMessage: 'Invalid Zip Code', zip:null}
//             }
//
//
//         } catch (e) {
//             console.log(e)
//
//         }
//     });


export const sendZipCode = createAsyncThunk(
    'zip/sendZipCode', async (payload, {rejectWithValue, dispatch}) => {
        try {
            console.log(payload.values)



            if (payload.shippingMode === 1) {
                const {data} = await httpClient.get(`/fedex/zip-code?zip=${payload.values.zip_code}`,{params:{delivery:true}})
                return {zip: data.data, tax: data.tax, deliveryTotal: data.deliveryTotal}
                // await httpClient.post('/user/add-shipping', appendFormData(payload.values))
            }
            const {data} = await httpClient.get(`/fedex/zip-code?zip=${payload.values.zip_code}`,{params:{delivery:false}})
            await httpClient.post('/user/change-data', appendFormData(payload.values))

            return {zip: data.data, tax: data.tax, deliveryTotal: data.deliveryTotal}


        } catch (e) {
            return {errorMessage: e.message}

        }
    });


export const addShipping = createAsyncThunk(
    'addShipping', async (payload, {rejectWithValue, dispatch}) => {
        try {
            console.log(payload.values)
            await httpClient.post('/user/add-shipping', appendFormData(payload.values))
        } catch (e) {
            return {errorMessage: e.message}

        }
    });

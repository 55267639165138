import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import {MEDIA_TABS} from "constants/globals";
import {fetchMedia} from "state/media/operations";
import {camelCase} from "lodash";

function useContainer() {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState(searchParams.get('type') || MEDIA_TABS.floorRefinishing);
    const { media, mediaLoader } = useSelector(({media}) => media);

    const onMountHandler = () => {
        dispatch(fetchMedia({type: activeTab}));
    }

    const changeActiveTab = () => {
        if(!MEDIA_TABS[camelCase(searchParams.get('type'))]) {
            searchParams.set('type', MEDIA_TABS.floorRefinishing);
            setSearchParams(searchParams);
            setActiveTab(MEDIA_TABS.floorRefinishing);
        }
        setActiveTab(searchParams.get('type') || MEDIA_TABS.floorRefinishing);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(onMountHandler, [activeTab]);
    useEffect(changeActiveTab, [searchParams]);

    return {
        activeTab,
        media,
        mediaLoader,
    }
}

export default useContainer;

import React from "react";
import {Button} from "antd";
import {Link} from "react-router-dom";
import {API_HOST} from "constants/genral";
import "./style.scss";

const OilCard = (
    {title, id, image, price, imageClassName = '', priceClassName = '', cardClassName = '', seeMoreClassName = '', withSeeMore}
) => {
    return (
        <Link to={`/product-details/${id}`} className={`${cardClassName} oil-card`}>
            <img src={`${API_HOST}${image}`} alt='...' className={`${imageClassName}`}/>
            <h2 className='name'>{title}</h2>
            <p className={`${priceClassName} price global_color`}>{`$${Number(price)?.toFixed(2)} USD`}</p>
            {!withSeeMore && <button className={`see-more-display-none ${seeMoreClassName}`}>See More</button>}
            {withSeeMore && <Button type='text' className='see-more global_color'>See more</Button>}
        </Link>
    )
}

export default OilCard;

import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {fetchServiceById} from "state/services/operations";
import {showModal} from "state/modal";

function useContainer() {
    const dispatch = useDispatch();
    const {id} = useParams();
    const {serviceById, fetchLoader} = useSelector(({services}) => services);

    const handleShowModal = () => {
        dispatch(showModal({
            modalType: 'SERVICE_REQUEST_MODAL',
            modalProps: {serviceId: +id}
        }))
    }

    const showServicesPdfModal = (data) => {
        dispatch(showModal({
            modalType: 'SERVICES_PDF_MODAL',
            modalProps: {
                data,
            },
        }))
    }

    const onMountHandler = () => {
        dispatch(fetchServiceById({id}))
    }

    useEffect(onMountHandler, []);

    return {
        serviceById,
        fetchLoader,
        handleShowModal,
        showServicesPdfModal,
    }
}

export default useContainer;

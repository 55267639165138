import {useFormik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import validationSchema from "utils/yupLocalised/scheme/forgotPassword";
import {forgotPasswordRequest} from "state/users/operations";

function useContainer({token}) {
    const dispatch = useDispatch();
    const { loader } = useSelector(({users}) => users);

    const onSubmit = async (values) => {
        if (values.verify_password !== values.password) {
            formik.setFieldError('verify_password','Verified password is incorrect')
            return;
        }
        delete values.verify_password;
        dispatch(forgotPasswordRequest({values, callback: (message) => {
            formik.setFieldError('password', message);
        }}));
    }

    const formik = useFormik({
        initialValues: {
            password: '',
            verify_password: '',
            token,
        },
        validationSchema,
        onSubmit,
    });

    return {
        formik,
        loader,
    }
}

export default useContainer;
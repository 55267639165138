import React, {useEffect, useState} from 'react';
import {Button, Tooltip} from "antd";
import {isEmpty} from "lodash";

const SheenCube = ({handleChangeSheens, selectedShen, item, typeData, selectedType, borderErrors}) => {

        const [tooltipSheenText, setTooltipSheenText] = useState(false)
        const [disabled, setDisabled] = useState(true)

        const getTooltipSheenText = (item) => {

            if (item['tag_id'] === selectedShen) {
                setTooltipSheenText(true)
            } else setTooltipSheenText(false)
        }

        const handleClick = (id) => {
            handleChangeSheens(id)
            setTooltipSheenText(!tooltipSheenText)
        }


        useEffect(() => {
                if (isEmpty(typeData)) {
                    setDisabled(false)
                    return
                }
                if (!selectedType) {
                    setDisabled(false)
                } else {

                    const result =  typeData.find(el=> el.title===selectedType && el.tag_id=== item.tag_id)
                    if(!result){
                        setDisabled(true)
                    }else {
                        setDisabled(false)
                    }
                }
            }, [typeData, selectedType]
        )

    const handleText = ()=>{
            if(tooltipSheenText){
                return 'Unselect'
            }
            if(!disabled){
                return 'Select'
            }else return ''
    }

    const getClassName = ()=>{
            if(borderErrors.sheens && !disabled){
                return 'errorButton'
            }
        if(item.tag_id===selectedShen){
            return 'newActiveButton'
        }
        if(disabled){
            return 'newButton disabledButton'
        }else return 'newButton'

    }



        return (
            <Tooltip title={handleText()} key={item.id} mouseEnterDelay={0.4} overlayStyle={{fontSize:12}} >
                <button value={item.tag_id} className={getClassName()}
                        disabled={disabled}
                        onMouseEnter={() => getTooltipSheenText(item)}
                        onClick={() => handleClick(item['tag_id'])}
                >
                    {item.tag}
                </button>
            </Tooltip>
        );
    }
;

export default SheenCube;

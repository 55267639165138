import React from "react";
import {Skeleton} from "antd";
import {isEmpty} from "lodash";
import {useSelector} from "react-redux";
import Wrapper from "views/Wrapper";
import ContainerBigWrapper from "views/shared/ContainerBigWrapper";
import SEO from "views/shared/SEO";
import CategoryCard from "./CategoryCard";
import "./style.scss";

const ProductsCategories = () => {
    const { productCategories, fetchCategoriesLoader } = useSelector(({products}) => products);

    if(fetchCategoriesLoader) {
        return <Skeleton style={{marginTop: 50, marginLeft: 20}} active />
    }

    return (
        <>
            <SEO title='LED Coating Solutions - Products'/>
            <Wrapper>
                <ContainerBigWrapper>
                    <div className='product-categories'>
                        <div className='top-content'>
                            <h2 className='title content-title'>ALWAYS THE RIGHT PRODUCT FOR YOU</h2>
                            <p className='description description-text'>
                                LED Coating Solution has a full line of finishing products to help you achieve that one of a kind look!
                                We specialize in Hardwax oils to help enhance the beauty of the would as well as increase the longevity of your finish.
                            </p>
                        </div>
                        {!isEmpty(productCategories?.categories) && (
                            <div className='cards'>
                                {productCategories?.categories?.map(item => <CategoryCard key={item.value} {...item}/>)}
                            </div>
                        )}
                    </div>
                </ContainerBigWrapper>
            </Wrapper>
        </>
    )
}

export default ProductsCategories;

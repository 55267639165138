import React, {useEffect, useState} from 'react';
import {Button, Tooltip} from "antd";
import {isEmpty} from "lodash";

const TypeCube = ({selectedType, handleChangeTypes, item, typeData, selectedShen, sheens, borderErrors}) => {


    const [tooltipTypeText, setTooltipTypeText] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const getTooltipTypeText = (item) => {
        if (item.title === selectedType) {
            setTooltipTypeText(true)
        } else setTooltipTypeText(false)
    }

    const handleClick = (title)=>{
        handleChangeTypes(title)
        setTooltipTypeText(!tooltipTypeText)
    }


    useEffect(() => {
            if (isEmpty(sheens)) {
                setDisabled(false)
                return
            }
            if (!selectedShen) {
                setDisabled(false)
            } else {
                const result =  typeData.find(el=> el.title===item.title && el.tag_id=== selectedShen)
                if(!result){
                    setDisabled(true)
                }else {
                    setDisabled(false)
                }
            }
        }, [typeData, selectedShen, sheens]
    )

    const handleText = ()=>{
        if(tooltipTypeText){
            return 'Unselect'
        }
        if(!disabled){
            return 'Select'
        }else return ''
    }

    const getClassName = ()=>{
        if(borderErrors.type && !disabled){
            return 'errorButton'
        }

        if(item.title===selectedType){
            return 'newActiveButton'
        }

        if(disabled){
            return 'newButton disabledButton'
        }else return 'newButton'

    }


    return (
        <Tooltip title={handleText()} key={item.id} mouseEnterDelay={0.4}  overlayStyle={{fontSize:12}} >
            <button disabled={disabled}
                    value={item.title} className={getClassName()}
                    onMouseEnter={() => getTooltipTypeText(item)}
                    onClick={() => handleClick(item.title)}
            >
                {item.title}
            </button>
        </Tooltip>
    );
};

export default TypeCube;

import React from 'react';
import Wrapper from "../Wrapper";
import ContainerBigWrapper from "../shared/ContainerBigWrapper";
import './style.scss'
import {Collapse} from "antd";
import Plus from "../../assets/svg/Plus";
import Minus from "../../assets/svg/Minus";

const {Panel} = Collapse;


const Faq = () => {

    const items = [
        {
            key: '1',
            label: 'How long after coating a wood floor can furniture and people be moved back in?',
            children: <p className='answer'>Immediately. The LED light completely cures the coating.</p>,
        },
        {
            key: '2',
            label: 'If I hold the light too long in one place, can the finish be over-cured?',
            children: <p className='answer'>No, but holding the LED light too long in one spot can heat up the wood and
                burn the wood. Always keep the LED light in motion.</p>,
        },
        {
            key: '3',
            label: 'How fast should I walk when using the LED light to cure the coating on a wood floor?',
            children: <p className='answer'>Watch the application video to see the correct walking speed; which is
                approximately 1 foot per second. Walking too quickly can result in the finish not curing.</p>,
        },
        {
            key: '4',
            label: 'Will the coating cure without using the LED light? ',
            children: <p className='answer'>Areas that have direct sunlight will cure, so a piece of furniture, trim, a
                door, etc., placed in sunlight will cure. A floor will cure only if the entire floor is hit by direct
                sunlight.</p>,

        },
        {
            key: '5',
            label: 'Will spills damage the finish?',
            children: <p className='answer'>Properly applied and cured LED-cured finishes are more resistant to stains
                than other hardwax oil coatings. As with any finish, it’s best to wipe up any spills immediately, but
                most liquids will not damage the floor unless they are left to sit overnight or longer. Liquids such as
                coffee, orange soda or pet urine are most likely to damage almost any type of coating.
            </p>,

        }, {
            key: '6',
            label: 'Does water-popping before coating make the finish more resistant to stains?',
            children: <p className='answer'>Because water-popping opens the grain of the wood, it allows more of the
                coating to be absorbed into the wood, giving the wood more protection.</p>,
        },
        {
            key: '7',
            label: 'The perimeter of the floor looks lighter (or darker) than the rest of the floor. Why?',
            children: <p className='answer'>This problem, called “halo,” indicates that there was a different scratch
                pattern on the edges of the floor than in the field, so more or less coating was absorbed into the wood
                in those areas, causing the color to appear differently. The perimeter of the floor must be resanded
                with the correct sanding pattern that matches the field, and then that area should be coated, feathering
                into the coating in the field.</p>,
        },
        {
            key: '8',
            label: 'If the customer doesn’t like the color, do I have to resand the floor?',
            children: <p className='answer'>No. Another coat can be applied over the existing LED-cured coating. The
                finish bonds to the previous coat. This process can be repeated as necessary with subsequent coats to
                achieve the desired color. To maintain the beautiful look of the oil finish penetrating into the wood
                instead of a coating with film build, be sure to wipe each coat extremely well during application.</p>,
        },
        {
            key: '9',
            label: 'Does my final coat have to be a clear coat?',
            children: <p className='answer'>Whether to have your final coat be a coat with color in it or a clear coat
                depends on the color of the floor. For colors that are highly pigmented, such as white or black, a clear
                coat will give the floor more protection. For colors such as light brown, which are lightly pigmented,
                two coats of the same color are sufficient.</p>,
        },
        {
            key: '10',
            label: 'I’m trying to spread the finish with the red pad, but it keeps getting stuck on the floor. Why?',
            children: <p className='answer'>This indicates that the pad is overworked and should be replaced.</p>,
        },
        {
            key: '11',
            label: 'I buffed in the oil with the red pad and then used the white pad on the buffer, but there is still a lot of oil on the floor. What should I do?',
            children: <p className='answer'>Use a towel or a piece of carpeting to wipe up any excess finish. If the
                floor doesn’t have gaps, a squeegee can also be used to wipe extra oil off of the floor. Once the excess
                oil is removed, use the white pad on the small oscillating buffer to ensure even application.</p>,
        },
        {
            key: '12',
            label: 'When I applied my clear coat, it seemed to take some of the color out of the previous coat. Why?',
            children: <p className='answer'>This indicates that the previous coat was not adequately cured with the LED
                light.</p>,
        },
        {
            key: '13',
            label: 'Do I always have to cover windows when coating a wood floor with LED oil?',
            children: <p className='answer'>No—only if sunlight will directly hit the floor. Planning well can help
                avoid having to cover windows. For example, plan to coat areas with windows that face west in the
                morning, or coat areas that face east later in the day. Using an app such as SunSeeker can help you
                determine where the sun will be at different points in the day.</p>,
        },
    ];

    return (
        <Wrapper>
            <ContainerBigWrapper>
                <div className="faq-content">
                    <h1 className='faq-content-title'>
                        FAQ
                    </h1>
                    <div className='faq-collapse'>
                        <Collapse
                            bordered={false}
                            className='collapse'
                            expandIcon={({isActive}) => !isActive ? <Plus/> :
                                <Minus/>}
                        >
                            {
                                items.map((elem, index) => <Panel className='panel' header={elem.label} key={elem.key}>
                                    <p>{elem.children}</p>
                                </Panel>)
                            }
                        </Collapse>
                    </div>
                </div>
            </ContainerBigWrapper>
        </Wrapper>
    );
};

export default Faq;

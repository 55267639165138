import React from 'react';
import {Modal} from "antd";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper/modules' ;
import useContainer from "./hook";
import "swiper/css";
import "swiper/css/navigation";
import "./style.scss";

const GalleryModal = ({onClose, initialSlide = 0, photos}) => {
    const {swiperRef, onSlideChangeHandler} = useContainer({initialSlide});

    return (
        <Modal onCancel={onClose} footer={null} open width={'750px'} className='gallery-modal'>
            <div className='gallery-modal-main'>
                <Swiper navigation={true} modules={[Navigation]} ref={swiperRef} onSlideChange={onSlideChangeHandler}>
                    {photos.map((photo) => (
                        <SwiperSlide key={photo.original} className='swiper'>
                            <div className="image" style={{backgroundImage: `url(${photo.original})`}}/>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </Modal>
    )
}

export default GalleryModal;

import {useEffect, useMemo, useState} from "react";
import {loadStripe} from "@stripe/stripe-js/pure";
import {STRIPE_PUBLIC_KEY} from "constants/genral";
import {debounce} from "lodash";

function useContainer() {
    const [visible, setVisible] = useState(false);
    const [isFetchingStripe, setIsFetchingStripe] = useState(true);

    const stripePromise = useMemo(async () => {
        const result = await loadStripe(STRIPE_PUBLIC_KEY);
        setIsFetchingStripe(false)
        return result;
    }, [])

    const onUpdateHandler = debounce(() => {
        if(stripePromise) setVisible(true)
    }, 300)

    useEffect(onUpdateHandler, [stripePromise]);

    return {
        visible,
        stripePromise,
        isFetchingStripe,
    }
}

export default useContainer;
import React from 'react';
import {Link} from "react-router-dom";
import {isEmpty} from "lodash";
import {API_HOST} from "constants/genral";
import "./style.scss";

const Card = ({title, img, id, sub, categories}) => {
    const link = isEmpty(sub) ? `/product-details/${id}` : `/products?categories=${categories}&parent_id=${id}`;
    return (
        <Link to={link} className='product-page-card'>
            <div className='image' style={{backgroundImage: `url(${API_HOST}${img})`}}/>
            <h4 className='name'>{title}</h4>
        </Link>
    );
};

export default Card;

import React from "react";
import {Link} from "react-router-dom";
import {API_HOST} from "constants/genral";
import "./style.scss";

const ProductCard = ({title, short_description, redirectRoute, id, img}) => (
    <Link to={`${redirectRoute}/${id}`} className='product-card'>
        <div style={{backgroundImage: `url(${API_HOST}/${img})`}} className='image-content'/>
        <div className='description-main'>
            <h3 className='title sub-title'>{title}</h3>
            <p className='description-text description'>{short_description}</p>
        </div>
        <div className='show-more'><button>See More</button></div>
    </Link>
)

export default ProductCard;

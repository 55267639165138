import React from 'react';
import {useDispatch} from "react-redux";
import {Popover} from "antd";
import MinusSvg from "assets/svg/MinusSvg";
import AddSvg from "assets/svg/AddSvg";
import {addPrice} from "state/basket";
import './style.scss';

const Counting = ({handleCount, count, price, showCountPopup, id, size}) => {
    const dispatch = useDispatch();

    const handleCounting = (e, opertaor) => {
        e.stopPropagation();
        if (opertaor === 'minus') {
            if (count !== 1) {
                handleCount(count - 1);
                dispatch(addPrice({price: +price, operator: 'minus'}));
            }
            return;
        }
        handleCount(count + 1);
        if(count === 10) return;
        dispatch(addPrice({price: +price, operator: null}));
    };

    return (
        <div className='countingContent'>
            <button className={size==='small'? 'counting global_background small': 'counting global_background'} onClick={(e) => handleCounting(e, 'minus')}>
                <MinusSvg/>
            </button>
            <span className='countValue'>{count}</span>
            <Popover
                open={String(showCountPopup) === String(id)}
                trigger="click"
                overlayClassName='count-message-popup'
                content={<p>If you want to order more than 10 products, please contact us.</p>}
            >
                <button className={size==='small'? 'counting global_background small': 'counting global_background'} onClick={(e) => handleCounting(e, 'add')}>
                    <AddSvg/>
                </button>
            </Popover>
        </div>
    );
};

export default Counting;

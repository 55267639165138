import {useDispatch, useSelector} from "react-redux";
import {fetchHomePageData} from "state/home/operations";
import {useEffect} from "react";

function useContainer() {
    const dispatch = useDispatch();
    const { products } = useSelector(({home}) => home);

    const onMountHandler = () => {
        dispatch(fetchHomePageData());
    }

    useEffect(onMountHandler, []);

    return {
        products,
    }
}

export default useContainer;
import {useCallback, useState} from "react";
import {createSearchParams, useSearchParams} from "react-router-dom";
import {isEmpty} from "lodash";

const useQueryParams = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [params, setParams] = useState({
        page: searchParams.get('page') || '1',
        categories: searchParams.get('categories') || [],
    });

    /** change params handler */
    const onChange = useCallback((values, keyName) => {
        const queryObj = {
            ...params,
        }
        queryObj[keyName] = String(values);
        if (isNaN(+queryObj?.page) || queryObj?.page === '1') delete queryObj.page;
        Object.keys(queryObj).forEach(item => {
            if (isEmpty(queryObj[item])) delete queryObj[item];
        })
        setSearchParams(`?${createSearchParams(queryObj).toString()}`);
        setParams(queryObj);
    }, [params]);

    return {
        params: {
            ...params,
            page: params.page || '1',
        },
        onChange,
        searchParams,
    }
};

export default useQueryParams;

import React from "react";
import "./style.scss";
import ButtonWithSvg from "../../shared/ButtonWithSvg";
import {Link} from "react-router-dom";

const Top = () => (
    <div className='top-component'>
        <div className='content'>
            <div className='information-content'>
                <div className='top'>
                    <div className='left'>
                        <h1 className='left-title'>
                            LED Coating Solutions
                        </h1>
                        <p className='description'>
                            LED COATING SOLUTIONS provides a complete solution for wood surface preparation and coating
                            machinery with a variety of LED-cured finishes that can be used for different wood surface
                            applications.
                        </p>
                        <Link to='/about'>
                            <ButtonWithSvg title={'Read More'} buttonClass='main-read-more'/>
                        </Link>
                    </div>
                    <div className='right'/>
                </div>

            </div>
        </div>
        <div className='bottom'>
            <div className='title-content'>
                <h2 className='title global-title'>Why LED coatings will transform the industry</h2>
                <p className='title-desc'>
                    The European LED oil finishes from LED Coating Solutions offer
                </p>
            </div>
            <div className='cards'>
                <p className='item'>
                    Instant cure on the job site or in the factory
                </p>
                <p className='item'>
                    The ability to layer colors with no abrasion or dry times between coats
                </p>
                <p className='item'>
                    Safety LED oils are non-combustible, with no off-gassing
                </p>
            </div>
            <div className='cards-small'>
                <p className='item-small'>
                    Long-term color stabilityry
                </p>
                <p className='item-small'>
                    Easy, fast repairability
                </p>
                <p className='item-small'>
                    Limitless colors
                </p>
            </div>
            {/*<div className='icon-cards'>*/}
            {/*    <SvgCard name='Great Support 24/7' description='We care your experiences'*/}
            {/*             icon={<Hours24 fill={"#22343D"}/>}/>*/}
            {/*    <SvgCard name='Quick Delivery' icon={<DeliverySvg fill={"#22343D"}/>}/>*/}
            {/*    <SvgCard name='Secure Payment' description='100% Secure Payment Method'*/}
            {/*             icon={<SecurePaymentSvg fill={"#22343D"}/>}/>*/}
            {/*</div>*/}
            <div className='button-content'>
                {/*<ButtonWithSvg title='Lorem Ipsum' />*/}
            </div>
        </div>
    </div>
)

export default Top;

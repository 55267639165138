import yup from 'utils/yupLocalised';

const validationSchema = yup.object().shape({
    first_name: yup.string().min(3).required(),
    last_name: yup.string().min(3).required(),
    email: yup.string().email().required(),
    password: yup.string().min(8).required(),
    verify_password: yup.string().min(8).required(),
});

export default validationSchema;

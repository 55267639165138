import React from "react";
import {Pagination, Skeleton} from "antd";
import {useSearchParams} from "react-router-dom";
import {isEmpty} from "lodash";
import EmptyComponent from "views/shared/EmptyComponent";
import Card from "../Card";
import "./style.scss";

const MainContent = ({onChange, products, loader, page}) => {
    const [searchParams] = useSearchParams();
    const categories = searchParams?.get('categories');

    if (loader) {
        return <Skeleton style={{marginTop: 50, marginLeft: 20}} active className='products-skeleton'/>
    }

    return (
        <>
            {!isEmpty(products?.models) ?
                <div className='main-content'>
                    <div className='oil-cards' style={{alignItems: products?.models.length > 2 ? 'center' : "flex-start"}}>
                        {products?.models.map(item => <Card key={item.id} {...item} categories={categories}/>)}
                    </div>
                    <div className='pagination'>
                        {+products?.dataCount > 8 &&
                            <Pagination
                                onChange={(page) => onChange(page, 'page')}
                                showTitle={false}
                                defaultPageSize={8}
                                defaultCurrent={page}
                                total={products?.dataCount || 0}
                            />}
                    </div>
                </div> : <EmptyComponent/>
            }
        </>
    )
}

export default MainContent;

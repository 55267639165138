import React from 'react';
import {Modal, Form, Input, Checkbox} from "antd";
import {FormikProvider} from "formik";
import InputFiled from "views/shared/forms/InputField";
import ButtonWithSvg from "views/shared/ButtonWithSvg";
import UserSvg from "assets/svg/UserSvg";
import ForgotPasswordIcon from "assets/svg/ForgotPasswordIcon";
import useContainer from "./hook";
import "./style.scss";

const SignInModal = ({onClose}) => {
    const {formikSignIn, loader, forgotVisible, formikForgot, handleChangeModalType, senDEmailSuccess, handleChangeRememberMe} = useContainer();

    return (
        <Modal onCancel={onClose} footer={null} open width={'630px'} className='sign-in-modal'>
            <div className='sign-in-modal-main'>
                <div className='title-content'>
                    <div className='account'>
                        {forgotVisible ? <ForgotPasswordIcon /> : <UserSvg width={33} height={33}/>}
                    </div>
                    <p className='title'>{forgotVisible ? 'Forgot password?' : 'Sign in'}</p>
                    {forgotVisible && !senDEmailSuccess &&
                        <div className='modal-description-content'>
                            <p>
                                Please enter your email address and we will send you
                                information about how to reset your password.
                            </p>
                        </div>
                    }
                </div>
                {forgotVisible ? <>
                        {senDEmailSuccess ?
                            <div className='send-email-success'>
                                <p className='description'>
                                    An email has been sent to <span className='email'>{`${formikForgot.values.email} `}</span>
                                        with a link allowing you to reset your password.
                                </p>
                            </div>
                            :
                            <Form onFinish={formikForgot.handleSubmit} className='form form-forgot'>
                                <FormikProvider value={formikForgot}>
                                    <InputFiled
                                        className="sign-in-input"
                                        name="email"
                                        formItemClassName="form-item"
                                        placeholder="Email"
                                    />
                                    <div className='button-content'>
                                        <ButtonWithSvg loader={loader} buttonClass='button' title='Send' htmlType='submit'/>
                                    </div>
                                </FormikProvider>
                            </Form>
                        }
                    </>
                    :
                    <Form onFinish={formikSignIn.handleSubmit} className='form'>
                        <FormikProvider value={formikSignIn}>
                            <InputFiled
                                className="sign-in-input"
                                name="email"
                                formItemClassName="form-item"
                                placeholder="Email"
                            />
                            <InputFiled
                                className="sign-in-input"
                                name="password"
                                formItemClassName="form-item"
                                placeholder="Password"
                                autoComplete="new-password"
                                asComponent={Input.Password}
                            />
                            <div className='forgot-password-button'>
                                <Checkbox onChange={handleChangeRememberMe}>Remember me</Checkbox>
                                <p onClick={handleChangeModalType}>Forgot Password ?</p>
                            </div>
                            <div className='button-content'>
                                <ButtonWithSvg loader={loader} buttonClass='button' title='Sign In' htmlType='submit'/>
                            </div>
                        </FormikProvider>
                    </Form>
                }
            </div>
        </Modal>
    )
}

export default SignInModal;
import * as React from "react"

function ForgotPasswordIcon(props) {
    return (
        <svg
            width={50}
            height={48}
            viewBox="0 0 50 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.538 21.99h1.684v-3.534c0-1.907.785-3.64 2.048-4.897v-.002a6.976 6.976 0 019.852 0l.002.002a6.895 6.895 0 012.048 4.896v3.535h1.342c.298 0 .543.243.543.54V33.94c0 .297-.245.54-.544.54H17.538a.544.544 0 01-.544-.54V22.53c0-.297.245-.54.544-.54zM6.788 8.982a21.332 21.332 0 00-2.06 3.016 24.12 24.12 0 00-3.047 13.973 23.302 23.302 0 005.442 13.054 26.729 26.729 0 003.716 3.628c4.522 3.614 9.878 5.396 15.22 5.346 5.345-.051 10.665-1.936 15.115-5.656A26.654 26.654 0 0044.8 38.65a23.254 23.254 0 004.912-10.978c.623-3.912.242-8.02-1.249-11.995-1.419-3.785-3.586-7.154-6.419-9.792A21.756 21.756 0 0032.225.6a21.507 21.507 0 00-3.658-.556 22.04 22.04 0 00-3.826.09 1.483 1.483 0 00-1.317 1.635c.09.813.826 1.4 1.644 1.31a19.058 19.058 0 013.312-.08A18.778 18.778 0 0140.005 8.04c2.498 2.327 4.414 5.31 5.673 8.668 1.307 3.484 1.642 7.081 1.097 10.5a20.308 20.308 0 01-4.294 9.587 23.687 23.687 0 01-3.22 3.288c-3.902 3.263-8.557 4.916-13.225 4.96-4.668.044-9.359-1.521-13.332-4.696a23.773 23.773 0 01-3.307-3.223 20.353 20.353 0 01-4.743-11.408c-.37-4.196.536-8.49 2.674-12.269a19.116 19.116 0 012.04-2.929l.217 5.945a1.483 1.483 0 001.539 1.425 1.483 1.483 0 001.434-1.53l-.346-9.473a1.484 1.484 0 00-1.69-1.413l-9.237 1.29A1.482 1.482 0 00.015 8.43c.112.81.864 1.376 1.678 1.263l5.095-.711zM21.932 21.99h8.53v-3.573c0-1.167-.48-2.227-1.252-2.995h-.001a4.266 4.266 0 00-6.025 0 4.217 4.217 0 00-1.252 2.995v3.573zm4.3 6.243l.86 3.95-2.36.009.693-4.003a1.594 1.594 0 01-1.113-1.515c0-.879.717-1.59 1.6-1.59.884 0 1.6.711 1.6 1.59 0 .77-.55 1.412-1.28 1.559z"
                fill="#76964A"
            />
        </svg>
    )
}

export default ForgotPasswordIcon

import React from 'react';
import {Button, Upload} from "antd";
import {isEmpty} from "lodash";
import {DeleteOutlined} from '@ant-design/icons';
import UploadImageSvg from "assets/svg/UploadImageSvg";
import useContainer from "./hook";
import "./style.scss";

const UploadImages = ({handleFileChange, fileList}) => {
   const { onChange, dummyRequest, createImageUrl, onRemoveHandler, showMoreChange, showMoreVisible, imageList } = useContainer({handleFileChange, fileList});

    return (
        <div className='upload-review-images'>
            <div className='image-list'>
                {!isEmpty(imageList) && (
                    imageList.map(item => (
                        <div className='image-card' key={item.uid} onClick={() => onRemoveHandler(item.uid)}>
                            <img className='image' src={createImageUrl(item?.originFileObj)} alt='...'/>
                            <div className='remove'><DeleteOutlined/></div>
                        </div>
                    ))
                )}
                {fileList.length > 3 && !showMoreVisible &&
                    <div className='image-card show-more' onClick={showMoreChange}>+{fileList.length - 3}</div>
                }
            </div>
            <Upload
                fileList={fileList}
                multiple
                listType="picture"
                customRequest={dummyRequest}
                onChange={onChange}
                showUploadList={false}
            >
                <Button type='text' icon={<UploadImageSvg width={22} height={20}/>} className='upload-button'>
                    Add image
                </Button>
            </Upload>
        </div>
    )
}

export default UploadImages;
import React from "react";
import {isEmpty} from "lodash";
import ButtonWithSvg from "views/shared/ButtonWithSvg";
import ReviewCard from "views/shared/ReviewCard";
import EmptyComponent from "views/shared/EmptyComponent";
import useContainer from "./hook";
import "./style.scss";

const Reviews = ({reviewData}) => {
    const {showReviewModal} = useContainer();
    return (
        <div className='reviews'>
            <div className='top'>
                <h2 className='title content-title'>Reviews</h2>
                <ButtonWithSvg title='Write a review' onClick={showReviewModal}/>
            </div>
            <div className='content'>
                {!isEmpty(reviewData) ?
                    reviewData.map((item) => <ReviewCard key={item.id} {...item}/>) :
                    <EmptyComponent marginTop={20} description='No review'/>
                }
            </div>
        </div>
    )
}

export default Reviews;

import React from 'react';
import {Modal, Spin} from "antd";
import {DownloadOutlined, EyeOutlined, LoadingOutlined } from "@ant-design/icons";
import {isEmpty} from "lodash";
import {API_HOST} from "constants/genral";
import useContainer from "./hook";
import "./style.scss";

const ServicesPdfModal = ({onClose, data}) => {
    const {onDownload, loader} = useContainer();

    return (
        <Modal onCancel={onClose} footer={null} open width={'750px'} className='services-pdf-modal'>
            <div className='services-pdf-modal-main'>
                <div className="pdfs">
                    {!isEmpty(data) && (
                        data.map((item, index) => (
                            <div key={item.title + index} className="pdf">
                                <p className="name">{item.pdf_title}</p>
                                <div className='operationContainer'>
                                    <a className="operation" href={`${API_HOST}${item.url}`} target="_blank" rel="noreferrer">
                                        <EyeOutlined/>
                                    </a>
                                    <span className="operation">
                                    {loader ?
                                        <Spin indicator={<LoadingOutlined style={{fontSize: 24, color: 'green'}} spin/>} /> :
                                        <DownloadOutlined onClick={() => onDownload(item.url, item.pdf_title)}/>}
                                </span>
                                </div>

                            </div>
                        ))
                    )}
                </div>
            </div>
        </Modal>
    )
}

export default ServicesPdfModal;

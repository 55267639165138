import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {fetchProductById} from "state/products/operations";

function useContainer() {
    const dispatch = useDispatch();
    const {id} = useParams();
    const {productById, loader} = useSelector(({products}) => products);


    const onMountHandler = () => {
        const params = {id}
        dispatch(fetchProductById(params));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(onMountHandler, [id]);

    return {
        productById,
        loader,
    }
}

export default useContainer;

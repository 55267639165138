import React from "react";
import {Form} from 'antd';
import {useField} from "formik";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import "./style.scss";

const PhoneNumberFiled = ({label, name, placeholder, formItemClassName, labelClassName, hasFeedback, ...props}) => {
    const [field, meta, helpers] = useField(name);
    const hasError = meta.touched && meta.error;
    const Error = hasError ? <div className="error" style={{color: 'red', marginLeft: 10, marginBottom: 10}}>{meta.error}</div> : undefined;
    const { setValue } = helpers;

    const onChangeHandler = value => setValue(value);

    return (
        <Form.Item
            style={{margin: '2px 0 12px'}}
            className={formItemClassName}
            label={<span className={labelClassName}>{label}</span>}
            htmlFor={name}
            validateStatus={hasError}
            help={Error}
        >
            <PhoneInput
                country='us'
                id={name}
                placeholder={placeholder}
                {...field}{...props} value={meta.value || null}
                autoFormat
                enableAreaCodes
                countryCodeEditable
                enableSearch
                disableSearchIcon
                onChange={onChangeHandler}/>
        </Form.Item>
    );
};

PhoneNumberFiled.defaultProps = {
    formItemClassName: '',
    labelClassName: '',
    placeholder: '',
    label: '',
};

export default PhoneNumberFiled;

import React, {useEffect} from "react";
import {isEmpty} from "lodash";
import {Link, NavLink} from "react-router-dom";
import {Menu, Popover} from "antd";
import VectorSvg from "assets/svg/VectorSvg";
import BasketOrSignIn from "views/Wrapper/Header/BasketOrSignIn";
import LEDIcon from "assets/svg/LEDIcon";
import Logo from "assets/svg/Logo";
import MobileHeader from "./MobileHeader";
import useContainer from "./hook";
import 'antd/dist/antd.min.css';
import "./style.scss";
import {useDispatch} from "react-redux";
import {getSessionToken} from "../../../state/users/operations";

const Header = () => {
    const {getItems, menuItems, ref} = useContainer();
    const dispatch = useDispatch()

    useEffect(() => {
        const sessionToken = localStorage.getItem('sessionToken');
        if (!sessionToken) {
            dispatch(getSessionToken())
        }
    }, [])

    return (
        <>
            <div className='header'>
                <div className='content'>
                    <div className='logoDiv'>
                        <Link to='/' className='left-content'>
                            <LEDIcon/>
                        </Link>
                        <span className='logo-by'>by</span>
                        <a className='logo-2' href={'http://www.archetypalgallery.com/'} target="_blank"
                           rel="noreferrer">
                            <Logo width={56}
                                  height={51}/>
                        </a>
                    </div>
                    <div className='center-content drop-class'>
                        {menuItems?.map(item => (!isEmpty(item.children) ?
                            <Popover
                                placement='bottom'
                                trigger='hover'
                                ref={ref}
                                key={item.path + item.title}
                                content={
                                    <Menu
                                        className="popover-dropdown"
                                        theme='light'
                                        mode='inline'
                                        items={getItems(item.children)}
                                    />
                                }
                            >
                                <NavLink to={item.path}
                                         className={({isActive}) => isActive ? 'activeNavLink' : 'navLink'}>
                                    {item.name}
                                    <span><VectorSvg width={10} height={10} fill={'#474747'}/></span>
                                </NavLink>
                            </Popover> :
                            <NavLink key={item.path + item.title} to={item.path}
                                     className={({isActive}) => isActive ? 'activeNavLink' : 'navLink'}>
                                {item.name}
                            </NavLink>)
                        )}
                    </div>
                    <div className='right-content'><BasketOrSignIn/></div>
                </div>
            </div>
            <div className='mobile-menu-wrapper'>
                <MobileHeader menuItems={menuItems} getItems={getItems}/>
            </div>
        </>
    )
}

export default Header;

import * as React from "react"

function BasketSvg(props) {
    return (
        <svg
            width={28}
            height={28}
            viewBox="0 0 28 28"
            fill="red"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M3.255 19.176a3.95 3.95 0 003.111 3.855 2.616 2.616 0 00-1.485 2.356A2.616 2.616 0 007.494 28a2.616 2.616 0 002.612-2.613c0-.97-.531-1.817-1.317-2.267H19.21a2.613 2.613 0 00-1.317 2.267A2.616 2.616 0 0020.506 28a2.616 2.616 0 002.613-2.613c0-.97-.531-1.817-1.318-2.267h1.958a.986.986 0 000-1.972H7.2a1.974 1.974 0 01-1.973-1.972v-1.467c.58.337 1.254.53 1.972.53h13.308c2.076 0 4.148-1.563 4.718-3.559l2.44-8.543a.984.984 0 00-.947-1.257H5.227v-.936A3.948 3.948 0 001.282 0a.986.986 0 000 1.972c1.087 0 1.972.885 1.972 1.972v15.232zm17.251 6.852a.642.642 0 11.002-1.283.642.642 0 01-.002 1.283zm-13.012 0a.642.642 0 11.002-1.283.642.642 0 01-.002 1.283z"
            />
        </svg>
    )
}

export default BasketSvg

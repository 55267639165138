import React, {useEffect, useState} from 'react';
import {Radio, Tooltip} from "antd";
import {isEmpty} from "lodash";

const CanCube = ({item, priseAndSize, handleSizeChange, selectedType, selectedShen}) => {

    const [tooltipCanText, setTooltipCanText] = useState(false)
    const [disabled, setDisabled] = useState(false)

    const getTooltipCanText = (item) => {
        if (item.id === priseAndSize.id) {
            setTooltipCanText(true)
        } else setTooltipCanText(false)
    }

    const handleClick = (id) => {

        if(priseAndSize.id !== id){
            handleSizeChange(id)
            setTooltipCanText(!tooltipCanText)
        }
    }

    const handleText = ()=>{
        if(tooltipCanText){
            return ''
        }
        else return 'Select'
    }

    const getClassName = ()=>{
        if(item.id===priseAndSize.id){
            return 'newActiveButton'
        }

        if(disabled){
            return 'newButton disabledButton'
        }else return 'newButton'
    }

    useEffect(()=>{
        if(!isEmpty(item['type_data'])){
            if(selectedType){
             const status =   item['type_data'].find(el=>el.title === selectedType)
              if(!status){
                  setDisabled(true)
              }else setDisabled(false)
            }else if(selectedShen){
                const status =   item['type_data'].find(el=>el.tag_id === selectedShen)
                if(!status){
                    setDisabled(true)
                }else setDisabled(false)
            }else setDisabled(false)
        }


    },[selectedType, item, selectedShen])








    return (
        <Tooltip title={handleText()} key={item.id} mouseEnterDelay={0.4}  overlayStyle={{fontSize:12}}>
            <button value={item.id} className={getClassName()} disabled={disabled}
                          onClick={()=>handleClick(item.id)}
                          onMouseEnter={() => getTooltipCanText(item)}>
                {item.size}
                {item.size_type === 'Gallon' && 'gal'}
                {item.size_type === 'Liter' && 'ltr'}
                {item.size_type === 'Milliliter' && 'ml'}
                {item.size_type === 'Quart' && 'qt'}
            </button>
        </Tooltip>
    );
};

export default CanCube;

import img2010 from "../assets/images/awardsImages/2010.png";
import img2011 from "../assets/images/awardsImages/2011.png";
import img2012 from "../assets/images/awardsImages/2012.png";
import img2014 from "../assets/images/awardsImages/2014.png";
import img2015 from "../assets/images/awardsImages/2015.png";
import img2020 from "../assets/images/awardsImages/2020.png";
import img2016 from "../assets/images/awardsImages/2016.png";
import img2021 from "../assets/images/awardsImages/2021.png";
import img2019 from "../assets/images/awardsImages/2019.png";
import img2014w from "../assets/images/awardsImages/2014wood.png";
import img2014m from "../assets/images/awardsImages/2014mosaics.png";


export const data = [
    {
        title: 'Best Limited Species',
        year: 2010,
        img: img2010,
        link: 'https://www.woodfloorbusiness.com/installation/design/article/15124996/prizes-on-the-potomac-2010-wood-floor-of-the-year-winners#archetypal'
    },
    {
        title: 'NWFA Wood Floor of the Year:Best Commercial',
        year: 2011,
        img: img2011,
        link: 'https://www.woodfloorbusiness.com/installation/extraordinary-floors/article/15125313/san-diego-spotlight-wood-floor-of-the-year-2011#archetypal'

    },
    {
        title: 'NWFA Wood Floor of the Year: Members Choice and Best Residential',
        year: 2012,
        img: img2012,
        link: 'https://www.woodfloorbusiness.com/installation/extraordinary-floors/article/15125434/honored-in-orlando-2012-wood-floor-of-the-year-winners#archetypal'

    },
    {
        title: 'NWFA Wood Floor of the Year: Best Commercial',
        year: 2014,
        img: img2014,
        link: 'https://www.woodfloorbusiness.com/installation/extraordinary-floors/article/15128855/wood-floor-of-the-year-2014-taking-center-stage#archetypal'
    },
    {
        title: ' NWFA Wood Floor of the Year: Best Reclaimed',
        year: 2015,
        img: img2015,
        link: 'https://www.woodfloorbusiness.com/installation/extraordinary-floors/article/15130218/wood-floor-of-the-year-the-best-floors-of-2015#archetypal'
    },
    {
        title: 'NWFA Wood Floor of the Year: Best Marquetry/Inlay',
        year: 2020,
        img: img2020,
        link: 'https://hardwoodfloorsmag.com/2020/05/05/nwfa-announces-2020-wood-floor-of-the-year-awards/'

    },
    {
        title: 'NWFA Wood Floor of the Year: Members Choice',
        year: 2016,
        img: img2016,
        link: 'https://www.woodfloorbusiness.com/installation/extraordinary-floors/article/15131339/wood-floor-of-the-year-awards-2016-stories-behind-the-floors'

    },
    {
        title: 'Wood Floor Business Design Awards: Best Residential',
        year: 2021,
        img: img2021,
        link: 'https://www.woodfloorbusiness.com/installation/extraordinary-floors/article/15135922/craftsmanship-perseveres-see-the-winners-of-the-2021-wood-floor-business-design-awards#Residential'
    },
    {
        title: 'Wood Floor Business feature article: How We Did This Job: An Obsession With Color Fixed This Floor',
        year: 2019,
        img: img2019,
        link: 'https://www.woodfloorbusiness.com/finishing/stain-custom-color/article/15134468/how-we-did-this-job-an-obsession-with-color-fixed-this-floor'
    },
    {
        title: 'Wood Floor Business feature article: Award-Winning Wood Floor Artist Makes Gold-Leafed Wood Wall Mosaics',
        year: 2014,
        img: img2014w,
        link: 'https://www.woodfloorbusiness.com/news/article/15134700/how-they-did-it-the-mosaic-tiger-wood-floor-explained'
    },
    {
        title: 'Wood Floor Business feature article: Award-Winning Wood Floor Artist Makes Gold-Leafed Wood Wall Mosaics',
        year: 2014,
        img: img2014m,
        link: 'https://www.woodfloorbusiness.com/installation/extraordinary-floors/article/15129290/floor-of-the-year-winner-makes-goldleafed-wood-wall-mosaics'
    },
]

import {createSlice, isAnyOf} from "@reduxjs/toolkit";
import {fetchProductById, fetchProductCategories, fetchProducts, sendReview, addToCard, removeFromCard} from "./operations";

const initialState = {
    productCategories: {},
    products: {},
    productById: {},
    loader: false,
    fetchCategoriesLoader: false,
    loaderCart: false,
    sendReviewsLoader: false,
    deleteOrAddLoader: false,
};

export const productsSlice = createSlice({
    name: 'products',
    initialState,

    extraReducers: (builder) => {
        builder
            .addCase(sendReview.pending, (state) => {
                state.sendReviewsLoader = true;
            })
            .addCase(addToCard.fulfilled, (state, {payload}) => {
                state.productById.basketData = [payload, ...state?.productById?.basketData];
            })
            .addCase(removeFromCard.fulfilled, (state, {payload}) => {
                state.productById.basketData = state.productById?.basketData?.filter(item => {
                    return item.id !== payload.id
                });
            })
            .addCase(sendReview.fulfilled, (state, {payload}) => {
                state.sendReviewsLoader = false;
                state.productById.reviews = [payload, ...state.productById.reviews];
            })
            .addCase(fetchProductById.fulfilled, (state, {payload}) => {
                state.productById = payload;
                state.loader = false;
            })
            .addCase(fetchProductCategories.fulfilled, (state, {payload}) => {
                state.productCategories = payload;
                state.fetchCategoriesLoader = false;
            })
            .addCase(fetchProductCategories.pending, (state) => {
                state.fetchCategoriesLoader = true;
            })
            .addCase(fetchProducts.fulfilled, (state, {payload}) => {
                state.loader = false;
                state.products = payload;
            })
            .addMatcher(isAnyOf(fetchProducts.pending, fetchProductById.pending),
                (state) => {
                    state.loader = true;
                })
            .addMatcher(isAnyOf(addToCard.pending, removeFromCard.pending),
                (state) => {
                    state.deleteOrAddLoader = true;
                })
            .addMatcher(isAnyOf(addToCard.fulfilled, removeFromCard.fulfilled),
                (state) => {
                    state.deleteOrAddLoader = false;
                })
    }
});

export default productsSlice.reducer;

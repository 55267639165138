import React from "react";
import {Button} from "antd";
import "./style.scss";

const ButtonWithSvg = ({title, buttonClass = '', loader = false, disabled=false, ...props}) => (
    <div className='general-button'>
        <Button type="text" loading={loader}  className={`button-with-svg ${buttonClass}`} disabled={!!disabled} {...props}>
            <p className='button-text'>{title}</p>
        </Button>
    </div>
)

export default ButtonWithSvg;

import {useDispatch, useSelector} from "react-redux";
import {fetchPaymentHistory} from "state/basket/operations";
import {useEffect} from "react";

function useContainer() {
    const dispatch = useDispatch();
    const {paymentHistory, fetchPaymentsLoader} = useSelector(({basket}) => basket);

    const onMountHandler = () => {
        dispatch(fetchPaymentHistory())
    }

    useEffect(onMountHandler, []);

    return {
        fetchPaymentsLoader,
        paymentHistory,
    }
}

export default useContainer;
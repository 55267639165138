import {useCallback, useState} from "react";
import {useFormik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import validationSchemaSignIn from "utils/yupLocalised/scheme/signIn";
import validationSchemaForgot from "utils/yupLocalised/scheme/forgotPasswordEmail";
import {sendEmailForForgotPassword, userSignIn} from "state/users/operations";

function useContainer() {
    const dispatch = useDispatch();
    const {loader} = useSelector(({users}) => users);
    const [forgotVisible, setForgotVisible] = useState(false);
    const [senDEmailSuccess, setSendEmailSuccess] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);

    const onSubmitSignIn = (values) => {
        const data = {values: values, rememberMe}
        dispatch(userSignIn({
            data, callback: (message) => {
                formikSignIn.setErrors({password: message});
            }
        }));
    }

    const onSubmitForgot = (values) => {
        dispatch(sendEmailForForgotPassword({values, callback: (message) => {
                if (message === 'success') {
                    setSendEmailSuccess(true);
                    return;
                }
                formikForgot.setFieldError('email', message);
            }
        }));
    }

    const formikSignIn = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchemaSignIn,
        onSubmit: onSubmitSignIn,
    });

    const handleChangeRememberMe = useCallback(({target: {checked}}) => {
        setRememberMe(checked)
    }, [])

    const formikForgot = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationSchemaForgot,
        onSubmit: onSubmitForgot,
    });

    const handleChangeModalType = useCallback(() => {
        setForgotVisible(!forgotVisible);
    }, [forgotVisible]);

    return {
        formikForgot,
        formikSignIn,
        loader,
        forgotVisible,
        handleChangeModalType,
        senDEmailSuccess,
        handleChangeRememberMe,
    }
}

export default useContainer;
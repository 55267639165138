import {createAsyncThunk} from "@reduxjs/toolkit";
import httpClient from "state/httpClient";
import {appendFormData} from "utils/helpers";
import {hideModal} from "state/modal";

export const fetchServices = createAsyncThunk(
    'services/fetchServices', async (payload) => {
        try {
            const {data} = await httpClient.get(`/services`);
            return data.data;
        } catch {
            //
        }
    });

export const fetchServiceById = createAsyncThunk(
    'services/fetchServiceById', async (payload) => {
        try {
            const {data} = await httpClient.get(`services/view`, {params: payload});
            return data.data;
        } catch {
            //
        }
    });

export const sendServiceRequest = createAsyncThunk(
    'services/sendServiceRequest', async (payload, {dispatch}) => {
        const body = appendFormData(payload)
        try {
            await httpClient.post(`/requests`, body);
            dispatch(hideModal())
        } catch {
            //
        }
    });
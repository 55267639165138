import * as React from "react"

function UploadImageSvg(props) {
    return (
        <svg
            width={26}
            height={27}
            viewBox="0 0 26 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M14.083 6.042h-3.977c-.575 0-1.126.228-1.532.634l-.898.898a2.167 2.167 0 01-1.532.634h-1.81c-1.197 0-2.167.97-2.167 2.167v10.833c0 1.197.97 2.167 2.166 2.167H19.5c1.197 0 2.167-.97 2.167-2.167v-7.583m-3.25-7.583h6.5m-3.25 3.25v-6.5m-9.75 17.333a4.333 4.333 0 100-8.667 4.333 4.333 0 000 8.667z"
                stroke="#fff"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default UploadImageSvg

import {createAsyncThunk} from "@reduxjs/toolkit";
import httpClient from "state/httpClient";

export const fetchMedia = createAsyncThunk(
    'medias/fetchMedia', async (params) => {
        try {
            const {data} = await httpClient.get(`/media/data`, {params});
            return data.data;
        } catch (e) {
            console.log(e)
        }
    });
import {useCallback, useEffect, useState} from "react";

function useContainer({handleFileChange, fileList}) {
    const [showMoreVisible, setShowMoreVisible] = useState(false);
    const [imageList, setImageList] = useState([]);

    const onChange = ({fileList: newFileList}) => {
        setShowMoreVisible(false);
        handleFileChange(newFileList);
    };

    const createImageUrl = (originFileObj) => {
        const blob = new Blob([originFileObj], {type: "image/jpeg"});
        return URL.createObjectURL(blob);
    };

    const dummyRequest = ({onSuccess}) => setTimeout(() => {
        onSuccess("ok");
    }, 0);

    const onRemoveHandler = (uid) => {
        const newList = fileList.filter(item => item.uid !== uid);
        handleFileChange(newList);
    }

    const showMoreChange = useCallback(() => {
        setShowMoreVisible(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showMoreVisible])

    const onUpdateHandler = () => {
       if(showMoreVisible) {
           setImageList(fileList);
       } else {
           setImageList(fileList.slice(0, 3))
       }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(onUpdateHandler, [fileList, showMoreVisible]);

    return {
        onRemoveHandler,
        dummyRequest,
        createImageUrl,
        onChange,
        showMoreVisible,
        showMoreChange,
        imageList,
    }
}

export default useContainer;
import React, {useState} from "react";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import {CloseOutlined} from "@ant-design/icons";
import MobileMenuISvg from "assets/svg/MobileMenuISvg";
import LEDIcon from "assets/svg/LEDIcon";
import Logo from "assets/svg/Logo";
import BasketOrSignIn from "../BasketOrSignIn";
import "./style.scss";
import LEDIconNew from "../../../../assets/svg/LEDIconNew";
import LedIcon from '../../../../assets/images/LedNewIcon.png'

const MobileHeader = ({getItems, menuItems}) => {
    const [menuVisible, setMenuVisible] = useState(false);

    return (
        <>
            <div className='mobile-header-parent'>
                <div className='mobile-header'>
                    <div className='mobile-content'>
                        <div className='left-content'>
                            <Link to='/'>
                                <div style={{zIndex: 100, height: '100%'}}>
                                    {/*<LEDIcon width={96} height={53} />*/}
                                    {/*<LEDIconNew width={96} height={53} />*/}
                                    {/*<img src={LedIcon} alt="#" style={{width:80,marginRight:10, height:50}}/>*/}
                                    <LEDIcon width={150} height={70} />
                                </div>
                            </Link>
                            <span className='logo-by'>by</span>
                            <a className='logo-2' href={'http://www.archetypalgallery.com/'} target="_blank" rel="noreferrer">
                                <Logo width={60} heiht={10} />
                            </a>
                        </div>
                        <div className='mobile-menu-icon' onClick={() => setMenuVisible(prev => !prev)}>
                            {menuVisible ? <CloseOutlined style={{color: '#68885D', fontSize: 23}}/> : <MobileMenuISvg/>}
                        </div>
                    </div>
                </div>
                {menuVisible && (
                    <>
                        <div className='mobile-drop-menu-out-side' onClick={() => setMenuVisible(false)}/>
                        <div className='mobile-popup-wrapper'>
                            <Menu className="mobile-popover-dropdown" theme='light' mode='inline'
                                  items={getItems(menuItems)}/>
                            <div className='mobile-menu-account-content'><BasketOrSignIn/></div>
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

export default MobileHeader;

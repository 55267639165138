import {createSlice} from "@reduxjs/toolkit";
import {fetchHomePageData} from "./operations";

const initialState = {
    products: [],
    loader: false,
};

export const homeSlice = createSlice({
    name: 'home',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchHomePageData.fulfilled, (state, {payload}) => {
            state.products = payload.product;
            state.loader = false;
        })
        builder.addCase(fetchHomePageData.pending, (state) => {
            state.loader = true;
        })
    }
});

export default homeSlice.reducer;

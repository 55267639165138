import {useCallback, useEffect, useRef, useState} from "react";

function useContainer({initialSlide}) {
    const [activeIndex, setActiveIndex] = useState(initialSlide);
    const swiperRef = useRef();

    const onSlideChangeHandler = useCallback((swiper) => {
        setActiveIndex(swiper.activeIndex);
    }, []);

    const onMountHandler = () => {
        swiperRef.current.swiper.slideTo(activeIndex);
    };

    useEffect(onMountHandler, []);

    return {
        swiperRef,
        onSlideChangeHandler,
    }
}

export default useContainer;

import React, {useMemo} from "react";
import ImageGallery from 'react-image-gallery';
import {isEmpty} from "lodash";
import {API_HOST} from "constants/genral";
import RenderThumbInner from "views/shared/Gallery/RenderThubInner";
import RenderItem from "views/shared/Gallery/RenderItem";
import {useDispatch} from "react-redux";
import {showModal} from "state/modal";
import "./style.scss";

const Gallery = ({carouselData}) => {
    const dispatch = useDispatch();

    const data = useMemo(() => {
        return carouselData?.map((item, index) => {
            item = {
                original: `${API_HOST}${item}`,
                thumbnail: `${API_HOST}${item}`,
                index,
            };
            return item;
        });
    }, [carouselData]);

    const openGalleryModal = (initialSlide) => {
        dispatch(showModal({
            modalType: 'GALLERY_MODAL',
            modalProps: {
                initialSlide,
                photos: data,

            }
        }))
    }

    return (
        <div className='gallery'>
            {!isEmpty(carouselData) &&
                <ImageGallery
                    showPlayButton={false}
                    infinite={false}
                    showNav={false}
                    showFullscreenButton={false}
                    items={data}
                    renderThumbInner={RenderThumbInner}
                    renderItem={(props) => <div onClick={() => openGalleryModal(props.index)}> <RenderItem {...props}/></div>}
                />
            }
        </div>
    )
}

export default Gallery;


import WriteReviewModal from "views/modals/WriteReviewModal";
import SignInModal from "views/modals/SignInModal";
import SignUpModal from "views/modals/SignUpModal";
import ServiceRequestModal from "views/modals/ServiceRequestModal";
import ChangePassword from "views/modals/ChangePassword";
import ForgotPasswordModal from "views/modals/ForgotPasswordModal";
import PaymentModal from "views/modals/PaymentModal";
import GalleryModal from "views/modals/GalleryModal";
import ServicesPdfModal from "views/modals/ServicesPdfModal";
import PaymentAddressModal from "../modals/PaymentAddressModal";

const MODAL_COMPONENTS = {
    WRITE_REVIEW_MODAL: WriteReviewModal,
    SIGN_IN_MODAL: SignInModal,
    SIGN_UP_MODAL: SignUpModal,
    SERVICE_REQUEST_MODAL: ServiceRequestModal,
    CHANGE_PASSWORD: ChangePassword,
    FORGOT_PASSWORD_MODAL: ForgotPasswordModal,
    PAYMENT_MODAL: PaymentModal,
    GALLERY_MODAL: GalleryModal,
    SERVICES_PDF_MODAL: ServicesPdfModal,
    PAYMENT_ADDRESS_MODAL:PaymentAddressModal
};

export default MODAL_COMPONENTS;

import React from 'react';
import {Modal, Form, Rate} from "antd";
import {FormikProvider} from "formik";
import InputFiled from "views/shared/forms/InputField";
import ButtonWithSvg from "views/shared/ButtonWithSvg";
import CKEditorTextArea from "./CKEditorTextArea";
import UploadImages from "./UploadImages";
import useContainer from "./hook";
import "./style.scss";

const WriteReviewModal = ({onClose, productId}) => {
    const { formik, onChangeRate, onChangeEditor, handleFileChange, fileList, loader } = useContainer({productId});
    const messageMeta = formik.getFieldMeta('message');
    const starsMeta = formik.getFieldMeta('stars');

    return (
        <Modal onCancel={onClose} footer={null} open width={660} className='write-review-modal'>
            <div className='reviews-modal-content'>
                <div className='title-content'>
                    <h2 className='title'>Add Review</h2>
                </div>
                <Form onFinish={formik.handleSubmit} className='form'>
                    <FormikProvider value={formik}>
                        <div className='rate-content'>
                            <p className='label'>Rating</p>
                            <Rate className='rating' value={formik.values.stars} allowHalf onChange={onChangeRate}/>
                            <p style={{color: 'red', marginLeft: 10}}>{starsMeta.touched && formik.errors?.stars && formik.errors.stars}</p>
                        </div>
                        <InputFiled
                            className="input"
                            name="title"
                            formItemClassName="form-item"
                            label="Title review"
                        />
                        <div className='ckeditor-content'>
                            <p className='label'>How was your overall experience</p>
                            <CKEditorTextArea onChangeEditor={onChangeEditor} value={formik.values.message} />
                            <p style={{color: 'red', marginLeft: 10}}>{messageMeta.touched && formik.errors?.message && formik.errors.message}</p>
                        </div>
                        <div className='upload-image-content'>
                            <UploadImages handleFileChange={handleFileChange} fileList={fileList} />
                        </div>
                        <div className='button-content'>
                            <ButtonWithSvg loader={loader} title='Add Review' htmlType='submit' />
                        </div>
                    </FormikProvider>
                </Form>
            </div>
        </Modal>
    )
}

export default WriteReviewModal;

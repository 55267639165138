import React from "react";
import {Form, Select} from 'antd';
import {useField} from "formik";

const SelectFiled = ({label, name, placeholder, formItemClassName, labelClassName, hasFeedback, asComponent: Component, ...props}) => {
    const [field, meta, helpers] = useField(name);
    const hasError = meta.touched && meta.error;
    const Error = hasError ? <div className="error" style={{color: 'red', marginLeft: 10, marginBottom: 10}}>{meta.error}</div> : undefined;
    const { setValue } = helpers;

    const onChangeHandler = value => setValue(value);

    return (
        <Form.Item
            style={{margin: '2px 0 12px'}}
            className={formItemClassName}
            label={<span className={labelClassName}>{label}</span>}
            htmlFor={name}
            validateStatus={hasError}
            help={Error}

        >
            <Component id={name} placeholder={placeholder} {...field} {...props} value={meta.value || null} onChange={onChangeHandler}/>
        </Form.Item>
    );
};

SelectFiled.defaultProps = {
    asComponent: Select,
    formItemClassName: '',
    labelClassName: '',
    placeholder: '',
    label: '',
};

export default SelectFiled;

import * as React from "react"

function Minus(props) {
    return (
        <svg
            width={17}
            height={2}
            viewBox="0 0 17 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M1.896 1h14"
                stroke="#696969"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default Minus

import * as React from "react"

function Youtube(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M23.761 7.199s-.235-1.655-.956-2.381C21.89 3.86 20.869 3.857 20.4 3.8c-3.356-.243-8.395-.243-8.395-.243h-.01s-5.038 0-8.395.243c-.469.057-1.49.061-2.405 1.018-.721.726-.951 2.38-.951 2.38S0 9.145 0 11.086v1.818c0 1.941.239 3.886.239 3.886s.234 1.655.951 2.382c.914.956 2.114.923 2.649 1.026 1.922.183 8.161.24 8.161.24s5.044-.01 8.4-.25c.469-.055 1.49-.06 2.405-1.016.721-.727.956-2.382.956-2.382S24 14.85 24 12.904v-1.819c0-1.94-.239-3.886-.239-3.886zM9.52 15.11V8.366l6.483 3.384-6.483 3.361z"
                fill="#636270"
            />
        </svg>
    )
}

export default Youtube

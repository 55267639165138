import {createAsyncThunk} from "@reduxjs/toolkit";
import {appendFormData} from "utils/helpers";
import httpClient from "state/httpClient";
import {getAllBasket} from "state/basket/operations";

export const sendCheckoutForm = createAsyncThunk(
    'payment/sendCheckoutForm', async (payload, {rejectWithValue, dispatch}) => {
        try {
            await httpClient.post(`/basket/stripe`, appendFormData(payload));
            await dispatch(getAllBasket());
            return {
                status: 'success',
                value: 'Your payment has been successfully completed',
            };
        } catch (e) {
            console.log(e)
            return rejectWithValue({
                status: 'rejected',
                value: e.response.data.message,
            })
        }
    });

export const checkCouponPercent = createAsyncThunk(
    'payment/checkCouponPercent', async (payload, {rejectWithValue, dispatch}) => {
        try {
            const {data} = await httpClient.get(`/default/coupon?code=${payload.coupon}`)
            if (data.discount) {
                return {percent: data.discount, errorMessage: '', couponNumber:payload.coupon}
            } else {
                return {percent: null, errorMessage: 'Invalid Coupon Code', couponNumber:null}
            }


        } catch (e) {
            console.log(e)

        }
    });

import * as React from "react"

function CloseSvg(props) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M1.825.313A1.07 1.07 0 10.313 1.825L6.488 8 .313 14.175a1.07 1.07 0 101.512 1.512L8 9.512l6.175 6.175a1.07 1.07 0 001.512-1.512L9.512 8l6.175-6.175A1.07 1.07 0 1014.175.313L8 6.488 1.825.313z"
                fill="#5D5D5D"
            />
        </svg>
    )
}

export default CloseSvg

import React from "react";
import "./style.scss";

const ContainerBigWrapper = ({children}) => (
    <div className='container-big-wrapper'>
        <div className='wrapper-content'>
            {children}
        </div>
    </div>
)
export default ContainerBigWrapper;
import React from "react";
import {Route, Routes, Navigate} from "react-router-dom";
import ModalRoot from "views/ModalRoot/container";
import Home from "views/Home";
import ProductDetails from "views/ProductDetails";
import ProductsCategories from "views/ProductsCategories";
import Products from "views/Products";
import Services from "views/Services";
import HowToUse from "views/HowToUse";
import Media from "views/Media";
import ColorLab from "views/ColorLab";
import Basket from "views/Basket";
import ServiceDetails from "views/ServiceDetails";
import Profile from "views/Profile";
import UserGuard from "guards/UserGuard";
import useMount from "hooks/useMount";
import PageNotFound from "views/PageNotFound";
import Payments from "views/Payments";
import Policy from "views/Policy";
import TechnicalData from "views/ TechnicalData";
import Awards from "views/Awards";
import Constarction from "views/Constraction";
import About from "./views/About";
import FAQ from "./views/FAQ";

const App = () => {
    useMount();
    return (
        <>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/constarction" element={<Constarction/>}/>
                <Route path="/products" element={<Products/>}/>
                <Route path="/products-categories" element={<ProductsCategories/>}/>
                <Route path="/product-details/:id" element={<ProductDetails/>}/>
                <Route path="/services" element={<Services/>}/>
                <Route path="/service-details/:id" element={<ServiceDetails/>}/>
                <Route path="/how-to-use" element={<HowToUse/>}/>
                {/*<Route path="/media" element={<Media/>}/>*/}
                <Route path="/color-lab" element={<ColorLab/>}/>
                <Route path="/policy" element={<Policy/>}/>
                <Route path="/technical-data-sheets" element={<TechnicalData/>}/>
                <Route path="/awards" element={<Awards/>}/>
                <Route path="/user/basket" element={<Basket/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/faq" element={<FAQ/>}/>

                <Route path='/user' element={<UserGuard/>}>
                    <Route path="/user/profile" element={<Profile/>}/>
                    <Route path="/user/payments" element={<Payments/>}/>
                </Route>

                <Route path="/*" element={<Navigate to='/404'/>}/>
                <Route path="/404" element={<PageNotFound />}/>
            </Routes>
            <ModalRoot/>
        </>
    )
}

export default App;

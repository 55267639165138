import {createAsyncThunk} from "@reduxjs/toolkit";
import httpClient from "state/httpClient";
import {appendFormData} from "../../utils/helpers";
import {hideModal} from "../modal";

export const changeUserNameAndLastName = createAsyncThunk(
    'profile/changeUserNameAndLastName', async (payload) => {
        try {
            await httpClient.post(`/user/names`, appendFormData(payload));
        } catch (e) {
            console.log(e)
        }
    });

export const changePassword = createAsyncThunk(
    'profile/changePassword', async (payload, thunkAPI) => {
        try {
            await httpClient.post(`/user/change-password`, appendFormData(payload));
            thunkAPI.dispatch(hideModal())
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data.message)
        }
    });

export const addProfileImage = createAsyncThunk(
    'profile/addProfileImage', async (payload) => {
        try {
            await httpClient.post(`/user/user-img-upload`, appendFormData(payload))
        } catch (e) {
            console.log(e)
        }
    });
import {createSlice} from "@reduxjs/toolkit";
import {checkCouponPercent, sendCheckoutForm} from "./operations";

const initialState = {
    loader: false,
    message: {},
    sellPercent:null,
    couponNumber:null,
    errorMessage:''
};

export const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        clearPaymentState: () => ({...initialState})
    },
    extraReducers: (builder) => {
        builder.addCase(sendCheckoutForm.fulfilled, (state, {payload}) => {
            state.message = payload;
            state.loader = false;
        })
        builder.addCase(checkCouponPercent.fulfilled, (state, {payload}) => {
            state.sellPercent = payload.percent;
            state.errorMessage = payload.errorMessage;
            state.couponNumber = payload.couponNumber
        })
        builder.addCase(sendCheckoutForm.pending, (state) => {
            state.loader = true;
            state.message = {};
        })
        builder.addCase(sendCheckoutForm.rejected, (state, {payload}) => {
            state.message = payload;
            state.loader = false;
        })
    }
});

export const { clearPaymentState } = paymentSlice.actions;
export default paymentSlice.reducer;

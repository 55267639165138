import {isEmpty} from "lodash";


export const revertToYupMessageFormat = (string = '') => {
    if (isEmpty(string)) return;

    const result = string.split('_').join(' ');
    return `${result[0].toUpperCase()}${result.slice(1)}`
};

export const embedVideo = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11)

        ? `https://www.youtube-nocookie.com/embed/${match[2]}`
        : null;
};

export const appendFormData = (data) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
    });

    return formData;
};

export const filterBarDefaultValues = (data) => {
    if (isEmpty(data)) return [];

    return data.split(',').map(item => +item);
};

export const expressDateDuration = (data) => {
    let time = '';
    Object.keys(data).forEach(item => {
        if (time) return;
        if (data[item]) time = `${data[item]} ${item} ago`;
    })
    return time || '1 second ago'
};

export const customUserName = (name = 'Name', lastName = 'Lastname') => {
    return (name[0] + lastName[0]).toUpperCase();
};

export const getBasketId = (basketData, colorId, priseId, selectedShen, selectedType) => {




    return basketData.reduce((basketId, item) => {
        if (+colorId === +item?.color_id && +priseId === +item?.price_id && +selectedShen === +item?.tag_id && +selectedType === +item?.type_id) {
            basketId = item?.id;
            return basketId
        }

        if (+item?.color_id === 1 &&  +item?.tag_id === 0 && +priseId === +item?.price_id){
            if(+item?.type_id===0 || +item?.type_id === +selectedType){
                basketId = item?.id;
                return basketId
            }
        }
            return basketId;
    }, '')
};

export default function downloadPDF(blob, fileName) {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};






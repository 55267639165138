import React from 'react';
import './style.scss';

const AwardCard = ({year, title, link, isHome, yearClassName, cardClassName, img,}) => {
    return (
        <div className={`award-card ${cardClassName || ''}`}>
            <a rel="noreferrer" target='_blank' href={link}>
                <div className='yearAndTitle'>
                    <p className={`year ${yearClassName || ''}`}>{year}</p>
                    <p className='text'>{title}</p>
                </div>
            </a>
            <img className='image' src={img} alt="#"/>
        </div>
    );
};

export default AwardCard;

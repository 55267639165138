import React from 'react';
import {Empty} from "antd";
import "./style.scss";

const EmptyComponent = ({description='No Data', marginTop = 120}) => {
    return (
        <div className='empty-component'>
            <Empty description={<p>{description}</p>} style={{marginTop}} className='empty' />
        </div>
    );
};

export default EmptyComponent;
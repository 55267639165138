import {createSlice} from "@reduxjs/toolkit";
import {addProfileImage, changePassword, changeUserNameAndLastName} from "./operations";

const initialState = {
    passwordError: '',
    loader: false,
    imageUpload: false

};

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(addProfileImage.pending, (state) => {
                state.imageUpload = true
            })
            .addCase(addProfileImage.fulfilled, (state) => {
                state.imageUpload = false
            })
            .addCase(changeUserNameAndLastName.pending, (state) => {
                state.loader = true
            })
            .addCase(changeUserNameAndLastName.fulfilled, (state, action) => {
                state.loader = false

            })
            .addCase(changePassword.pending, (state) => {
                state.loader = true
            })
            .addCase(changePassword.fulfilled, (state, action) => {
                state.loader = false
            })
            .addCase(changePassword.rejected, (state, action) => {
                state.loader = false
                state.passwordError = action.payload
            })
    }
});

export default profileSlice.reducer;
import React from 'react';
import {Helmet} from "react-helmet";

const SEO = ({title, description='', type='website', image=''}) => (
    <Helmet>
        <title>{title}</title>
        <meta name='description' content={description} />
        <meta property="og:type" content={type} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:site_name" content="LED Coating Solutions" key="og-site" />
        <meta name="twitter:card" content={type} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
    </Helmet>
)

export default SEO;

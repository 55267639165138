import {useCallback, useEffect, useMemo, useState} from "react";
import backgroundImageLeft from "assets/images/elipseBackground.png";
import backgroundImageRight from "assets/images/backgroundImageRight.png";

function useContainer({withBackgroundCircleLeft, withBackgroundCircleRight}) {
    const [goUpVisible, setGoUpVisible] = useState(false);

    const setScroll = () => {
        const {scrollTop} = document.documentElement;
        if(scrollTop > 200) {
            setGoUpVisible(true)
            return
        }
        setGoUpVisible(false)
    };

    useEffect(() => {
        window.addEventListener("scroll", setScroll);
        return () => {
            window.removeEventListener("scroll", setScroll);
        };
    }, []);

    const getBackgroundImage = useMemo(() => {
        if (withBackgroundCircleLeft) return backgroundImageLeft;
        if (withBackgroundCircleRight) return backgroundImageRight;
        return null;
    }, [withBackgroundCircleLeft, withBackgroundCircleRight]);

    const goUp = useCallback(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);

    return {
        goUp,
        goUpVisible,
        getBackgroundImage,
    }
}

export default useContainer;

import React from 'react';
import "./style.scss";
function PaymentLine({title, text, role}) {
    return (
        <div className={`lineContainerPayment ${role}`}>
            <span>{title}</span>
            <p className='linePaymentP'>{text}</p>
        </div>
    );
}

export default PaymentLine;

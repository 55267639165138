import React from 'react';
import p1 from '../../../assets/images/p1.png'
import p2 from '../../../assets/images/p2.png'
import p3 from '../../../assets/images/p3.png'
import p4 from '../../../assets/images/p4.png'
import p5 from '../../../assets/images/p5.png'
import p6 from '../../../assets/images/p6.png'
import './style.scss'

const PuzzleImages = () => {
    const images = [p1, p2, p3, p4, p5, p6]
    return (
        <div className='puzzle-img'>
            {
                images.map((el, index) => <img key={index} src={el} alt=""/>)
            }
        </div>
    );
};

export default PuzzleImages;
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useElements, useStripe} from "@stripe/react-stripe-js";
import {checkCouponPercent, sendCheckoutForm} from "state/payment/operations";
import {clearPaymentState} from "state/payment";
import axios from "axios";
import {sendZipCode} from "../../../../state/zip/operations";

function useContainer({amount, shippingMode, checked, formik}) {
    const {loader, message, couponNumber} = useSelector(({payment}) => payment);
    const {sellPercent, errorMessage} = useSelector(state => state.payment)
    const [createPaymentLoader, setCreatePaymentLoader] = useState(false);


    const dispatch = useDispatch();
    const stripe = useStripe();
    const elements = useElements();
    const [coupon, setCoupon] = useState('')
    const [finalAmount, setFinalAmount] = useState(+amount)

    const [loadings, setLoadings] = useState([]);

    const enterLoading = (index) => {
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = true;
            return newLoadings;
        });
        setTimeout(() => {
            setLoadings((prevLoadings) => {
                const newLoadings = [...prevLoadings];
                newLoadings[index] = false;
                return newLoadings;
            });
        }, 1500);
    };


    const handleApplyCoupon = async () => {
        if (coupon.length) {
            enterLoading(0)
            dispatch(checkCouponPercent({coupon}))
        }
    }


    const handleSubmit = async (e) => {
        setCreatePaymentLoader(true);
        e.preventDefault();

        if(shippingMode===1 && !checked){
            formik.handleSubmit()
        }



        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement('cardNumber')
        })
        setCreatePaymentLoader(false);

        const obj = {
            amount: finalAmount,
            id: paymentMethod?.id,
        }
        if (couponNumber) {
            obj.coupon = couponNumber
        }
        if (shippingMode === 2) {
            obj.delivery = false;
            obj.billing = false
        }

        if(shippingMode===1 && checked){
            obj.billing = true
            obj.delivery = true
        }

        if(shippingMode===1 && !checked){
            obj.delivery = true;
            obj.billing = false
        }


        if (!error) {
            try {
                dispatch(sendCheckoutForm(obj))

            } catch (error) {
                console.log("Error", error)
            }
        } else {
            console.log(error.message)
        }
    }

    useEffect(() => {
        return () => {
            dispatch(clearPaymentState())
        }
    }, [])

    return {
        sendLoader: loader || createPaymentLoader,
        message,
        handleSubmit,
        setCoupon,
        handleApplyCoupon,
        loadings,
        sellPercent,
        errorMessage,


    }
}

export default useContainer;

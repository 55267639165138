import * as React from "react"

function UserSvg(props) {
    return (
        <svg
            width={42}
            height={42}
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g filter="url(#filter0_d_3138_119)">
                <path
                    d="M21 17a8.331 8.331 0 008.333-8.333A8.331 8.331 0 0021 .333a8.331 8.331 0 00-8.333 8.334A8.331 8.331 0 0021 17zm0 4.167c-5.563 0-16.667 2.791-16.667 8.333v4.167h33.334V29.5c0-5.542-11.105-8.333-16.667-8.333z"
                    fill="#76964A"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_3138_119"
                    x={0.333313}
                    y={0.333344}
                    width={41.3333}
                    height={41.3333}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy={4} />
                    <feGaussianBlur stdDeviation={2} />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.470588 0 0 0 0 0.941176 0 0 0 0.1 0" />
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_3138_119"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_3138_119"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    )
}

export default UserSvg

import {useState} from "react";
import {API_HOST} from "constants/genral";
import downloadPDF from "utils/helpers";

function useContainer() {
    const [loader, setLoader] = useState(false);

    const onDownload = async (pdfUrl, fileName= 'LED Coating Solutions') => {
       setLoader(true);
       await fetch(`${API_HOST}${pdfUrl}`)
            .then(response => response.blob())
            .then(blob => downloadPDF(blob, fileName));
       setLoader(false);
    }

    return {
        loader,
        onDownload,
    }
}

export default useContainer;

import React from "react";
import {isEmpty} from "lodash";
import Wrapper from "views/Wrapper";
import ContainerBigWrapper from "views/shared/ContainerBigWrapper";
import TopContent from "views/ProductDetails/TopContent";
import DescriptionContent from "views/ProductDetails/DescriptionContent";
import Reviews from "views/ProductDetails/Reviews";
import AlsoLike from "views/ProductDetails/AlsoLike";
import SkeletonLoader from "views/shared/Skeleton";
import SEO from "views/shared/SEO";
import {API_HOST} from "constants/genral";
import useContainer from "./hook";
import "./style.scss";

const ProductDetails = () => {
    const {productById, loader} = useContainer();

    if (loader) {
        return <SkeletonLoader withImage/>
    }

    return (
        <>
            <SEO
                description={`${productById?.title}`}
                title={`LED Coating Solutions - ${productById?.title}`}
                image={`${API_HOST}${productById?.img_path?.[0]}`}
            />
            <Wrapper>
                <ContainerBigWrapper>
                    {!isEmpty(productById) &&
                        <div className='product-details'>
                            <TopContent {...productById} />
                            <DescriptionContent description={productById.description}/>
                            {/*{!isEmpty(productById.video_link) &&*/}
                            {/*    <Videos videoLink={productById.video_link}/>*/}
                            {/*}*/}
                            {!isEmpty(productById?.product_carousel) && <AlsoLike data={productById.product_carousel} />}
                            <Reviews reviewData={productById?.reviews}/>
                        </div>
                    }
                </ContainerBigWrapper>
            </Wrapper>
        </>
    )
};

export default ProductDetails;

import {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import validationSchema from "utils/yupLocalised/scheme/review";
import {sendReview} from "state/products/operations";

function useContainer({productId}) {
    const {sendReviewsLoader} = useSelector(({products}) => products);
    const [fileList, setFileList] = useState([]);
    const dispatch = useDispatch();

    const onSubmit = (values) => {
        const images = fileList.map(item => {
            item = item.originFileObj;
            return item;
        })

        dispatch(sendReview({values: {...values, img_path: images}}));
    }

    /** Formik initialization */
    const formik = useFormik({
        initialValues: {
            stars: '',
            title: '',
            message: '',
            products_id: productId,
        },
        validationSchema,
        onSubmit,
    });

    /** handle change fileList */
    const handleFileChange = useCallback((fileList) => {
        setFileList(fileList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileList]);

    /** handle change editor */
    const onChangeEditor = (value) => {
        formik.setFieldValue('message', value);
    }

    /** rate change handler */
    const onChangeRate = (value) => {
        formik.setFieldValue('stars', value);
    }

    return {
        formik,
        onChangeRate,
        onChangeEditor,
        handleFileChange,
        fileList,
        loader: sendReviewsLoader,
    }
}

export default useContainer;
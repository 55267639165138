import * as React from "react"

function EmptyBasketSVG(props) {
    return (
        <svg
            width={180}
            height={180}
            viewBox="0 0 160 152"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M67.797 151.228c37.443 0 67.797-5.672 67.797-12.668 0-6.996-30.354-12.668-67.797-12.668S0 131.564 0 138.56c0 6.996 30.354 12.668 67.797 12.668z"
                fill="#F5F5F7"
                fillOpacity={0.8}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M122.035 101.344L98.11 71.899c-1.148-1.386-2.826-2.225-4.594-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 101.344v15.383h108.476v-15.383h-.001z"
                fill="#AEB8C2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.83 31.67h67.933a4.002 4.002 0 014 4v93.344a3.999 3.999 0 01-4 4H33.83a4 4 0 01-4-4V35.67a4 4 0 014-4z"
                fill="#F5F5F7"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M42.678 41.623h50.237a2 2 0 012 2V68.58a2 2 0 01-2 2H42.678a2 2 0 01-2-2V43.623a2 2 0 012-2zm.262 39.814h49.713a2.262 2.262 0 110 4.524H42.94a2.262 2.262 0 110-4.524zm0 11.763h49.713a2.263 2.263 0 010 4.525H42.94a2.262 2.262 0 010-4.525zm78.873 43.502c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.311 7.311 0 01-.222-1.79v-33.567h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393v-.012c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569a7.33 7.33 0 01-.221 1.789zM125.122 33.292l-6.83 2.65a1.003 1.003 0 01-1.045-.201.995.995 0 01-.272-1.029l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408 0-9.995 10.118-18.097 22.6-18.097 12.479 0 22.598 8.102 22.598 18.097 0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                fill="#DCE0E6"
            />
            <path
                d="M146.304 21.365c1.574 0 2.849-1.26 2.849-2.815s-1.275-2.815-2.849-2.815c-1.573 0-2.849 1.26-2.849 2.815s1.276 2.815 2.849 2.815z"
                fill="#fff"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M131.348 21.013h-5.698l2.898-4.926 2.8 4.926zm3.561-4.926h4.985v4.926h-4.985v-4.926z"
                fill="#fff"
            />
        </svg>
    )
}

export default EmptyBasketSVG

import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchServices} from "state/services/operations";

function useContainer() {
    const dispatch = useDispatch();
    const {services, fetchLoader} = useSelector(({services}) => services);

    const onMountHandler = () => {
        dispatch(fetchServices())
    }

    useEffect(onMountHandler, []);

    return {
        services,
        fetchLoader,
    }
}

export default useContainer;
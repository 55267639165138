import React from "react";
import {Button} from "antd";
import {UpOutlined} from "@ant-design/icons";
import Header from "./Header";
import Footer from "./Footer";
import useContainer from "./hook";
import "./style.scss";

const Wrapper = ({children, withBackgroundCircleLeft, withBackgroundCircleRight}) => {
    const {goUpVisible, goUp} =
        useContainer({withBackgroundCircleLeft, withBackgroundCircleRight});

    return (
        <div className='wrapper'>
            <Header/>
            <div className='container'>
                {children}
            </div>
            <Footer/>
            {goUpVisible &&
                <Button onClick={goUp} type='text' icon={<UpOutlined/>} className='go-up'/>
            }
        </div>
    )
}

export default Wrapper;

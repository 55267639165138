import {useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";
import {getAllBasket} from "state/basket/operations";
import {showModal} from "state/modal";
import {fetchProductCategories} from "state/products/operations";

const useMount = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.users.currentUser);

    useEffect(() => {
        if(!isEmpty(currentUser)) dispatch(getAllBasket())
    },[currentUser])

    useEffect(() => {
        dispatch(fetchProductCategories());
        if(searchParams.has('token')) {
            dispatch(showModal({
                modalType: 'FORGOT_PASSWORD_MODAL',
                modalProps: {
                    token: searchParams.get('token'),
                }
            }))
            searchParams.delete('token');
            setSearchParams(searchParams);
        }
    }, []);
};

export default useMount;

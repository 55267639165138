import React from 'react';
import {Modal, Form, Input} from "antd";
import {FormikProvider} from "formik";
import InputFiled from "views/shared/forms/InputField";
import ButtonWithSvg from "views/shared/ButtonWithSvg";
import UserSvg from "assets/svg/UserSvg";
import useContainer from "./hook";
import "./style.scss";

const ForgotPasswordModal = ({onClose, token}) => {
    const {formik, loader} = useContainer({token});

    return (
        <Modal onCancel={onClose} footer={null} open width={'630px'} className='forgot-password-modal'>
            <div className='forgot-password-main'>
                <div className='title-content'>
                    <div className='account'>
                        <UserSvg width={33} height={33}/>
                    </div>
                    <p className='title'>Forgot password</p>
                </div>
                <Form onFinish={formik.handleSubmit} className='form'>
                    <FormikProvider value={formik}>
                        <InputFiled
                            className="sign-up-input"
                            name="password"
                            formItemClassName="form-item"
                            placeholder="Password"
                            autoComplete="new-password"
                            asComponent={Input.Password}
                        />
                        <InputFiled
                            className="sign-up-input"
                            name="verify_password"
                            formItemClassName="form-item"
                            placeholder="Verify Password"
                            autoComplete="new-password"
                            asComponent={Input.Password}
                        />
                        <div className='button-content'>
                            <ButtonWithSvg loader={loader} buttonClass='button' title='Send' htmlType='submit'/>
                        </div>
                    </FormikProvider>
                </Form>
            </div>
        </Modal>
    )
}

export default ForgotPasswordModal;
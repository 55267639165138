import React from "react";
import "./style.scss";

const RenderItem = ({original}) => {
    return (
        <div className='render-item'>
            <div className='render-item-image' style={{backgroundImage: `url(${original})`}}/>
        </div>
    )
};

export default RenderItem;

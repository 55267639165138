const EDITOR = {
    toolbar: {
        items: [
            'heading',
            'bold',
            'italic',
            'link',
            // 'imageUpload',
            'fontFamily',
            'underline',
            'strikethrough',
            'fontColor',
            'fontBackgroundColor',
            'numberedList',
            'bulletedList',
            'outdent',
            'indent',
            'undo',
            'redo',
        ]
    },
}
export default EDITOR;

import React, {useMemo} from "react";
import {v4 as uuid} from "uuid";
import {API_HOST} from "constants/genral";
import "./style.scss";

const SelectableColors = ({colorData, colors, colorName, handleChange, selectedType, setColorName, setColorId}) => {



    const filteredColors = useMemo(() => {
        if(!selectedType){
            return colorData
        }
        let  result = []
        if (selectedType === 'Ultra color') {
             result = colorData.filter(el => el.name.includes('Ultra'))
        }
        if(selectedType !== 'Ultra color'){
            result = colorData.filter(el => !el.name.includes('Ultra'))
        }
      const exist = result.find(el=>el.id === colors)
        if(!exist){
            setColorName('Check a color')
            setColorId(null)
        }

        return result
    },[colorData, selectedType, setColorName, setColorId, colors])



    return (
        <div className='selectable-colors'>
            <p className='title'>Color: {colorName}</p>
            <div className='cards'>
                {filteredColors.map(item => (
                    <div key={uuid()} className={`color-card ${colors === item.id && 'active-color-card'}`}>
                        <img alt={item.name} src={`${API_HOST}/${item.img}`} className='color-image'
                             onClick={() => handleChange(item.name, item.id)}/>
                    </div>
                ))}
            </div>
        </div>
    )
};

export default SelectableColors;

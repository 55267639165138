import React from 'react';
import {Button, Popover} from "antd";
import numeral from 'numeral'
import {Link} from "react-router-dom";
import CloseSvg from "assets/svg/CloseSvg";
import {API_HOST} from "constants/genral";
import {isEmpty} from "lodash";

import useContainer from "../../../Basket/CartedCard/hook";
import './style.scss';
import Counting from "../../../Basket/Counting";

const PaymentModalCard = ({priceData, id, products_id, product, showCountPopup, countPopupHandler, ...props}) => {

    const {handleCount, productTotal, count, handleDeleteItem, popoverRef} = useContainer({
        defCount: priceData.count,
        dataTotal: priceData.dataTotal,
        price: priceData.price,
        products_id: products_id,
        id: id,
        countPopupHandler,
        ...props,
    })

    return (
        <div className='PaymentCardContent'>
            <div className='productDes'>
                <Link to={`/product-details/${products_id}`}>
                    <div className='imageContainer'>
                        <img className='productImg' src={`${API_HOST}/${product?.img}`} alt="#"/>
                    </div>

                </Link>
                <div className='productTitle'>
                    <h2 className='sub-title'>{product.title}</h2>
                    {!isEmpty(product?.color) && <h4 className='description-text'>Color: '{product?.color?.[0]?.name}'</h4>}
                    <div className='specificallyDes'>
                        <div className='specificallyDesItem'>
                            <p className='description-text'>Category: </p>
                            <p className='description-text category'>'{product?.categories[0]?.categories}'</p>

                        </div>
                        { !isEmpty(product?.tags)&& (
                            <div className='specificallyDesItem'>
                                <p className='description-text'>Sheens: </p>
                                <p className='description-text category'>'{product?.tags[0]?.categories}'</p>
                            </div>
                        )}
                        {!isEmpty(product?.price_type_data) && (
                            <div className='specificallyDesItem'>
                                <p className='description-text'>Type: </p>
                                <p className='description-text category'>'{product?.price_type_data?.type_name}'</p>
                            </div>
                        )}
                    </div>
                    <p className='mobilePrice'>${priceData.price}</p>
                </div>
            </div>
            <div className='count'>
                {/*<p>${numeral(productTotal).format('0,0')}</p>*/}
                <p>${product?.price_type_data?.type_price || priceData.price }</p>
                <Counting id={id} countPopupHandler={countPopupHandler} showCountPopup={showCountPopup} count={count}
                          handleCount={handleCount} price={priceData.price} size={'small'}/>
            </div>
            <div className='total'>
                <Popover
                    trigger="click"
                    ref={popoverRef}
                    placement="topRight"
                    overlayClassName='delete-confirm'
                    content={
                        <>
                            <p className='message'>Are you sure to delete this product?</p>
                            <div className='buttons'>
                                <Button type='text' className='yes-button' onClick={handleDeleteItem}>Yes</Button>
                                <Button type='text' className='no-button'
                                        onClick={() => popoverRef.current.close()}>No</Button>
                            </div>
                        </>
                    }
                >
                    <div className='closeBtn'><CloseSvg/></div>
                </Popover>
            </div>
        </div>
    );
};

export default PaymentModalCard;

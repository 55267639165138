import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from 'lodash'
import {Skeleton} from "antd";
import {API_HOST} from "constants/genral";
import Wrapper from "views/Wrapper";
import ContainerBigWrapper from "views/shared/ContainerBigWrapper";
import EmptyComponent from "views/shared/EmptyComponent";
import {getColors} from "state/colorLab/operations";
import "./style.scss";
import ButtonWithSvg from "../shared/ButtonWithSvg";
import {showModal} from "../../state/modal";
import ReactPlayer from "react-player";

const ColorLab = () => {
    const dispatch = useDispatch()
    const colorsData = useSelector(state => state.colorLab.colorLabData)
    const colorStatus = useSelector(state => state.colorLab.colorLabStatus)
    const [showAll, setShowAll] = useState(false)

    const handleClick = () => {
        setShowAll(!showAll)


    }

    useEffect(() => {
        dispatch(getColors())
    }, [])

    const handleShowModal = () => {
        dispatch(showModal({
            modalType: 'SERVICE_REQUEST_MODAL',
            modalProps: {serviceId: 5, customColor: true}
        }))
    }


    return (
        <Wrapper withBackgroundCircleRight>
            <ContainerBigWrapper>
                <div className='color-lab-content'>
                    <h2>Colors</h2>
                    <p className='color-lab-des'>As any artist will tell you, creating colors is an art. And that art is
                        all the more challenging when the canvas changes from simply blank white to the natural material
                        of wood. Each piece of wood has its own color, grain and depth that affects the appearance of
                        the color applied to it. The artisans at LED Coatings Solutions have extensive training in color
                        theory and decades of practical experience at applying that theory to real life when it comes to
                        wood flooring, cabinetry and more. They can use their expertise to create a bespoke color for a
                        high-end installation, match a complicated color in an existing space or create proprietary
                        colors for a wood floor finishing line.
                    </p>

                    <ReactPlayer controls={true}
                                 style={{width: '100%', height: '319px', maxWidth: 680, margin: '32px auto 90px auto'}}
                                 url="https://vimeo.com/861832337/a225971d39?share=copy"
                    />
                    <div className='small-part-desc'>
                        <h2 className='small-title'>
                            Tips for samples
                        </h2>
                        <p className='small-desc'>
                            Show a large sample: Wood is a product of nature, which is why humans are naturally drawn to
                            its beauty. Because it’s created by nature, not man, variation is inherent in wood flooring.
                            That’s why it’s so important to show samples to clients that represent the full variation of
                            the wood grain and color that will be present in the entire floor. Showing clients just a
                            small sample of one or two boards can lead to unrealistic expectations for consistency in a
                            natural wood floor.
                            Know the lighting: Color will appear drastically different based on the lighting in the
                            space. When looking at the color of the sample with the client, or when trying to match an
                            existing sample, make sure the lighting is the same temperature and CRI (color rendering
                            index) as the lighting that will be present in the space where the flooring will be
                            installed.
                            Make sure all finishes are completely cured: The color of finish changes as it cures. For
                            most finishes, this process takes weeks or months. A huge advantage of LED-cured finishes is
                            that they are instantly cured, so you can be confident about the color in a newly made
                            sample.
                        </p>
                    </div>
                    <div className='small-part-desc'>
                        <h2 className='small-title'>
                            Why using reactives to create colors is a bad idea
                        </h2>
                        <p className='small-desc'>
                            In today’s oil finish market many companies use reactives to help create colors in the wood.
                            As their name implies, “reactives” react chemically with the actual wood, usually white oak,
                            to create the color. One issue with reactives is that the colors created are very limited,
                            so manufacturers add dyes to them to broaden their color options. Dyes are notoriously
                            unstable over time, so the colors created will change over weeks, months and years.
                            Another issue with reactives is that they are extremely difficult, if not impossible, to
                            reproduce accurately at a later date. If there is a repair necessary in a wood floor five
                            years after the floor was installed, the wood flooring professional will not be able to use
                            the same process to make the new repair match the existing flooring, as the color will have
                            changed. In that situation, the only option will be to resand the entire floor or hire wood
                            floor color experts such as the ones at LED Coatings Solutions to figure out a formula to
                            match the existing flooring—an extremely difficult process.
                        </p>
                    </div>
                    <div className='buttonsContainer'>
                        {/*<div className='pdfs'>*/}
                        {/*    <a rel="noreferrer" target="_blank" href={colorPdf}>*/}
                        {/*    <span className='button'>*/}
                        {/*    <span className='technical'>Color swatches</span>*/}
                        {/*   <span><TechnicalInfoIcon fill={"#739766"}/></span>*/}
                        {/*</span>*/}
                        {/*    </a>*/}
                        {/*</div>*/}

                        <div className='pdfs' onClick={handleShowModal}>
                            <span className='button'>
                                <span className='technical'>Request a custom color</span>
                        </span>

                        </div>
                    </div>

                    {colorStatus === 'pending' && <Skeleton style={{marginTop: 50, marginLeft: 20}} active/>}
                    {
                        !isEmpty(colorsData) && colorStatus === 'ok' ? <>

                            <div className='color-items'>
                                {
                                    showAll ? colorsData.map(item =>
                                            <div key={item.id} className='color-item'>
                                                <img src={`${API_HOST}/${item.img_path}`} alt="#"/>
                                                <p className='colorName'>{item.color_name}</p>
                                            </div>) :

                                        colorsData.slice(0, 18).map(item =>
                                            <div key={item.id} className='color-item'>
                                                <img src={`${API_HOST}/${item.img_path}`} alt="#"/>
                                                <p className='colorName'>{item.color_name}</p>
                                            </div>)
                                }

                            </div>
                            <div className='showAll' onClick={handleClick}>
                                <ButtonWithSvg title={showAll ? 'Show Less' : 'Show All'}/>
                            </div>

                        </> : <EmptyComponent/>
                    }
                </div>
            </ContainerBigWrapper>
        </Wrapper>
    )
}

export default ColorLab;

import React from 'react';
import {Modal, Form, Input} from "antd";
import {FormikProvider} from "formik";
import InputFiled from "views/shared/forms/InputField";
import ButtonWithSvg from "views/shared/ButtonWithSvg";
import useContainer from "./hook";
import "./style.scss";

const ChangePassword = ({onClose}) => {
    const {formik, loader, errorMessage} = useContainer();


    return (
        <Modal onCancel={onClose} footer={null} open width={'630px'} className='change-modal'>
            <h3 className='modal-title'>Change Password</h3>
            <Form onFinish={formik.handleSubmit} className='change-modal-form'>
                <FormikProvider value={formik}>
                    <InputFiled
                        label='Current Password'
                        className="userInputs"
                        name="current_password"
                        formItemClassName="form-item"
                        placeholder="Password"
                        autoComplete="new-password"
                        asComponent={Input.Password}
                    />
                    <InputFiled
                        label='New Password'
                        className="userInputs"
                        name="new_password"
                        formItemClassName="form-item"
                        placeholder="Password"
                        autoComplete="new-password"
                        asComponent={Input.Password}
                    />
                    <InputFiled
                        label='Verify Password'
                        className="userInputs"
                        name="verify_password"
                        formItemClassName="form-item"
                        placeholder="Verify Password"
                        autoComplete="new-password"
                        asComponent={Input.Password}
                    />
                    <div className='user-button-content'>
                        <ButtonWithSvg
                            loader={loader}
                            buttonClass='save-button'
                            title='Save'
                            htmlType='submit'
                        />
                    </div>
                </FormikProvider>
            </Form>
        </Modal>
    )
}

export default ChangePassword;
import {useFormik} from "formik";
import {useSelector} from "react-redux";
import validationSchema from "utils/yupLocalised/scheme/changeUserName";

function useContainer() {
    const user = useSelector(state => state.users.currentUser)
    const imageUpload = useSelector(state => state.profile.imageUpload)
    const saveLoader = useSelector(state => state.profile.loader)
    const createImageUrl = (originFileObj) => {
        const blob = new Blob([originFileObj], {type: "image/jpeg"});
        return URL.createObjectURL(blob);
    };

    const onSubmit = async (values) => {
    }

    /** Formik initialization */
    const formik = useFormik({
        initialValues: {
            first_name: user.first_name,
            last_name: user.last_name,
        },
        validationSchema,
        onSubmit,
    });

    return {
        formik,
        imageUpload,
        createImageUrl,
        saveLoader,
        user

    }
}

export default useContainer;
import React from "react";
import "./style.scss";

const RenderThumbInner = ({thumbnail}) => (
    <div className='render-thumb-inner'>
        <img alt='...' src={thumbnail} />
    </div>
)

export default RenderThumbInner;

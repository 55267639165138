import {useFormik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import validationSchema from "utils/yupLocalised/scheme/changePassword";
import {changePassword} from "state/profile/operations";
import {useEffect} from "react";

function useContainer() {
    const loader = useSelector(state => state.profile.loader)
    const errorMessage = useSelector(state => state.profile.passwordError)
    const user = useSelector(state => state.users.currentUser)
    const dispatch = useDispatch()

    useEffect(() => {
        formik.setFieldError('verify_password', errorMessage)
    }, [errorMessage])

    const onSubmit = async (values) => {
        if (values.verify_password !== values.new_password) {
            formik.setErrors({verify_password: 'Verified password is incorrect'})
            return;
        }
        delete values.verify_password;
        const changePasswordData = {
            old_password: values.current_password,
            password: values.new_password
        }
        dispatch(changePassword((changePasswordData)))

    }

    /** Formik initialization */
    const formik = useFormik({
        initialValues: {
            current_password: '',
            new_password: '',
            verify_password: '',
        },
        validationSchema,
        onSubmit,
    });

    return {
        formik,
        loader,
        errorMessage
    }
}

export default useContainer;
import yup from 'utils/yupLocalised';

const validationSchema = yup.object().shape({
    region:yup.string(),
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    address_first: yup.string().required(),
    address_last: yup.string(),
    city:yup.string().required(),
    zip_code:yup.string().required(),
    phone:yup.string().required()
});

export default validationSchema;

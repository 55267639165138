import * as React from "react"

function VectorSvg(props) {
    return (
        <svg
            viewBox="0 0 9 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M8 1L4.5 4.5 1 1"
                stroke="#474747"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default VectorSvg

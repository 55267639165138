import React, {useEffect, useMemo, useState} from 'react';
import {Popover, Input} from 'antd';
import {isEmpty, uniqueId} from "lodash";
import ArrowDown from "../../../../assets/svg/ArrowDown";
import ArrowUp from "../../../../assets/svg/ArrowUp";


function InputColor({colorsArray,selectedType, setColorId, setColorName, text, setText, colors}) {


    const newFilteredColors = useMemo(() => {
        if(!selectedType){
            return colorsArray
        }
        let  result = []
        if (selectedType === 'Ultra color') {
            result = colorsArray.filter(el => el.name.includes('Ultra'))
        }
        if(selectedType !== 'Ultra color'){
            result = colorsArray.filter(el => !el.name.includes('Ultra'))
        }
        const exist = result.find(el=>el.id === colors)
        if(!exist){
            setColorName('Check a color')
            setColorId(null)
        }

        return result
    },[colorsArray, selectedType, setColorName, setColorId, colors])




    const [filteredColors, setFilteredColors] = useState([])
    const [allowShowPopover, setAllowShowPopover] = useState(false)
    const [show, setShow] = useState(false)

    const handleChangeText = ({target: {value}}) => {
        setText(value)
        setAllowShowPopover(true)
    }

    const handleInputClick = () => {
        if(!text){
            setShow(!show)
        }

    }

    const handleClick = (item) => {
        setFilteredColors([])
        setAllowShowPopover(false)
        setShow(false)
        setText(item.name)
        setColorId(item.id);
        setColorName(item.name)
    }


    const handleContent = useMemo(() => {
        return (
            <div className='inputColorContainer'>
                {
                    !isEmpty(newFilteredColors) && newFilteredColors.map((item) => {
                        return <p key={uniqueId()} className='filteredColorItem'
                                  onClick={() => handleClick(item)}>{item.name}</p>
                    })
                }
            </div>
        )
    }, [filteredColors])


    useEffect(() => {
        if (!text) {
             setFilteredColors([])
            setShow(false)
            return
        }
        const filteredArray = colorsArray.filter((item) => item.name.toLowerCase().includes(text.toLowerCase()))
        setFilteredColors(filteredArray)

    }, [text])

    useEffect(()=>{
        if(show && !text){
            setFilteredColors(colorsArray)
        }
        if(!show){
            setFilteredColors([])
        }

    },[show])


    return (
        <Popover placement="bottom" content={handleContent} open={filteredColors.length > 0 && allowShowPopover || show} onOpenChange={(ev)=>setShow(ev)} trigger={'click'}>
            <div className='inputSVGContainer' onClick={handleInputClick}>
                <input placeholder='Search a color' onChange={handleChangeText} value={text} className='textInput'  />
                {
                   !text ? show ?  <ArrowUp/> :   <ArrowDown/>:null
                }
            </div>

        </Popover>
    );
}

export default InputColor;

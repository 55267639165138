import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import EDITOR from "constants/editor";
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/es';
import "./style.scss";

const CKEditorTextArea = ({value, onChangeEditor}) => {
    return (
        <div className='ckeditor-textarea'>
            <div className="document-editor__toolbar"></div>
            <div className="document-editor__editable-container">
            <CKEditor
                editor={ DecoupledEditor }
                data={value}
                onReady={ editor => {
                    editor.ui.getEditableElement().parentElement.insertBefore(
                        editor.ui.view.toolbar.element,
                        editor.ui.getEditableElement()
                    );
                } }
                config={{
                    toolbar: EDITOR.toolbar,
                    // plugins: [ FontColor ],
                }}
                onChange={ ( event, editor ) => {
                    const data = editor.getData();
                    onChangeEditor(data);
                } }
            />
            </div>
        </div>
    )
}

export default CKEditorTextArea;
import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {LoadingOutlined} from "@ant-design/icons";
import {Form, Spin} from "antd";
import {FormikProvider} from "formik";
import {API_HOST} from "constants/genral";
import {customUserName} from "utils/helpers";
import Wrapper from "views/Wrapper";
import ContainerBigWrapper from "views/shared/ContainerBigWrapper";
import UserPhotoSvg from "assets/svg/UserPhotoSvg";
import InputFiled from "views/shared/forms/InputField";
import {showModal} from "state/modal";
import ButtonWithSvg from "views/shared/ButtonWithSvg";
import {addProfileImage, changeUserNameAndLastName} from "state/profile/operations";
import {getUser} from "state/users/operations";
import useContainer from "./hook";
import './style.scss'

const Profile = () => {
    const {formik, user, imageUpload, saveLoader} = useContainer();
    const [img, setImg] = useState()
    const dispatch = useDispatch()
    const handleImg = async (imgUrl) => {
        if (imgUrl) {
            await dispatch(addProfileImage({user_id: user.id, img_path: imgUrl}))
            dispatch(getUser())
        }
    }

    useEffect(() => {
        setImg(user.img_path)
    }, [user])
    const handleSave = async () => {
        const changedData = {
            first_name: formik.values.first_name,
            last_name: formik.values.last_name
        }
        await dispatch(changeUserNameAndLastName(changedData))
        dispatch(getUser())

    }
    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 30,
                color: 'white'
            }}
            spin
        />
    );
    return (
        <Wrapper>
            <div className='user'>
                <div className='userImgContent'>
                    <div className='userImg'>
                        <div className='user-image'>
                            {
                                img ?
                                    <div>
                                        {
                                            imageUpload ? <Spin indicator={antIcon}/> :
                                                <img src={`${API_HOST}/${user.img_path}`} alt="#"/>
                                        }
                                    </div>
                                    :
                                    <span
                                        className='userShortName'>{customUserName(user.first_name, user.last_name)}</span>
                            }
                        </div>
                        <label id='inputTag' className='userPhoto'>
                            <UserPhotoSvg/>
                            <input
                                onChange={(e) => handleImg(e.target.files[0])}
                                id='inputTag'
                                type="file"/>
                        </label>
                    </div>
                    <p className='userName'>{user.first_name} {user.last_name}</p>
                </div>

            </div>
            <ContainerBigWrapper>
                <Form onFinish={formik.handleSubmit} className='user-form'>
                    <FormikProvider value={formik}>
                        <InputFiled
                            label='First Name'
                            className="userInputs"
                            name="first_name"
                            placeholder="First Name"
                        />
                        <InputFiled
                            label='Last Name'
                            className="userInputs"
                            name="last_name"
                            placeholder="Last Name"
                        />
                        <button
                            type='button'
                            className='changePassword'
                            onClick={() => dispatch(showModal({modalType: "CHANGE_PASSWORD"}))}
                        >
                            Change Password
                        </button>
                        <div className='save-btn'>
                            <div onClick={handleSave}>
                                <ButtonWithSvg loader={saveLoader} title='Save'/>
                            </div>
                        </div>
                    </FormikProvider>
                </Form>

            </ContainerBigWrapper>
        </Wrapper>
    );
};

export default Profile;

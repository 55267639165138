import React from 'react';
import OwlCarousel from "react-owl-carousel";
import AwardCard from "views/shared/AwardCard";
import "./style.scss";
import {data} from "../../../constants/data";



const Awards = () => {
    return (
        <div className='home-awards'>
            <div className='title-content'>
                <h2 className='title content-title'>Our awards</h2>
            </div>
            <div className='content'>
                <OwlCarousel className="owl-theme" margin={13} dots={false} responsiveClass lazyLoad nav
                             responsive={{
                                 0: {items: 0.8},
                                 400: {items: 1.3},
                                 600: {items: 2},
                                 685: {items: 2},
                                 820: {items: 2},
                                 1050: {items: 4},
                                 1285: {items: 4},
                                 1440: {items: 4},
                             }}
                >
                    {data.map((item, index) => (
                        <div className="slide-card" key={index}>
                            <AwardCard {...item} isHome={true} yearClassName='yearClass' cardClassName='awardCard'/>
                        </div>
                    ))}
                </OwlCarousel>
            </div>
        </div>
    );
};

export default Awards;

import React from 'react';
import {Modal, Form, Input} from "antd";
import {FormikProvider} from "formik";
import InputFiled from "views/shared/forms/InputField";
import ButtonWithSvg from "views/shared/ButtonWithSvg";
import UserSvg from "assets/svg/UserSvg";
import useContainer from "./hook";
import "./style.scss";

const SignUpModal = ({onClose}) => {
    const {formik, loader} = useContainer();

    return (
        <Modal onCancel={onClose} footer={null} open width={'630px'} className='sign-ul-modal'>
            <div className='sign-in-modal-main'>
                <div className='title-content'>
                    <div className='account'>
                        <UserSvg width={33} height={33} fill={'#68885D'} />
                    </div>
                    <p className='title'>Sign Up</p>
                </div>
                <Form onFinish={formik.handleSubmit} className='form'>
                    <FormikProvider value={formik}>
                        <InputFiled
                            className="sign-up-input"
                            name="first_name"
                            formItemClassName="form-item"
                            placeholder="First Name"
                        />
                        <InputFiled
                            className="sign-up-input"
                            name="last_name"
                            formItemClassName="form-item"
                            placeholder="Last Name"
                        />
                        <InputFiled
                            className="sign-up-input"
                            name="email"
                            formItemClassName="form-item"
                            placeholder="Email"
                        />
                        <InputFiled
                            className="sign-up-input"
                            name="password"
                            formItemClassName="form-item"
                            placeholder="Password"
                            autoComplete="new-password"
                            asComponent={Input.Password}
                        />
                        <InputFiled
                            className="sign-up-input"
                            name="verify_password"
                            formItemClassName="form-item"
                            placeholder="Confirm password"
                            autoComplete="new-password"
                            asComponent={Input.Password}
                        />

                        <div className='button-content'>
                            <ButtonWithSvg loader={loader} buttonClass='button' title='Sign Up' htmlType='submit'/>
                        </div>
                    </FormikProvider>
                </Form>
            </div>
        </Modal>
    )
}

export default SignUpModal;

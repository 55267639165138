import {useFormik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import validationSchema from "utils/yupLocalised/scheme/signUp";
import {userSignUp} from "state/users/operations";


function useContainer() {
    const dispatch = useDispatch();
    const { loader } = useSelector(({users}) => users);


    const onSubmit = async (values) => {
        if (values.verify_password !== values.password) {
            formik.setErrors({verify_password: 'Password and Confirm Password does not match'})
            return;
        }
        delete values.verify_password;
        const data = {values: values, rememberMe:false}
        dispatch(userSignUp({data, callback: (message) => {
            formik.setErrors({email: message});
        }}));
    }

    /** Formik initialization */
    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            verify_password: '',
        },
        validationSchema,
        onSubmit,
    });


    return {
        formik,
        loader,

    }
}

export default useContainer;

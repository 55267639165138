import React from "react";
import {isEmpty} from "lodash";
import Wrapper from "views/Wrapper";
import ContainerBigWrapper from "views/shared/ContainerBigWrapper";
import SkeletonLoader from "views/shared/Skeleton";
import ServiceCard from "views/Services/ServiceCard";
import SEO from "views/shared/SEO";
import useContainer from "./hook";
import "./style.scss";

const Services = () => {
    const {fetchLoader, services} = useContainer();

    if (fetchLoader) {
        return <SkeletonLoader/>
    }

    return (
        <>
            <SEO title='LED Coating Solutions - Services'/>
            <Wrapper>
                <ContainerBigWrapper>
                    <div className='services-page'>
                        <div className="cards">
                            {!isEmpty(services) && services?.models?.map(item => <ServiceCard
                                key={item.id} {...item} />)}
                        </div>
                    </div>
                </ContainerBigWrapper>
            </Wrapper>
        </>
    )
}

export default Services;

import React from 'react';
import CalendarIcon from "assets/svg/Calendar";
import "./style.scss"

const Constarction = () => {
    return (
        <div className='comming-son'>
            <p className='title'>WEBSITE COMING SOON</p>
            <p className="description">
                We will be available shortly
            </p>
            <div className="icon">
                <CalendarIcon width={100} height={100} fill={'#FFF'}/>
            </div>
        </div>
    );
};

export default Constarction;

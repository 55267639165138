import {createAsyncThunk} from "@reduxjs/toolkit";
import httpClient from "state/httpClient";
import {hideModal} from "state/modal";
import {appendFormData} from "utils/helpers";

export const fetchProductById = createAsyncThunk(
    'products/fetchProductById', async (payload) => {
        try {
            const {data} = await httpClient.get(`/products/view`, {params: payload});
            return data.data;
        } catch (e) {
            //
        }
    });

export const fetchProductCategories = createAsyncThunk(
    'products/fetchProductCategories', async () => {
        try {
            const {data} = await httpClient.get(`/default`);
            return data;
        } catch (e) {
            //
        }
    });

export const fetchProducts = createAsyncThunk(
    'products/fetchProducts', async (payload) => {
        try {
            const {data} = await httpClient.get(`/products`, {params: payload});
            return data.data;
        } catch (e) {
            //
        }
    });

export const sendReview = createAsyncThunk(
    'products/sendReview', async (payload, {dispatch}) => {
        const formData = new FormData();

        Object.entries(payload.values).forEach(([key, value]) => {
            if(key === 'img_path') {
                value.forEach(item => formData.append(`img_path[]`, item));
            } else {
                formData.append(key, value);
            }
        });

        try {
            const {data} = await httpClient.post(`/reviews/create`, formData);
            dispatch(hideModal());
            return data.reviews;
        } catch (e) {
            //
        }
    });

export const addToCard = createAsyncThunk(
    'products/addToCard', async (payload) => {
        const body = {...payload};
        if(!body.color_id) {
            body.color_id = 1;
        }
        try {
            const {data} = await httpClient.post(`/basket/data`, appendFormData(body));
            return data.basket;
        } catch {
            //
        }
    });

export const removeFromCard = createAsyncThunk(
    'products/removeFromCard', async (payload) => {
        try {
            await httpClient.post(`/basket/delete`, appendFormData(payload));
            return payload
        } catch {
            //
        }
    });

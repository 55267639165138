import {useDispatch, useSelector} from "react-redux";
import {showModal} from "state/modal";
import {isEmpty} from "lodash";
import Account from "utils/account";
import {userLogAuth} from "state/users/operations";
import {getAllBasket} from "../../../../state/basket/operations";

function useContainer() {
    const dispatch = useDispatch();
    const {basketData} = useSelector(({basket}) => basket)
    const {currentUser} = useSelector(({users}) => users);

    const handleLogAuth = (e) => {
        e.stopPropagation();
        dispatch(userLogAuth());

    }

    const opeSignInModal = () => {
        dispatch(showModal({
            modalType: 'SIGN_IN_MODAL',
        }))
    }

    const opeSignUpModal = () => {
        dispatch(showModal({
            modalType: 'SIGN_UP_MODAL',
        }))
    }

    return {
        authorized: !isEmpty(currentUser) && Account.getAccessToken(),
        opeSignInModal,
        opeSignUpModal,
        handleLogAuth,
        basketData,
        currentUser,
    }
}

export default useContainer;

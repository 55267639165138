import {createSlice} from "@reduxjs/toolkit";
import {sendZipCode} from "./operations";

const initialState = {
    zipCode: '',
    errorMessage: '',
    TaxRate:'',
    deliveryTotal:null
};

export const zipSlice = createSlice({
    name: 'zip',
    initialState,
    reducers: {
        clearZipState: () => ({...initialState})
    },
    extraReducers: (builder) => {
        builder.addCase(sendZipCode.fulfilled, (state, {payload}) => {
            state.errorMessage = payload.errorMessage;
            state.zipCode = payload.zip
            state.tax = payload.tax
            state.deliveryTotal = payload.deliveryTotal
        })
    }
});

export const { clearZipState } = zipSlice.actions;
export default zipSlice.reducer;

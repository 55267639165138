import React, {useState} from 'react';
import {Form, Modal} from 'antd';
import "./style.scss";
import RadioButtonSVG from "../../../assets/svg/RadioButtonSVG";
import AddressForm from "../../AddressForm";
import ButtonWithSvg from "../../shared/ButtonWithSvg";
import {showModal} from "../../../state/modal";
import useContainer from "../../AddressForm/hook";
import {FormikProvider} from "formik";
import {useSelector} from "react-redux";

const PaymentAddressModal = ({onClose}) => {
    const [shippingMode, setShippingMode] = useState(1);
    const {formik} = useContainer({shippingMode});


    const handleChangeRadio = (value) => {
        setShippingMode(value);
    };

    return (
        <Modal onCancel={onClose} footer={null} open width={'830px'} className='PaymentAddressModal'>
            <div className='mainContainerModal'>
                <h2 className='title'>Payment</h2>
                <div className='radioButtonContainer' style={{marginTop: 60}} onClick={() => handleChangeRadio(1)}>
                    <RadioButtonSVG type={shippingMode === 1}/>
                    <span>Use shipping method</span>
                </div>
                <div className='radioButtonContainer' style={{marginTop: 10}} onClick={() => handleChangeRadio(2)}>
                    <RadioButtonSVG type={shippingMode === 2}/>
                    <span>Take by myself</span>
                </div>
                <p>{shippingMode === 1 ? 'Shipping address' : "Billing address"}</p>
                <Form onFinish={formik.handleSubmit}>
                    <FormikProvider value={formik}>
                        <AddressForm/>

                        <div className='buttonContainer'>
                            <ButtonWithSvg title='Next' buttonClass='customStyle' htmlType={'submit'}/>
                        </div>
                    </FormikProvider>
                </Form>
            </div>
        </Modal>
    )
}

export default PaymentAddressModal;

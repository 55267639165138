import * as React from "react"

function Facebook(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_833_959)">
                <path
                    d="M24 12c0-6.628-5.372-12-12-12S0 5.372 0 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078V12h3.047V9.356c0-3.007 1.791-4.668 4.533-4.668 1.312 0 2.685.234 2.685.234v2.953h-1.512c-1.491 0-1.956.925-1.956 1.875V12h3.328l-.532 3.469h-2.796v8.385C19.612 22.954 24 17.989 24 12z"
                    fill="#636270"
                />
            </g>
            <defs>
                <clipPath id="clip0_833_959">
                    <path fill="#fff" d="M0 0H24V24H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Facebook

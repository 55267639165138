import React from 'react';
import Wrapper from "../Wrapper";
import './style.scss'
import ContainerWrapper from "../ContainerWrapper";
import img1 from '../../assets/images/avetis.png'
import img2 from '../../assets/images/vardan.png'

const About = () => {
    return (
        <Wrapper>
            <ContainerWrapper>
                <div className='about-content'>
                    <h1 className='about-content-title'>
                        Quick Version about Avedis and Vartan
                    </h1>
                    <p className='about-content-desc'>
                        LED Coating Solutions is the culmination of the life’s work of business partners and best
                        friends
                        Avedis Duvenjian and Vartan Arutyunian. Avedis, with a background in craftsmanship, and Vartan,
                        with
                        a background as a fine artist, have combined their talents for decades to create some of the
                        most
                        lauded wood floors in the world with their custom wood flooring company, Archetypal Imagery.
                        Their
                        passion and creativity for wood flooring has earned them a stunning <a href='/awards'>
                        10 Wood Floor of the Year
                        Awards.
                    </a> With LED Coating Solutions, they are now sharing their cutting-edge products and their wood
                        finishing expertise with the entire wood finishing market.
                    </p>
                    <div className='avetis-about'>
                        <img src={img1} alt="#"/>
                        <div className='avetis-about-content'>
                            <h2 className='avetis-about-title'>About Avedis</h2>
                            <p>
                                Avedis Duvenjian’s story begins in his native Armenia in the generations before him. The
                                Armenian people had a strong tradition of craftsmanship that helped them persevere
                                through centuries, including the genocide of the Armenian people during the early 20th
                                century. Avedis’s grandfather was skilled in restoring both the inner workings and the
                                ornate wood exteriors of clocks.
                                When Avedis was 14, after waiting for many years, his family had the opportunity to
                                leave Armenia (then part of the Soviet Union) and come to the United States. When it was
                                time to consider a future career, Avedis felt the pressure to choose a path in medicine,
                                but his cousin encouraged him to find a calling that was in his heart, not something he
                                would pursue because of prestige or money.

                            </p>
                        </div>
                    </div>
                    <p className='avetis-about-desc'>
                        He stopped his studies and began working with his friend Arthur, who had a company doing
                        beautiful high-end work in house interiors crafting cabinets, paneling, moldings and more. As he
                        worked with the wood and began creating the colors, he felt connected to the memories of his
                        grandfather: the smell of the shellac, the depth of the wood when it was coated, the aroma of
                        his cigar as he worked. He realized his life’s passion would be working with wood, just as his
                        grandfather had done in Armenia.

                        When he did his first wood floor, he was intrigued, because it was the most challenging
                        situation yet: With a wood floor, the finish reveals the wood grain, and the fibers of the wood
                        continually change because of the people walking on them. Avedis pursued his passion further by
                        attending wood flooring training, and he was particularly drawn to the work of legendary wood
                        floor master Birger Juell, renowned for his classic European parquets, and the oil finishes that
                        were being used in Europe. He traveled to Europe and studied the finishes and spent time with
                        the floors in the historic castles and palaces. As he broadened his skills and perspective, he
                        also developed a reputation in the design world for crafting gorgeous parquets that magically
                        appeared to be antique, and he became sought-after for high-end wood flooring projects around
                        the globe.

                        Since then, Avedis’s reputation throughout the world has only grown, and his obsession with
                        color theory and how color and finishes interplay with wood has become his life’s work, and he
                        and partner Vartan Arutyunian have continued to further their knowledge about color through
                        specialized schools in color, faux painting and faux graining. The culmination of their years of
                        both work and in-depth study is the launch of LED Coating Solutions.

                    </p>
                    <div className='avetis-about'>
                        <img src={img2} alt="#"/>
                        <div className='avetis-about-content'>
                            <h2 className='avetis-about-title'>About Vartan</h2>
                            <p>
                                While growing up in Armenia, Vartan Arutyunian always loved art, but his first formal
                                education in art began while he was earning his degree in architectural engineering.
                                After earning his diploma, he was selected to an invite-only additional three years of
                                art education. During that time, he honed his skills and interest in photo-realistic art
                                and contemporary art. A professor who was working with Vartan suggested that the best
                                place in the world for him would be the United States, which was the epicenter for his
                                particular interests in the art world.

                                In 1991 Vartan traveled to New York City to experience the art scene first-hand and
                                immerse himself in that world, but he had no intention of staying long-term.

                            </p>
                        </div>
                    </div>
                    <p className='avetis-about-desc'>
                        That all changed when, after he arrived, the economic conditions in Armenia became dire
                        following the breakup of the Soviet Union. Because of his extraordinary artistic talent, he was
                        able to secure a talent visa to get his green card and live in the United States. As he was
                        finding success selling his paintings to collectors in New York City, he became friends with
                        Avedis and agreed to help Avedis with the artistic aspects of his growing wood flooring company.
                        Their partnership and friendship became so strong that when Vartan was presented with a
                        once-in-a-lifetime offer to partner with a world-famous European designer, he chose to pursue
                        his art via Archetypal instead.

                        Today, Vartan continues to bring his extraordinary artistic insights into color and design to
                        Archetypal, manifesting his passion for mixed media art and texture via the living medium of
                        wood, whether that be creating a custom color for a multi-million-dollar project or creating a
                        unique mosaic installation unlike any envisioned before in the world.
                    </p>
                </div>
            </ContainerWrapper>
        </Wrapper>
    );
};

export default About;

import React from "react";
// import {FilterOutlined} from "@ant-design/icons";
import Wrapper from "views/Wrapper";
import ContainerBigWrapper from "views/shared/ContainerBigWrapper";
// import FilterBar from "views/Products/FilterBar";
import MainContent from "views/Products/MainContent";
import SEO from "views/shared/SEO";
import useContainer from "./hook";
import "./style.scss";

const Products = () => {
    const {changeFilterVisible, filtersVisible, onChange, params, products, loader} = useContainer();

    return (
        <>
            <SEO title='LED Coating Solutions - Products'/>
            <Wrapper>
                <ContainerBigWrapper>
                    <div className='products'>
                        {/*<div className='left-filter-bar-content'>*/}
                        {/*    <FilterBar onChange={onChange} /></div>*/}
                        {/*<div className="mobile-filter-content">*/}
                        {/*    {!filtersVisible && <h2 className="title">Products</h2>}*/}
                        {/*    {filtersVisible ? <>*/}
                        {/*        <div className="mobile-filter-wrapper" onClick={changeFilterVisible} />*/}
                        {/*        <div className="mobile-filter-popup">*/}
                        {/*            <FilterBar onChange={onChange} changeFilterVisible={changeFilterVisible} />*/}
                        {/*        </div>*/}
                        {/*    </> : <button className="filter-button" onClick={changeFilterVisible}>*/}
                        {/*        <FilterOutlined/>*/}
                        {/*        Filters*/}
                        {/*    </button>}*/}
                        {/*</div>*/}
                        <MainContent page={params.page} onChange={onChange} products={products} loader={loader} />
                    </div>
                </ContainerBigWrapper>
            </Wrapper>
        </>
    )
}

export default Products;

import React from "react";
import Explanation from "views/shared/Explanation";
import PuzzleImages from "views/Home/PuzzleImages";
import textImage from "assets/images/textBackground.png"
import "./style.scss";

const data = {
    title: 'Color lab',
    description: 'As any artist will tell you, creating colors is an art. And that art is all the more challenging when the canvas changes from simply blank white to the natural material of wood. Each piece of wood has its own color, grain and depth that affects the appearance of the color applied to it. The artisans at LED Coatings Solutions have extensive training in color theory and decades of practical experience at applying that theory to real life when it comes to wood flooring, cabinetry and more. They can use their expertise to create a bespoke color for a high-end installation, match a complicated color in an existing space or create proprietary colors for a wood floor finishing line.',
}

const ColorLab = () => (
    <div className='color-lab'>
        <div className='description-title-content'>
            <h2 className='content-title'>Color lab</h2>
        </div>
        <div className='left-content'>
            <Explanation redirectRoute='/color-lab' title='Color lab' withButton buttonText='See all' {...data}/>
        </div>
        <div className='right-content'>
            <div className="bottomDiv">
                <img className='leftTextImage' src={textImage} alt='...'/>
            </div>
            <PuzzleImages/>
        </div>
    </div>
);

export default ColorLab;

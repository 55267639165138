import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import numeral from 'numeral'
import {isEmpty} from 'lodash'
import {Checkbox} from "antd";
import Wrapper from "views/Wrapper";
import ButtonWithSvg from "views/shared/ButtonWithSvg";
import ContainerBigWrapper from "views/shared/ContainerBigWrapper";
import SkeletonLoader from "views/shared/Skeleton";
import CartedCard from "./CartedCard";
import useContainer from "./hook";
import './style.scss';
import EmptyBasketSVG from "../../assets/svg/EmptyBasketSVG";
import {useDispatch, useSelector} from "react-redux";
import Account from "../../utils/account";

const Basket = () => {
    const dispatch = useDispatch();

    const {
        isFetchingBasket,
        opePaymentModal,
        basketData,
        totalPrice,
        countPopupHandler,
        showCountPopup,
        setShowCountPopup,
        checkPolicy,
        checkedPolicy
    } = useContainer();





    if (isFetchingBasket) return <SkeletonLoader/>;

    return (
        <div onClick={() => setShowCountPopup('')}>
            <Wrapper>
                <ContainerBigWrapper>
                    <div className='basketContent'>
                        <h2>Shopping Cart</h2>
                        <h6>{basketData?.data?.length || 0} Items in Cart</h6>
                    </div>
                    {!isEmpty(basketData.data) ? <>
                            <div className='basketProductsTitle'>
                                <ul className='ul'>
                                    <li className='li'>Products</li>
                                    <li className='li'>Price</li>
                                    <li className='li'>Quantity</li>
                                    <li className='li'>Total</li>
                                </ul>
                                {!isEmpty(basketData.data) &&
                                    basketData.data.map(e =>
                                        <CartedCard
                                            countPopupHandler={countPopupHandler}
                                            showCountPopup={showCountPopup}
                                            key={e.id}
                                            {...e}
                                        />

                                    )}
                            </div>
                            <div className='allProductTotalPrice'>
                                <div className='allProductTotalPriceContainer'>
                                    <p>Total: <span>${numeral(totalPrice).format('0,0') || 0}</span></p>
                                </div>
                            </div>
                            <div className='basketCheckout'>

                                <div className='checkBox'>
                                    <Checkbox onChange={checkPolicy}/>
                                    <span style={{marginLeft:10}}>I agree to the</span>
                                    <Link to='/policy'>
                                        <span className='agree'>terms</span>
                                    </Link>
                                </div>
                                <ButtonWithSvg disabled={!checkedPolicy} title='Checkout' onClick={opePaymentModal}/>
                            </div>
                        </>
                        :
                        <div className='basketEmpty'>
                            <div style={{paddingLeft:20}}>     <EmptyBasketSVG/></div>

                            <h5>There are no items in your cart.</h5>
                            <Link to='/products' className='goShop'>Go To Shop</Link>
                        </div>
                    }
                </ContainerBigWrapper>
            </Wrapper>
        </div>
    );
};
export default Basket;

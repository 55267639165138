import React, {useMemo} from 'react';
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {showModal} from "state/modal";
import {API_HOST} from "constants/genral";
import defaultServiceIcon from 'assets/images/serviceDefaultIcon.png';
import "./style.scss";

const ServiceCard = ({title, short_description, icon, id}) => {
    const dispatch = useDispatch();
    const circleIcon = useMemo(() => {
        if(icon === 'uploads/img/services/default.jpg') return defaultServiceIcon;
        return `${API_HOST}/${icon}`
    }, [])

    const handleShowModal = () => {
        dispatch(showModal({
            modalType: 'SERVICE_REQUEST_MODAL',
            modalProps: {serviceId: id}
        }))
    }

    return (
        <div className='service-page-card'>
            <div className='circle-icon' style={{backgroundImage: `url(${circleIcon})`}} />
            <div className="card-main-content">
                <p className="service-name">{title}</p>
                <p className="service-description">{short_description}</p>
            </div>
            <div className="button-content">
                <button className='button-class' onClick={handleShowModal}>Send Request</button>
                <Link to={`/service-details/${id}`} className='view-more'>View more</Link>
            </div>
        </div>
    );
};

export default ServiceCard;

import React from "react";
import {Link} from "react-router-dom";
import {isEmpty} from "lodash";
import {useSelector} from "react-redux";
import LEDIcon from "assets/svg/LEDIcon";
import "./style.scss";
import Facebook from "../../../assets/svg/socialIcons/Facebook";
import Twitter from "../../../assets/svg/socialIcons/Twitter";
import Instagram from "../../../assets/svg/socialIcons/Instagram";
import Pinterest from "../../../assets/svg/socialIcons/Pinterest";
import Youtube from "../../../assets/svg/socialIcons/Youtube";

const Footer = () => {
    const {productCategories} = useSelector(({products}) => products);

    return (
        <div className='footer-content'>
            <div className='footer'>
                <div className='top'>
                    <div className='content'>
                        <div className='nth-1'>
                            <Link className='logo' to='/'>
                                <LEDIcon width={150} height={70}/>
                            </Link>
                        </div>
                        <div className='menu-content'>
                            <div className='nth-2'>
                                {productCategories?.categories?.map((item, index) => (
                                    <Link className={index === 0 ? 'item first' : 'item'} key={item.label + item.value}
                                          to={{
                                              pathname: '/products',
                                              search: `?categories=${item.value}`,
                                          }}>
                                        {item.label}
                                    </Link>
                                ))}
                            </div>
                            {!isEmpty(productCategories?.categories) && (
                                <div className='nth-3'>
                                    <Link to='/services' className='item'>Services</Link>
                                    <Link to='/how-to-use' className='item first'>How to use</Link>
                                    <Link to='/awards' className='item first'>Awards</Link>
                                    <Link to='/color-lab' className='item first'>Colors</Link>
                                </div>
                            )}
                            {!isEmpty(productCategories?.categories) && (
                                <div className='nth-4'>
                                    <Link to='/technical-data-sheets' className='item'>Technical Data</Link>
                                    <Link to='/policy' className='item first'>Privacy Policy</Link>
                                    <Link to='/faq' className='item first'>FAQ</Link>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom-content">
                <div className='bottom'>
                    <div className='content'>
                        <p className='text'>2022 - Designed & Develop by <span>Brain Fors</span></p>
                    </div>
                    <div className='social-content'>
                        <a className='link' href={'#'} target="_blank" rel="noreferrer"><Facebook/></a>
                        <a className='link' href={'#'} target="_blank" rel="noreferrer"><Twitter/></a>
                        <a className='link' href={'#'} target="_blank" rel="noreferrer"><Instagram/></a>
                        <a className='link' href={'#'} target="_blank" rel="noreferrer"><Pinterest/></a>
                        <a className='link' href={'#'} target="_blank" rel="noreferrer"><Youtube/></a>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Footer;

import React from 'react';
import Wrapper from "views/Wrapper";
import ContainerWrapper from "views/ContainerWrapper";
import AwardCard from "views/shared/AwardCard";
import ArdSmallIcon from "assets/svg/ArdSmallIcon";
import './style.scss';
import {data} from "../../constants/data";



const Awards = () => {
    return (
        <Wrapper>
            <div className='awards-page'>
                <div className='top'>
                    <ContainerWrapper>
                        <div className="title-content">
                            <div className='test-with-image'>
                                <h1 className='title'>
                                    Awards
                                </h1>
                            </div>
                        </div>
                        <div className='description-content'>
                            <p className='description'>
                                Awarded Best Wood Floor of the Year by the NWFA 10 times.
                            </p>
                        </div>
                        <div className='award-cards'>
                            {data.slice(0, 8).map((item, index) => <AwardCard key={index} {...item} cardClassName={'light'}/>)}
                        </div>
                        <div className="title-content">
                            <div className='test-with-image'>
                                <h1 className='title'>
                                    Press
                                </h1>
                            </div>
                        </div>
                        <div className='award-cards-press'>
                            {data.slice(8, 11).map((item, index) => <AwardCard key={index} {...item} cardClassName={'light'}/>)}
                        </div>

                    </ContainerWrapper>
                    <div className='bottom-img'> </div>
                </div>
                <div className='right-documents'>
                    <div className='right-documents-content'>
                        <div className='award-list-content'>
                            <div className="title-content">
                                <div className='test-with-image'>
                                    <h2 className='title'>
                                        Service on Committees
                                    </h2>
                                </div>
                            </div>
                            <div className='award-list'>
                                <p>
                                    2018-2021 NWFA Architect &
                                </p>
                            </div>
                            <div className='award-list'>
                                <p>
                                    Designer Committee
                                </p>
                            </div>
                        </div>
                        <div className='award-list-content'>
                            <div className="title-content">
                                <div className='test-with-image'>
                                    <h2 className='title'>
                                        NWFA Degrees
                                    </h2>
                                </div>
                            </div>
                            <div className='award-list'>
                                <p>
                                    Craftsman degree
                                </p>
                                <p>
                                    Master Craftsman degree
                                </p>
                                <p>
                                    Advanced Master Craftsman degree
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <ContainerWrapper>
                    <div className='items'>
                        <div className='left'>
                            <div className='award-list-content'>
                                <div className="title-content">
                                    <div className='test-with-image'>
                                        <h2 className='title'>
                                            Industry Instruction Experience
                                        </h2>
                                    </div>
                                </div>
                                <div className='award-list'>
                                    <div>
                                        <p>
                                            <span className='bold'>
                                                National Wood Flooring Association Instructor:
                                            </span>Advanced Installation and
                                            Advanced Sand & Finish schools
                                        </p>
                                        <p>
                                            <span className='bold'>
                                                International Wood Flooring Association Instructor:
                                            </span> Expert Sand & Finish
                                            schools
                                        </p>
                                        <p>
                                            <span className='bold'>
                                                2023 NWFA Expo Speaker/Presenter
                                            </span> – Using Dyes & Waxes, May 4, 2023
                                        </p>
                                        <p>
                                            <span className='bold'>
                                                NWFA Expo Attendee:
                                            </span> 2013 to 2023
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ContainerWrapper>
            </div>
        </Wrapper>
    );
};

export default Awards;

import axios from 'axios';
import {BASE_URL} from "constants/genral";
import Account from "utils/account";
import history from "utils/browserHistory";
import {changUserLogged} from "state/users";
import store from './index';

const httpClient = axios.create({
    baseURL: BASE_URL,
});

httpClient.interceptors.request.use((config) => {
    const accessToken = Account.getAccessToken();
    const sessionToken = localStorage.getItem('sessionToken')
    if (accessToken) {
        config.headers['x-api-key'] = accessToken;
    } else if(sessionToken){
        config.headers['X-Requested-With']= sessionToken
    }
    return config
});

httpClient.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            Account.delete();
            history.replace('/');
            store.dispatch(changUserLogged());
        }
        return Promise.reject(error)
    }
);

export default httpClient;

import React from "react";
import Wrapper from "views/Wrapper";
import ContainerWrapper from "views/ContainerWrapper";
import SEO from "views/shared/SEO";
import Products from "./Products";
import Services from "./Services";
import ColorLab from "./ColorLab";
import Top from "./Top";
import Awards from "./Awards";
import useContainer from "./hook";

const Home = () => {
    const {products} = useContainer();

    return (
        <>
            <SEO
                title='LED Coating Solutions'
                description='For 60 Years, Vesting Has Been Developing And Producing
                High-Quality Wood Treatment Products, Such As Waxes, Oils, Stains,
                Lacquers, Outdoor And Maintenance Products For Various Markets
                Such As The Floor And Furniture Industry And The Restoration And Decoration Industry.'
            />
            <Wrapper>
                <Top/>
                <ContainerWrapper>
                    <Products products={products}/>
                    <Services/>
                    <Awards/>
                    {/*<Media/>*/}
                    <ColorLab/>
                </ContainerWrapper>
            </Wrapper>
        </>
    )
}

export default Home;

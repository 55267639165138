import * as React from "react"

function BasketEmptySvg(props) {
    return (
        <svg
            width={180}
            height={180}
            viewBox="0 0 276 266"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g opacity={0.89}>
                <path
                    d="M141.701 259.402c63.348 0 114.701-51.353 114.701-114.701C256.402 81.353 205.049 30 141.701 30 78.353 30 27 81.353 27 144.701c0 63.348 51.353 114.701 114.701 114.701z"
                    fill="#f8fbff"
                />
                <path
                    opacity={0.8}
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M139.422 199.606a8.58 8.58 0 018.579-8.579h49.775c6.801 0 13.302 2.013 18.307 5.692 5.004 3.678 8.975 9.462 8.975 16.64 0 7.178-3.97 12.962-8.972 16.642-5.005 3.683-11.507 5.699-18.31 5.699H79.506a8.579 8.579 0 010-17.158h118.27c3.528 0 6.378-1.063 8.142-2.361 1.767-1.3 1.983-2.396 1.983-2.822 0-.424-.214-1.517-1.98-2.815-1.763-1.296-4.614-2.359-8.145-2.359L147.5 206.5c-4.738 0-8.078-2.156-8.078-6.894z"
                    fill="#dce0e6"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M217.5 97.154A9.648 9.648 0 01226.82 90h39.021c5.328 0 9.647 4.32 9.647 9.647 0 5.329-4.319 9.648-9.647 9.648h-31.615l-11.257 42.072c-1.377 5.147-6.666 8.203-11.813 6.826-5.147-1.377-8.203-6.666-6.826-11.813l13.17-49.226z"
                    fill="#dce0e6"
                />
                <path
                    opacity={0.8}
                    d="M209.164 61.05H92.119l-9.234 20.182h117.037l9.242-20.182z"
                    fill="#dce0e6"
                />
                <path
                    opacity={0.8}
                    d="M150.198 58H73.643l9.242 23.232h76.546L150.198 58z"
                    fill="#dce0e6"
                />
                <path
                    opacity={0.8}
                    d="M210.727 58h-42.054l-9.242 23.232h42.054L210.727 58z"
                    fill="#dce0e6"
                />
                <path
                    d="M201.493 81.231h-76.555v76.555h76.555V81.231z"
                    fill="#dce0e6"
                />
                <path
                    opacity={0.8}
                    d="M159.44 81.231H82.885v76.555h76.555V81.231z"
                    fill="#dce0e6"
                />
                <path
                    d="M108.077 265.364c11.542 0 20.9-9.357 20.9-20.9s-9.358-20.9-20.9-20.9c-11.543 0-20.9 9.357-20.9 20.9s9.357 20.9 20.9 20.9z"
                    fill="#dce0e6"
                />
                <path
                    d="M211.799 115.871H67.772c-10.796 0-18.771 9.157-16.33 18.737l11.734 56.347c1.766 6.919 8.533 11.827 16.33 11.827L206.5 211l21.663-76.367c5.337-15.633-5.542-18.762-16.364-18.762z"
                    fill="#dce0e6"
                />
                <path
                    opacity={0.8}
                    d="M107.5 255c5.799 0 10.5-4.701 10.5-10.5s-4.701-10.5-10.5-10.5S97 238.701 97 244.5s4.701 10.5 10.5 10.5z"
                    fill="#27272754"
                />
                <mask
                    id="a"
                    style={{
                        maskType: "alpha"
                    }}
                    maskUnits="userSpaceOnUse"
                    x={50}
                    y={112}
                    width={180}
                    height={103}
                >
                    <path
                        opacity={0.8}
                        d="M212.968 112H66.998c-10.942 0-19.024 9.915-16.55 20.288l11.892 61.009c1.79 7.491 8.648 12.805 16.55 12.805L207.597 215l21.955-82.685c2.475-10.4-5.616-20.315-16.584-20.315z"
                        fill="#dce0e6"
                    />
                </mask>
                <g mask="url(#a)">
                    <rect
                        width={12.1616}
                        height={55.7064}
                        rx={6.08081}
                        transform="matrix(.99999 -.00545 .00112 1 150.106 139.389)"
                        fill="#27272754"
                    />
                    <rect
                        width={11.9998}
                        height={214.14}
                        rx={5}
                        transform="matrix(.0067 .99998 -1 .00254 257.056 114.354)"
                        fill="#27272754"
                    />
                    <rect
                        width={12.1616}
                        height={55.7064}
                        rx={6.08081}
                        transform="matrix(.99999 -.00545 .00112 1 184.476 139.066)"
                        fill="#27272754"
                    />
                    <rect
                        width={12.1616}
                        height={55.7064}
                        rx={6.08081}
                        transform="matrix(.99999 -.00545 .00112 1 115.737 139.711)"
                        fill="#27272754"
                    />
                    <rect
                        width={12.1616}
                        height={55.7064}
                        rx={6.08081}
                        transform="matrix(.99999 -.00545 .00112 1 81 140.183)"
                        fill="#27272754"
                    />
                </g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M183.354 3.084c-1.461-2.561-4.173-3.766-6.057-2.69-1.885 1.074-2.228 4.022-.767 6.583.042.073.084.144.127.214-.958.197-1.858 1.134-2.211 2.424-.454 1.66.17 3.278 1.394 3.613 1.224.335 2.585-.74 3.039-2.4.124-.456.168-.907.14-1.33 1.207.66 2.517.77 3.569.17.926-.528 1.48-1.509 1.63-2.674.253.132.528.24.821.32 1.661.455 3.278-.17 3.613-1.394.335-1.224-.74-2.584-2.401-3.038-1.069-.293-2.12-.138-2.822.335l-.075-.133zM96.873 13.118c-.857.874-1.936 1.332-3.026 1.163-1.263-.196-2.258-1.189-2.77-2.567a4.08 4.08 0 01-.872.861c-1.39 1.017-3.123 1.011-3.873-.013-.75-1.024-.23-2.678 1.159-3.695 1.103-.807 2.423-.97 3.295-.49.46-2.9 2.558-4.98 4.695-4.649 2.108.327 3.47 2.876 3.086 5.73.827.089 1.715.618 2.353 1.49 1.017 1.389 1.011 3.123-.013 3.872-1.024.75-2.679.23-3.695-1.159a4.22 4.22 0 01-.339-.543z"
                    fill="#27272754"
                />
                <path
                    d="M18.968 126.461c.518-2.144 3.567-2.144 4.085 0l1.742 7.216a2.1 2.1 0 001.549 1.549l7.216 1.742c2.144.518 2.144 3.567 0 4.085l-7.216 1.742a2.1 2.1 0 00-1.55 1.549l-1.741 7.216c-.518 2.144-3.567 2.144-4.085 0l-1.742-7.216a2.101 2.101 0 00-1.55-1.549l-7.215-1.742c-2.144-.518-2.144-3.567 0-4.085l7.216-1.742a2.101 2.101 0 001.55-1.549l1.741-7.216zM240.968 198.461c.518-2.144 3.567-2.144 4.085 0l1.742 7.216a2.1 2.1 0 001.549 1.549l7.216 1.742c2.144.518 2.144 3.567 0 4.085l-7.216 1.742a2.1 2.1 0 00-1.549 1.549l-1.742 7.216c-.518 2.144-3.567 2.144-4.085 0l-1.742-7.216a2.1 2.1 0 00-1.549-1.549l-7.216-1.742c-2.144-.518-2.144-3.567 0-4.085l7.216-1.742a2.1 2.1 0 001.549-1.549l1.742-7.216z"
                    fill="#27272754"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M49.628 74.987a4.359 4.359 0 100-8.718 4.359 4.359 0 000 8.718zm0 3.27a7.628 7.628 0 100-15.257 7.628 7.628 0 000 15.256z"
                    fill="#27272754"
                />
                <path
                    d="M32.014 187.053c-.333-1.306.873-2.483 2.17-2.118l8.759 2.461c1.298.365 1.714 1.997.75 2.939l-6.511 6.354c-.965.942-2.587.486-2.92-.82l-2.248-8.816zM258.713 157.018c1.306-.331 2.481.876 2.115 2.173l-2.472 8.756c-.367 1.297-2 1.711-2.94.745l-6.346-6.519c-.94-.966-.482-2.587.824-2.918l8.819-2.237z"
                    fill="#27272754"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M230.128 65.63a3.502 3.502 0 100-7.004 3.502 3.502 0 000 7.004zm0 2.626a6.128 6.128 0 100-12.257 6.128 6.128 0 000 12.257z"
                    fill="#27272754"
                />
                <rect
                    x={45}
                    y={114}
                    width={189}
                    height={13}
                    rx={6.5}
                    fill="#a6a4a41c"
                />
                <path
                    d="M93.12 16.106c-1.72 6.885 3.855 21.352 12.461 22.5 15.925 2.123 13-16.5 3.5-13-11.306 4.165.008 21.983 15.5 26M183.635 14.367c9.464 18.42-15.611 38.087-22.553 41.73"
                    stroke="#AECB7B"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeDasharray="4 4"
                />
                <path
                    d="M174.57 265.364c11.543 0 20.9-9.357 20.9-20.9s-9.357-20.9-20.9-20.9-20.9 9.357-20.9 20.9 9.357 20.9 20.9 20.9z"
                    fill="#dce0e6"
                />
                <path
                    opacity={0.8}
                    d="M174.5 255c5.799 0 10.5-4.701 10.5-10.5s-4.701-10.5-10.5-10.5-10.5 4.701-10.5 10.5 4.701 10.5 10.5 10.5z"
                    fill="#27272754"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_3777_2460"
                    x1={140.817}
                    y1={-7.34947}
                    x2={143.167}
                    y2={395.463}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" stopOpacity={0} />
                    <stop offset={1} stopColor="#AECB7B" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_3777_2460"
                    x1={71}
                    y1={152.5}
                    x2={117.645}
                    y2={198.86}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#AECB7B" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_3777_2460"
                    x1={311.08}
                    y1={95.559}
                    x2={206.579}
                    y2={97.5073}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#DFEDC7" />
                    <stop offset={1} stopColor="#B4D082" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_3777_2460"
                    x1={82.7422}
                    y1={65.7075}
                    x2={209.646}
                    y2={70.8629}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#AECB7B" />
                    <stop offset={1} stopColor="#76964A" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_3777_2460"
                    x1={116.537}
                    y1={58}
                    x2={116.537}
                    y2={81.2316}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CAE0A3" />
                    <stop offset={1} stopColor="#76964A" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear_3777_2460"
                    x1={185.079}
                    y1={58}
                    x2={185.079}
                    y2={81.2316}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#AECB7B" />
                    <stop offset={1} stopColor="#AECB7B" />
                </linearGradient>
                <linearGradient
                    id="paint6_linear_3777_2460"
                    x1={122.64}
                    y1={138.638}
                    x2={233.242}
                    y2={140.387}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.392237} stopColor="#AECB7B" />
                    <stop offset={1} stopColor="#D3ECA7" />
                </linearGradient>
                <linearGradient
                    id="paint7_linear_3777_2460"
                    x1={104}
                    y1={123.5}
                    x2={145.545}
                    y2={134.326}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CADDA9" />
                    <stop offset={1} stopColor="#CDE7A0" />
                </linearGradient>
                <linearGradient
                    id="paint8_linear_3777_2460"
                    x1={50.7989}
                    y1={138.138}
                    x2={229.552}
                    y2={140.274}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#BED98E" />
                    <stop offset={1} stopColor="#CCE1A8" />
                </linearGradient>
                <linearGradient
                    id="paint9_linear_3777_2460"
                    x1={49.7961}
                    y1={136.109}
                    x2={230.964}
                    y2={138.136}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#AECB7B" />
                    <stop offset={1} stopColor="#AECB7B" />
                </linearGradient>
                <linearGradient
                    id="paint10_linear_3777_2460"
                    x1={242.5}
                    y1={121}
                    x2={41.9664}
                    y2={85.6755}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#AECB7B" />
                    <stop offset={1} stopColor="#AECB7B" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default BasketEmptySvg

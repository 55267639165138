import React from 'react';
import {Link} from "react-router-dom";
import ButtonWithSvg from "views/shared/ButtonWithSvg";
import textImage from "assets/images/textBackground.png";
import './style.scss';

const Explanation = ({
                         title,
                         buttonText = 'Learn More',
                         withButton,
                         description,
                         redirectRoute = '#',
                         rightButton,
                         withTextImage,
                         ulLi,
                         withVideo
                     }) => (

    <div className='explanation'>
        <div className='titleContainer'>
            {withTextImage && <img className='leftTextImage' src={textImage} alt={'..'}/>}
            <h2 className='content-title'>{title}</h2>
            <div className='des-content'>
                <p className='description-text'>{description || 'description'}</p>
            </div>
            {
                ulLi && <ul className='ulLi'>
                    {
                        ulLi?.map((el, index) => <li key={index}>{el.t}</li>)
                    }
                </ul>
            }
        </div>
        {withButton &&
            <div className='button-content' style={{justifyContent: rightButton ? 'flex-end' : "flex-start"}}>
                <Link to={redirectRoute}>
                    <ButtonWithSvg title={buttonText} buttonClass='learn-more'/>
                </Link>
            </div>
        }
    </div>
)

export default Explanation;

import React from "react";
import {Navigate, Outlet} from 'react-router-dom';
import Account from "utils/account";

const UserGuard = () => {
    const accessToken = Account.getAccessToken();

    if (accessToken) {
        return <Outlet />
    }

    return <Navigate to='/' replace />
};

export default UserGuard;

import {createSlice, isAnyOf} from "@reduxjs/toolkit";
import {fetchServiceById, fetchServices, sendServiceRequest} from "./operations";

const initialState = {
    services: {},
    serviceById: {},
    fetchLoader: false,
    sendLoader: false,
};

export const servicesSlice = createSlice({
    name: 'services',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchServices.fulfilled, (state, {payload}) => {
            state.services = payload;
            state.fetchLoader = false;
        })
        builder.addCase(fetchServiceById.fulfilled, (state, {payload}) => {
            state.serviceById = payload;
            state.fetchLoader = false;
        })

        builder.addCase(sendServiceRequest.pending, (state) => {
            state.loader = true
        })
        builder.addCase(sendServiceRequest.fulfilled, (state) => {
            state.loader = false
        })

        builder.addMatcher(isAnyOf (fetchServices.pending, fetchServiceById.pending),
            (state) => {
                state.fetchLoader = true;
            })
    }
});

export default servicesSlice.reducer;
import * as React from "react"

function ArrowDown(props) {
    return (
        <svg
            fill="#68885D"
            height="15px"
            width="15px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 511.787 511.787"
            xmlSpace="preserve"
            {...props}
        >
            <path d="M508.667 125.707a10.623 10.623 0 00-15.04 0L255.76 363.573 18 125.707c-4.267-4.053-10.987-3.947-15.04.213a10.763 10.763 0 000 14.827L248.293 386.08a10.623 10.623 0 0015.04 0l245.333-245.333c4.161-4.054 4.161-10.88.001-15.04z" />
        </svg>
    )
}

export default ArrowDown

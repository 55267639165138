import {useDispatch, useSelector} from "react-redux";
import {showModal} from "state/modal";
import {useParams} from "react-router-dom";

function useContainer() {
    const dispatch = useDispatch();
    const {id} = useParams();
    const {isUserLogged} = useSelector(({users}) => users);

    const showReviewModal = () => {
        if(isUserLogged) {
            dispatch(showModal({
                modalType: 'WRITE_REVIEW_MODAL',
                modalProps: {
                    productId: id,
                }
            }));
            return;
        }
        dispatch(showModal({
            modalType: 'SIGN_IN_MODAL',
        }))
    }
    return {
        showReviewModal,
    }
}

export default useContainer;
import React, {useMemo, useState} from 'react';
import {CardCvcElement, CardExpiryElement, CardNumberElement} from "@stripe/react-stripe-js";
import {isEmpty} from "lodash";
import {CheckCircleTwoTone} from '@ant-design/icons';
import ButtonWithSvg from "views/shared/ButtonWithSvg";
import useContainer from "./hook";
import "./style.scss";

import {Form, Checkbox} from 'antd';
import {useDispatch, useSelector} from "react-redux";

import PaymentLine from "./PaymentLine";
import FedexSVG from "../../../../assets/svg/FedexSVG";
import AddressForm from "../../../AddressForm";
import {FormikProvider, useFormik} from "formik";
import validationSchema from "../../../../utils/yupLocalised/scheme/address";
import {addShipping} from "../../../../state/zip/operations";


const cardOptions = {
    iconStyle: "solid",
    style: {
        base: {
            iconColor: "#c4f0ff",
            color: "#636270",
            fontWeight: 500,
            fontFamily: "Montserrat, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": {color: "#fce883"},
            "::placeholder": {color: "#636270"}
        },
        invalid: {
            iconColor: "#ffc7ee",
            color: "#ffc7ee"
        },
        confirmCardSetup: ('{SETUP_INTENT_CLIENT_SECRET}', {
            payment_method: '{PAYMENT_METHOD_ID}',
        })
    }
}

const cardNumberOptions = {
    showIcon: true,
    ...cardOptions,
}

const CheckoutForm = ({amount, shippingMode, zipData}) => {
    const dispatch = useDispatch()
    const {currentUser} = useSelector(state => state.users)
    const [checked, setChecked] = useState(true)
    const onSubmit = async (values) => {
            await dispatch(addShipping({values}))
    }

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            address_first: '',
            address_last: '',
            city: '',
            zip_code: '',
            phone: '',
            region: ''

        },
        validationSchema,
        onSubmit,
    });

    const {
        sendLoader,
        message,
        handleSubmit,
    } = useContainer({amount, shippingMode, checked, formik});

    const onChange = (e) => {
        setChecked(e.target.checked)
    };



    const shipToAddress = useMemo(() => {
        const str = `${zipData.tax.Zip}, ${zipData.zipCode.country}, ${zipData.tax.State} ${zipData.tax.City}`
        return str
    }, [zipData])





    return (
        <div className='checkout-form'>
            {!isEmpty(message) && message.status === 'success' ? (
                <div className='success-content'>
                    <div className='content'>
                        <CheckCircleTwoTone style={{fontSize: '150px'}} twoToneColor="#68885D"/>
                        <p className='message'>{message.value}</p>
                    </div>
                </div>
            ) : (
                <div className='mainContainer'>
                    <div className='paymentInfo'>
                        <p className='paymentTitle'>Payment</p>
                        <PaymentLine title='Contact' text={currentUser.email} role='first'/>
                        <PaymentLine title='Ship to' text={shippingMode === 1 ? shipToAddress : '---'}/>
                        <PaymentLine title='Shipping method' text={shippingMode === 1 ? <FedexSVG/> : '---'}
                                     role='last'/>
                    </div>
                    <form onSubmit={handleSubmit} className='form'>
                        <div className='formContainer'>
                            <div className='card-number-content input'>
                                <input className='card-number elinput' placeholder='Name on card'/>
                            </div>
                            <div className='card-number-content'>
                                <CardNumberElement options={cardNumberOptions} className='card-number element'/>
                            </div>


                            <div className="two-inputs">
                                <div className="expiration-data-content">
                                    <CardExpiryElement options={cardOptions} className='element'/>
                                </div>
                                <div className="cvc-code-content">
                                    <CardCvcElement options={cardOptions} className='element'/>
                                </div>
                            </div>
                            <p style={{color: 'red', marginTop: 10}}>
                                {!isEmpty(message) && message.status === 'rejected' && message.value}
                            </p>
                            {shippingMode === 1 &&
                                <Checkbox checked={checked} onChange={onChange}>Billing address is same as
                                    shipping</Checkbox>}
                        </div>

                        {!checked &&
                            <Form onFinish={formik.handleSubmit}>
                                <FormikProvider value={formik}>
                                    <AddressForm/>
                                </FormikProvider>
                            </Form>}


                        <div className="button-content">
                            <ButtonWithSvg loader={sendLoader} title='Pay' buttonClass='pay-button' htmlType='submit'/>
                        </div>
                    </form>
                </div>

            )}
        </div>
    );
};

export default CheckoutForm;

import React from "react";
import "./style.scss";
import MinusSvg from "../../../assets/svg/MinusSvg";
import AddSvg from "../../../assets/svg/AddSvg";

const Counting = ({count, handleCount}) => {


    const handleCounting = (type) => {
        if (type === 'minus') {
            if (count !== 1) {
                handleCount(count - 1);
            }
            return;
        }
        handleCount(count + 1)
    };


    return (
        <div className='countingContainer'>
            <button className='button' onClick={() => handleCounting('minus')}>
                <MinusSvg/>
            </button>
            <span className='countingValue'>{count}</span>

            <button className='button' onClick={() => handleCounting('plus')}>
                <AddSvg/>
            </button>
        </div>
    )
}

export default Counting;

import React from 'react';
import {Link} from "react-router-dom";
import {isEmpty} from "lodash";
import moment from "moment";
import {API_HOST} from "constants/genral";
import "./style.scss";

const PaymentCard = ({priceData, products_id, product, count, updated_at}) => {
    return (
        <div className='payment-card'>
            <div className='product'>
                <Link to={`/product-details/${products_id}`}>
                    <img className='productImg' src={`${API_HOST}/${product?.img}`} alt="#"/>
                </Link>
                <div className='productTitle'>
                    <h2 className='sub-title'>{product.title}</h2>
                    {!isEmpty(product?.color) && <h4>Color: {product?.color?.[0]?.name}</h4>}
                    <h4 className='mobile-info description-text'>Date: {moment(updated_at).format('L')}</h4>
                    <h4 className='mobile-info description-text'>Count: {count}</h4>
                    <h4 className='mobile-info description-text'>Payment Amount: ${+count * +priceData.price}</h4>
                    <div className='specificallyDes'>
                        {product?.categories[0]?.categories &&
                            <div className='specificallyDesItem'>
                                <p className='description-text'>Category: </p>
                                <p className='description-text category'>{product?.categories[0]?.categories}</p>
                            </div>
                        }
                        {!isEmpty(product?.tags) &&
                            <div className='specificallyDesItem'>
                                <p className='description-text'>Sheens: </p>
                                <p className='description-text category'>{product?.tags[0]?.categories}</p>
                            </div>
                        }
                        {!isEmpty(product?.type) &&
                            <div className='specificallyDesItem'>
                                <p className='description-text'>Type: </p>
                                <p className='description-text category'>{product?.type[0]?.categories}</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className='item'><p className='text description-text'>{moment(updated_at).format('L')}</p></div>
            <div className='item'><p className='text description-text'>{count}</p></div>
            <div className='item'><p className='text description-text'>${+count * +priceData.price}</p>
            </div>
        </div>
    );
};

export default PaymentCard;

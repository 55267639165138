import {useCallback, useState} from "react";
import useQueryParams from "hooks/useQueryParams";
import {useSelector} from "react-redux";

function useContainer() {
    const [filtersVisible, setFiltersVisible] = useState(false);
    const {params, onChange, searchParams} = useQueryParams()
    const { products, loader } = useSelector(({products}) => products);

    const changeFilterVisible = useCallback(() => {
        setFiltersVisible(prev => !prev);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtersVisible]);

    return {
        filtersVisible,
        changeFilterVisible,
        onChange,
        params,
        products,
        loader,
        searchParams
    }
}

export default useContainer;
import * as React from "react"

function ArrowUp(props) {
    return (
        <svg
            fill="#68885D"
            height="15px"
            width="15px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 511.735 511.735"
            xmlSpace="preserve"
            {...props}
        >
            <path d="M508.788 371.087L263.455 125.753a10.623 10.623 0 00-15.04 0L2.975 371.087c-4.053 4.267-3.947 10.987.213 15.04a10.763 10.763 0 0014.827 0l237.867-237.76 237.76 237.76c4.267 4.053 10.987 3.947 15.04-.213a10.663 10.663 0 00.106-14.827z" />
        </svg>
    )
}

export default ArrowUp

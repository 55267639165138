import React from 'react';
import {Divider} from "antd";
import Wrapper from "views/Wrapper";
import ContainerBigWrapper from "views/shared/ContainerBigWrapper";
import "./style.scss";

const Policy = () => {
    return (
        <Wrapper>
            <ContainerBigWrapper>
                <div className='policy'>
                    <div className="item">
                        <div className='title-content'>
                            <h2 className="title">
                                Non-Returnable Items and Final Purchases
                            </h2>
                        </div>
                        <div className='description'>
                            <p className='text'>
                                All Vesting LED USA Colors are mixed fresh to your specifications and, as a custom
                                order, are non-exchangeable and non-returnable. Please check the invoice before you make
                                the payments. Once the invoice is paid, we understand that you approve of the order and
                                will start mixing the oils.
                                Custom orders and special savings promotions and bulk purchases are all final purchases
                                and cannot be exchanged or returned.
                            </p>
                            {/*<p className='text second'>*/}
                            {/*    Custom orders and special savings promotions and bulk purchases are all final purchases*/}
                            {/*    and cannot be exchanged or returned.*/}
                            {/*</p>*/}
                        </div>
                    </div>
                    <Divider/>
                    <div className="item">
                        <div className='title-content'>
                            <h2 className="title">
                                General Return & Exchange Information.
                            </h2>
                        </div>
                        <div className='description'>
                            <p className='text'>
                                Returnable unopened and undamaged items, excluding LED mixed oil products, are
                                non-refundable, but can be returned within 7 days of purchase for an exchange or
                                merchandise credit. The return shipping costs must be paid by the purchaser.
                            </p>
                            <p className='text second'>
                                All returned items for exchange or credit must have a return authorization number (RA#).
                                All returned items for exchange or credit are subject to a 25% service fee. Items
                                returned without an RA# will not be accepted
                            </p>
                            <p className='subTitle second'>
                                DAMAGED RETURNS
                            </p>
                            <p className='text second'>
                                Conduct an inspection of merchandise upon receipt and, if anything is damaged, please
                                note the specifics clearly on all copies of the bill of lading (BOL) and delivery
                                receipt. Then notify us within 24 hours. This will allow us to best assist you with the
                                return or replacement. If you do not have time for an inspection or the driver rushes
                                you, YOU MUST WRITE “subject to inspection” next to your signature on the Delivery
                                Receipt and BOL. By signing for your delivery without noting any issues, you affirm that
                                the merchandise was delivered to you without any damages. When your order is delivered,
                                if there is visible damage to the packaging or boxes, please refuse the delivery and
                                note the damage on the form from the delivery driver. Then, please notify us
                                immediately. This allows us to order a replacement from the manufacturer promptly and
                                facilitates us submitting a claim with the shipper.</p>
                        </div>
                    </div>
                </div>
            </ContainerBigWrapper>
        </Wrapper>
    );
};

export default Policy;

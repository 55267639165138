import React from 'react';
import {API_HOST} from "constants/genral";
import {customUserName} from "utils/helpers";
import "./style.scss";

const colorList = ['#1C6037', '#7265e6', '#ffbf00', '#00a2ae'];

const UserAccount = ({size, userName, image}) => {
    return (
        image ? <img alt={'...'} className='user-avatar' src={`${API_HOST}/${image}`} style={{width: size, height: size}} /> : <div
                className='user-avatar'
                style={{
                    width: size,
                    height: size,
                    backgroundColor: colorList[Math.floor(Math.random() * 3) + 1],
                }}
            >
                {!image && customUserName(userName[0], userName[1])}
            </div>
    );
};

export default UserAccount;
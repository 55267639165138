import { createSlice } from "@reduxjs/toolkit";
import {fetchMedia} from "./operations";

const initialState = {
    media: [],
    mediaLoader: false,
};

export const mediaSlice = createSlice({
    name: 'medias',
    initialState,
    // reducers: {
    //     setMedia: (state, { payload }) => {
    //         return {...state, media: payload, mediaLoader: false}
    //     }
    // },
    extraReducers: (builder) => {
        builder.addCase(fetchMedia.pending, (state) => {
            state.mediaLoader = true;
        })
        builder.addCase(fetchMedia.fulfilled, (state, action) => {
            state.mediaLoader = false;
            state.media = action.payload;
        })
    }
});

// export const { setMedia } = mediaSlice.actions;
export default mediaSlice.reducer;
import * as React from "react"

function Twitter(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M7.55 21.749c9.055 0 14.008-7.503 14.008-14.008 0-.211-.005-.427-.014-.638A9.999 9.999 0 0024 4.553c-.898.4-1.85.66-2.826.774a4.95 4.95 0 002.165-2.723A9.898 9.898 0 0120.214 3.8a4.93 4.93 0 00-8.394 4.49 13.985 13.985 0 01-10.147-5.14 4.93 4.93 0 001.523 6.57 4.93 4.93 0 01-2.23-.614v.06a4.922 4.922 0 003.95 4.829 4.892 4.892 0 01-2.221.084A4.934 4.934 0 007.293 17.5 9.876 9.876 0 010 19.538a13.968 13.968 0 007.55 2.21z"
                fill="#636270"
            />
        </svg>
    )
}

export default Twitter

import React from "react";
import Explanation from "views/shared/Explanation";
import textImage from "assets/images/textBackground.png"
import "./style.scss";
import serviceImg from '../../../assets/images/serviceImg.png'

const data = {
    title: 'Services',
    description: 'The experts at LED Coating Solutions can provide',
    ulLi: [{t: 'Color-Matching for wood flooring manufacturers or on-site applications'}, {t: 'Color Creation for a perfect custom color'}, {t: ' On-Site Finishing to create custom color throughout the world'}, {t: ' Wood Floor Prefinishing using the LED Coating Solutions finish line.'}, {t: ' Factory finishing expertise for designing and installing prefinishing lines.'}]
}

const Services = () => {
    return (
        <div className='services'>
            <div className='description-title-content'>
                <h2 className='content-title'>Services</h2>
            </div>

            <div className='left-content'>
                <Explanation withButton buttonText='See all' redirectRoute='/services' {...data}/>

            </div>
            <div className='video-content'>
                <div className='topDiv'>
                    <img className='leftTextImage' src={textImage} alt='...'/>
                </div>
                <img src={serviceImg} width={'100%'} height={'100%'} style={{objectFit:'cover'}} alt=""/>
                {/*<VideoComponent video={'https://www.youtube.com/watch?v=9vt0VCIsLv8'}/>*/}
            </div>
        </div>
    )
}

export default Services;

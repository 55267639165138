import React, {useMemo, useState} from "react";
import {Radio, Rate} from 'antd';
import {isEmpty} from "lodash";
import {Link} from "react-router-dom";
import ButtonWithSvg from "views/shared/ButtonWithSvg";
import SelectableColors from "views/shared/SelectableColors";
import {API_HOST} from "constants/genral";
import Gallery from "views/shared/Gallery";
import useContainer from "./hook";
import "./style.scss";
import SheenCube from "./component/SheenCube'";
import TypeCube from "./component/TypeCube";
import CanCube from "./component/CanCube";
import Counting from "../Counting";
import InputColor from "./component/InputColor";


const TopContent = ({id, title, categories, all_stars, price, color, basketData, carousel_data, pdf_link, sheens}) => {


    const {
        priseAndSize,
        handleSizeChange,
        colorName,
        colorId,
        setColorId,
        setColorName,
        handleChange,
        handleAddToBasket,
        basketId,
        deleteOrAddLoader,
        handleChangeSheens,
        selectedShen,
        handleChangeTypes,
        selectedType,
        getUniqueItems,
        getUniqueSheens,
        count,
        handleCount,
        text,
        setText,
        borderErrors

    } = useContainer({color, price, basketData, title, id});


    const [disabled, setDisabled] = useState(false);


    const getPriceRange = useMemo(() => {
        if (isEmpty(price)) {
            return ''
        }
        const arr = []
        if (!isEmpty(priseAndSize['type_data'])) {
            const fountPrice = priseAndSize['type_data'].find(el => el.tag_id === selectedShen && el.title === selectedType)?.price
            if (fountPrice) {
                return `$${fountPrice}`
            }
            priseAndSize['type_data'].forEach((el) => {
                arr.push(+el.price)
            })
        }
        if (arr.length === 0) {
            return `$${+priseAndSize.price}`
        } else {
            return `$${Math.min(...arr)} - ${Math.max(...arr)}`
        }
    }, [selectedType, selectedShen, priseAndSize])


    const handleBuskedTittle = useMemo(() => {
        if (isEmpty(price)) {
            return 'Upon request'
        }

        if (basketId) {

            return 'Remove from cart'
        }
        if (!isEmpty(color)) {
            if (colorId) {
                if (!isEmpty(priseAndSize['type_data'])) {
                    const fountPrice = priseAndSize['type_data'].find(el => el.tag_id === selectedShen && el.title === selectedType)?.price
                    if (fountPrice) {
                        return 'Add to Cart'
                    } else {
                        return 'Add to Cart'
                    }
                } else {
                    return 'Add to Cart'
                }
            } else {
                return 'Add to Cart'
            }
        } else {

            if (isEmpty(getUniqueSheens) && isEmpty(getUniqueItems)) {
                return 'Add to Cart'
            } else {
                const fountPrice = priseAndSize['type_data'].find(el => el.tag_id === selectedShen && el.title === selectedType)?.price
                if (fountPrice) {
                    return 'Add to Cart'
                } else {
                    return 'Add to Cart'
                }
            }
        }

    }, [basketId, priseAndSize, selectedShen, selectedType, colorId, getUniqueSheens, getUniqueItems])


    return (
        <div className='top-content'>
            <div className='left-content'>
                {!isEmpty(carousel_data) && carousel_data.length > 1 ?
                    <Gallery carouselData={carousel_data}/> :
                    <div className='product-big-image'
                         style={{backgroundImage: `url(${API_HOST}${carousel_data[0]})`}}/>
                }
            </div>
            <div className='right-content'>
                <div className='title-content'>
                    <div className='header-content'>
                        <div className='code'><p>{priseAndSize?.code}</p></div>
                        <div className='name'><p className='title'>{title}</p></div>
                    </div>
                </div>
                <div className='price-and-rating'>
                    {getPriceRange && <p className='price'>{getPriceRange}</p>}

                    <Rate className='rating' value={+all_stars || 0} allowHalf disabled/>
                    <p className='rate-number'>{all_stars || 0}</p>
                </div>
                <div className='description'>
                    <div className='item'>
                        <p className='name'>Category:</p>
                        {categories.map(item => <span className='global_color'
                                                      key={item.categories}>{item.categories}</span>)}
                    </div>
                    {(!isEmpty(pdf_link) || categories?.[0].categories === 'LED-Hardwax Oils') && (
                        <div className='item'>
                            <p className='name'>Information:</p>
                            {!isEmpty(pdf_link) && (
                                <button className='button'>
                                    <a className='technical' href={`${API_HOST}${pdf_link}`} target="_blank"
                                       rel="noreferrer">Technical Info</a>
                                </button>
                            )}
                            {categories?.[0].categories === 'LED-Hardwax Oils' && (
                                <button className='button'>
                                    <Link className='technical' to='/how-to-use'>How to use</Link>
                                </button>
                            )}
                        </div>
                    )}
                </div>
                {price?.[0]?.size_type &&
                    <div className='size'>
                        <p className='title'>Can sizes</p>
                        <div className='button-group'>
                            {/*<Radio.Group defaultValue={price[0].id} buttonStyle="solid" onChange={handleSizeChange}>*/}
                            {price.map(item => (
                                <CanCube item={item} priseAndSize={priseAndSize} key={item.id}
                                         selectedType={selectedType}
                                         selectedShen={selectedShen}
                                         handleSizeChange={handleSizeChange}/>
                            ))}
                        </div>
                    </div>
                }
                {/*************************************************************Sheeens               */}
                <div className='sheens-and-size'>
                    {!isEmpty(getUniqueSheens) && (
                        <div className='size'>
                            <p className='title'>Sheens</p>
                            <div className='button-group sheensGroup'>
                                {getUniqueSheens.map(item => (
                                    <SheenCube key={item.id} handleChangeSheens={handleChangeSheens} item={item}
                                               borderErrors={borderErrors}
                                               selectedType={selectedType}
                                               selectedShen={selectedShen} typeData={priseAndSize?.type_data}/>
                                ))}
                            </div>
                        </div>
                    )}

                    {/******************************************************Type./*/}
                    {!isEmpty(priseAndSize?.type_data) && (
                        <div className='size'>
                            <p className='title'>Types</p>
                            <div className='button-group'>
                                {getUniqueItems.map(item => (
                                    <TypeCube key={item.id} item={item} selectedType={selectedType}
                                              sheens={sheens}
                                              borderErrors={borderErrors}
                                              selectedShen={selectedShen} typeData={priseAndSize?.type_data}
                                              handleChangeTypes={handleChangeTypes}/>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <div style={{marginTop: 30}}>
                    {!isEmpty(color) &&
                        <InputColor colorsArray={color}
                                    setColorId={setColorId}
                                    colors={colorId}
                                    setColorName={setColorName}
                                    selectedType={selectedType}
                                    text={text}
                                    setText={setText}/>}

                </div>

                <div className='colors'>
                    {!isEmpty(color) && (
                        <SelectableColors
                            colorData={color}
                            selectedType={selectedType}
                            colors={colorId}
                            setColorId={setColorId}
                            setColorName={setColorName}
                            colorName={colorName}
                            handleChange={handleChange}
                        />
                    )}
                </div>
                {
                    !isEmpty(priseAndSize?.type_data) && <Counting count={count} handleCount={handleCount}/>
                }

                <div className='button-div'>
                    <div onClick={handleAddToBasket}>
                        <ButtonWithSvg loader={deleteOrAddLoader}
                                       title={handleBuskedTittle}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopContent;

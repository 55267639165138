import {createAsyncThunk} from "@reduxjs/toolkit";
import httpClient from "state/httpClient";
import {appendFormData} from "utils/helpers";

export const getAllBasket = createAsyncThunk(
    'basket/getAllBasket', async () => {
        try {
            const {data} = await httpClient.post(`/basket`);
            return data
        } catch {
            //
        }
    });

export const addBasket = createAsyncThunk(
    'basket/addBasket', async (payload) => {
        try {
            const {data} = await httpClient.post(`/basket/data`, appendFormData(payload));
            return data.basket;
        } catch {
            //
        }
    });

export const deleteBasket = createAsyncThunk(
    'basket/deleteBasket', async (payload) => {
        try {
            await httpClient.post(`/basket/delete`, appendFormData(payload));
            return payload
        } catch {
            //
        }
    });

export const fetchPaymentHistory = createAsyncThunk(
    'basket/fetchPaymentHistory', async (_) => {
        try {
            const {data} = await httpClient.get(`/basket/payments`);
            return data.data;
        } catch {
            //
        }
    });
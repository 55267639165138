import {configureStore} from "@reduxjs/toolkit";
import media from "state/media";
import modal from "state/modal";
import users from "state/users";
import products from "state/products";
import basket from "state/basket";
import services from "state/services";
import profile from "state/profile";
import colorLab from "state/colorLab";
import home from "state/home";
import payment from "state/payment";
import zip from "state/zip";

const store = configureStore({
    reducer: {
        media,
        modal,
        users,
        products,
        basket,
        services,
        profile,
        colorLab,
        home,
        payment,
        zip,
    },
});

export default store;

import React from 'react';
import {Carousel} from "antd";
import 'antd/dist/antd.min.css';
import "./style.scss";

const CarouselContent = ({data, card: Card}) => (
    <Carousel className='carousel' arrows swipeToSlide draggable autoplay>
        {data.map((item, index) => (
            <div className='carousel-item' key={item?.title || '' + index}>
                <Card {...item} redirectRoute='/product-details' />
            </div>
        ))}
    </Carousel>
)

export default CarouselContent;

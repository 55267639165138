import React from "react";
import "./style.scss";

const DescriptionContent = ({description}) => {

    return (
        <div className='description-content' dangerouslySetInnerHTML={{__html: description}} />
    )
}

export default DescriptionContent;

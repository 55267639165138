import {useCallback, useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {debounce} from "lodash";
import {addBasket, deleteBasket} from "state/basket/operations";
import {addPrice} from "state/basket";

function useContainer({defCount, dataTotal, price, products_id, id, countPopupHandler, ...props}) {
    const dispatch = useDispatch();
    const [productTotal, setProductTotal] = useState(dataTotal);
    const [count, setCount] = useState(+defCount);
    const popoverRef = useRef(null);

    const handleDeleteItem = async () => {
        await dispatch(deleteBasket({id: +id}));
        dispatch(addPrice({price: productTotal, operator: 'minus'}));
    }

    const addBasketHandler = debounce((data) => {
        dispatch(addBasket(data));
    }, 100);

    const handleCount = useCallback((value) => {
        countPopupHandler(value, id);

        if(value > 10) return;

        setCount(value)

        const basketData = {
            color_id: +props.color_id,
            price_id: +props.price_id,
            products_id: +products_id,
            tag_id: +props.tag_id,
            type_id: +props.type_id,
            price_type_id: +props.price_type_id,
            count: value,
        }
        addBasketHandler(basketData);
    }, [productTotal, count]);

    useEffect(() => {
        setProductTotal(price * count);
    }, [count])

    return {
        handleCount,
        productTotal,
        count,
        popoverRef,
        handleDeleteItem,
    }
}

export default useContainer;

import * as React from "react"

function Instagram(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M16 7a1 1 0 112 0 1 1 0 01-2 0z" fill="#636270" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 7.251a4.75 4.75 0 100 9.5 4.75 4.75 0 000-9.5zm-3.25 4.75a3.25 3.25 0 116.5 0 3.25 3.25 0 01-6.5 0z"
                fill="#636270"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.259 2.834a47.721 47.721 0 00-10.516 0c-2.012.225-3.637 1.81-3.873 3.832a45.922 45.922 0 000 10.67c.236 2.022 1.86 3.607 3.873 3.832a47.77 47.77 0 0010.516 0c2.011-.226 3.636-1.81 3.872-3.832a45.91 45.91 0 000-10.67c-.236-2.022-1.86-3.607-3.872-3.832zm-10.35 1.49a46.22 46.22 0 0110.184 0c1.33.15 2.395 1.199 2.55 2.517a44.416 44.416 0 010 10.32 2.89 2.89 0 01-2.55 2.515 46.21 46.21 0 01-10.184 0 2.89 2.89 0 01-2.55-2.515c-.401-3.429-.401-6.892 0-10.32a2.89 2.89 0 012.55-2.516v-.001z"
                fill="#636270"
            />
        </svg>
    )
}

export default Instagram

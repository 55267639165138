import * as React from "react"

function RadioButtonSVG(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 19.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15zm0 1.5a9 9 0 100-18 9 9 0 000 18z"
                fill="#68885D"
            />
            <path
                d="M12 17.25a5.25 5.25 0 100-10.5 5.25 5.25 0 000 10.5z"
                fill={props.type ? "#68885D":'white'}
            />
        </svg>
    )
}

export default RadioButtonSVG

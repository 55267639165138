import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import "./style.scss";

const Loader = () => (
    <div className="loader">
        <Spin indicator={<LoadingOutlined className='spin' spin/>} />
    </div>
)

export default Loader;
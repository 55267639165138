import React, {useCallback, useEffect, useMemo, useRef} from "react";
import {Link, useLocation, createSearchParams, useSearchParams} from "react-router-dom";
import {isEqual, isEmpty, uniqueId} from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {fetchProducts} from "state/products/operations";
import Account from "utils/account";
import {changUserLogged} from "state/users";

const getItem = (label, key, children) => ({label, key, children});

function useContainer() {
    const dispatch = useDispatch();
    const {pathname} = useLocation();
    const {productCategories} = useSelector(({products}) => products);
    const {isUserLogged} = useSelector(({users}) => users);
    const [searchParams] = useSearchParams();
    const ref = useRef(null);
    const prevProps = useRef({});
    const parentProductId = searchParams?.get('parent_id');

    useEffect(() => {
        if(Account.getAccessToken()) return;
        dispatch(changUserLogged());
    }, []);

    const params = useMemo(() => ({
        page: searchParams.get('page') || '1',
        categories: searchParams.get('categories') || [],
        type: searchParams.get('type') || [],
        tags: searchParams.get('tags') || [],
    }), [searchParams]);

    useEffect(() => {
        if (pathname === '/products') {
            ref.current?.close();
            if(isEqual(prevProps.current, params)) return;
            if(params.page === '1') delete params.page;
            if(parentProductId) {
                params.parent_id = parentProductId;
            }
            dispatch(fetchProducts(params));
            prevProps.current = params;
        }
    }, [params]);

    const menuItems = useMemo(() => ({
        products: {
            name: 'Products',
            path: '/products-categories',
            children: productCategories?.categories?.map(item => {
                item = {
                    name: item.label,
                    value: item.value,
                    path: '/products',
                    queryName: 'categories'
                }
                return item
            }) || [],
            // children: [
            //     {
            //         name: 'Coatings',
            //         children: productCategories?.categories?.map(item => {
            //             item = {
            //                 name: item.label,
            //                 value: item.value,
            //                 path: '/products',
            //                 queryName: 'categories'
            //             }
            //             return item
            //         }) || [],
            //     },
            //     {
            //         name: 'Floor types',
            //         children: productCategories?.type?.map(item => {
            //             item = {
            //                 name: item.label,
            //                 value: item.value,
            //                 path: '/products',
            //                 queryName: 'type'
            //             }
            //             return item
            //         }) || [],
            //     },
            //     {
            //         name: 'Sheen',
            //         children: productCategories?.tags?.map(item => {
            //             item = {
            //                 name: item.label,
            //                 value: item.value,
            //                 path: '/products',
            //                 queryName: 'tags'
            //             }
            //             return item
            //         }) || [],
            //     },
            // ],
        },
        services: {
            name: 'Services',
            path: '/services',
        },
        resources: {
            name: 'How to use',
            path: '/how-to-use',
        },
        // media: {
        //     name: 'Media',
        //     path: '/media',
        // },
        colorLab: {
            name: 'Colors',
            path: '/color-lab',
        },
        awards: {
            name: 'Awards',
            path: '/awards',
        },
        payments: isUserLogged && {
            name: 'Payments',
            path: '/user/payments'
        },
        technicalDataSheets: {
            name: 'Technical Data',
            path: '/technical-data-sheets'
        },
    }), [productCategories, isUserLogged]);

    const pathnameUpdateHandler = () => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant",
        });
    }

    useEffect(pathnameUpdateHandler, [pathname]);

    const getItems = useCallback((values) => values.map((item, index) => {
        if (!item.path) {
            item = getItem(
                <div className='item-link'>
                    {item?.name[0]?.toUpperCase()}{item?.name?.substring(1)?.toLocaleLowerCase()}
                </div>,
                item.path + item.name + index + uniqueId(),
                !isEmpty(item.children) ? getItems(item.children) : null);
            return item
        }

        const data = [item.value];
        const queryParams = {
            [item?.queryName]: String(data),
        }
        item = getItem(
            <Link className='item-link' to={{
                pathname: item.path,
                search: item?.queryName ? `?${createSearchParams(queryParams).toString()}` : '',
            }}>
                {/*{item?.name[0]?.toUpperCase()}{item?.name?.substring(1)?.toLocaleLowerCase()}*/}
                {item.name}
            </Link>,
            item.path + item.name + index + uniqueId(),
            !isEmpty(item.children) ? getItems(item.children) : null);
        return item;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);

    return {
        getItems,
        menuItems: Object.values(menuItems).filter(item => !!item),
        ref,
    }

}

export default useContainer;

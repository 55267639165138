import {useDispatch, useSelector} from "react-redux";
import {notification} from 'antd';
import {useEffect, useMemo, useState} from "react";
import {isEmpty} from "lodash";
import {showModal} from "state/modal";
import {getAllBasket} from "state/basket/operations";
import {addToCard, removeFromCard} from "state/products/operations";
import {getBasketId} from "utils/helpers";

function useContainer({color, price, basketData, title, id}) {


    const dispatch = useDispatch();
    const {deleteOrAddLoader} = useSelector(({products}) => products);
    const {productCategories} = useSelector(({products}) => products);
    const {isUserLogged, currentUser} = useSelector(({users}) => users);
    const [text, setText] = useState('')

    const [count, setCount] = useState(1)

    const [priseAndSize, setPriseAndSize] = useState(price[0] || {});
    // const [colorId, setColorId] = useState(color?.[0]?.id || 1);
    const [colorId, setColorId] = useState(null);
    const [colorName, setColorName] = useState('Check a color');
    const [selectedShen, setSelectedShen] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [borderErrors, setBorderErrors]= useState({sheens:false, type:false})


    const [tooltipText, setTooltipText] = useState('')
    const [tooltipSheenText, setTooltipSheenText] = useState('')

    const [api, contextHolder] = notification.useNotification();

    const handleCount = (value)=>{
        setCount(value)
    }
    const handleBorderErrors = (errorObj)=>{
        console.log(7777, errorObj)
        if(errorObj.sheens && errorObj.type){
            console.log('ayo')
            setBorderErrors({sheens:true, type:true})
            setTimeout(()=>{
                setBorderErrors({sheens:false, type:false})
            },3000)
            return
        }
        if (errorObj.sheens){
            setBorderErrors({...borderErrors, sheens: true})
            setTimeout(()=>{
                setBorderErrors({sheens:false, type:false})
            },3000)
            return;
        }
        if (errorObj.type){
            setBorderErrors({...borderErrors, type: true})
            setTimeout(()=>{
                setBorderErrors({sheens:false, type:false})
            },3000)
        }
    }



    const basketId = useMemo(() => {
        return getBasketId(basketData, colorId, priseAndSize.id, selectedShen, productCategories?.type?.find(e => e.label === selectedType)?.value);
    }, [colorId, priseAndSize, basketData, selectedShen, selectedType, productCategories?.type]);


    const getTooltipSheenText = (item) => {
        if (item.id === selectedShen) {
            setTooltipSheenText('uncheck')
        } else setTooltipSheenText('check')
    }

    const findElement = (arr) => {
        return !isEmpty(arr['type_data']) && arr['type_data'].find(el => el.title === selectedType)
    }


    const handleSizeChange = (value) => {

        const newData = price.reduce((acc, item) => {
            if (item.id === value) {
                //
                // if(!isEmpty(item?.type_data)){
                //
                // })
                // if (!isEmpty(item?.type_data)) {
                //
                //     if(!item.type_data.title){
                //
                //         setSelectedType(null)
                //     }
                //     if(!item.type_data.tag_id){
                //
                //         setSelectedShen(null)
                //     }
                //     if (selectedType && selectedShen) {
                //         finalPrice = item['type_data'].find(e => e.title === selectedType && e.tag_id === selectedShen)?.price
                //         newData = {...item, price: finalPrice}
                //     } else if (selectedShen) {
                //         finalPrice = item['type_data'].find(e => e.tag_id === selectedShen)?.price
                //         newData = {...item, price: finalPrice}
                //     } else {
                //
                //         finalPrice = item['type_data'].find(e => e.title === selectedType)?.price
                //         newData = {...item, price: finalPrice}
                //     }
                // }
                acc = {...item};
            }
            return acc;
        }, {});
        setPriseAndSize(newData);
        if (!findElement(newData)) {
            setSelectedType(null)
        }
    }

    const handleChangeSheens = (id) => {
        if (selectedShen === id) {
            setSelectedShen(null)
        } else setSelectedShen(id)
    }


    const handleChangeTypes = (title) => {
        if (selectedType === title) {
            setSelectedType(null)
        } else setSelectedType(title)

    }

    const handleChange = (name, id) => {
        setText('')
        if (colorId !== id) {
            setColorId(id);
            setColorName(name);
        } else {
            setColorId(null);
            setColorName('Check a color');
        }
    }

    const openNotificationWithIcon = (type, message) => {
        notification.open({
            message,
            type,
            duration: 2,

        });
    };


    const handleAddToBasket = async () => {
        let colorError = false
        const borderErrors = {sheens:false, type:false}

        if(isEmpty(price)){
            dispatch(showModal({modalType:"SERVICE_REQUEST_MODAL", modalProps:{title, id}}))
            return
        }



        const basketData = {
            user_id: currentUser?.id,
            products_id: +priseAndSize['product_id'],
            color_id: colorId,
            price_id: +priseAndSize.id,
            count: count,
            type_id: productCategories?.type?.find(e => e.label === selectedType)?.value || 0,
            tag_id: selectedShen || 0,
            price_type_id: null
        }
        if (!isEmpty(priseAndSize['type_data'])) {
            basketData.price_type_id = priseAndSize['type_data'].find(el => el.title === selectedType && el.tag_id === selectedShen)?.id || null
        }
        if(!isEmpty(color)){
                if(!colorId){
                    colorError = true
                }
            if (!isEmpty(priseAndSize['type_data'])) {
                const fountPrice = priseAndSize['type_data'].find(el => el.tag_id === selectedShen && el.title === selectedType)

                if(!fountPrice && !selectedShen){
                    borderErrors.sheens = true
                }
                if(!fountPrice && !selectedType){
                    borderErrors.type = true
                }
            }
        }else {
            if(isEmpty(getUniqueSheens) && isEmpty(getUniqueItems)){

            }else {

                const fountPrice = priseAndSize['type_data'].find(el => el.tag_id === selectedShen && el.title === selectedType)
                if(!fountPrice && !isEmpty(getUniqueSheens)){
                    borderErrors.sheens = true
                }
                if(!fountPrice && !isEmpty(getUniqueItems)){
                    borderErrors.type = true
                }
            }
        }
        if(colorError && !borderErrors.sheens && !borderErrors.type){
            openNotificationWithIcon('error', 'Add some color')
            return
        }
        if(borderErrors.sheens || borderErrors.type){
            openNotificationWithIcon('error', 'Please choose all required fields')
            handleBorderErrors(borderErrors)
            return
        }


        if (!basketId) {
            openNotificationWithIcon('success', 'Added to Cart')
            await dispatch(addToCard(basketData))
        } else {
            openNotificationWithIcon('error', 'Removed from Cart')
            await dispatch(removeFromCard({id: basketId}))
        }
        dispatch(getAllBasket())
    }

    useEffect(() => {
        if (isEmpty(priseAndSize?.type_data)) return;
        let finalPrice = ''

        if (selectedType && selectedShen) {
            finalPrice = priseAndSize['type_data'].find(e => e.title === selectedType && e.tag_id === selectedShen)?.price
            finalPrice && setPriseAndSize({...priseAndSize, price: finalPrice})

        } else if (selectedShen) {
            finalPrice = priseAndSize['type_data'].find(e => e.tag_id === selectedShen)?.price
            finalPrice && setPriseAndSize({...priseAndSize, price: finalPrice})
        } else {
            finalPrice = priseAndSize['type_data'].find(e => e.title === selectedType)?.price
            finalPrice && setPriseAndSize({...priseAndSize, price: finalPrice})
        }

    }, [selectedType, selectedShen])

    const getUniqueItems = useMemo(() => {
        const arr = priseAndSize?.type_data
        const uniqueItems = [];
        const uniqueValues = [];

        for (let i = 0; i < arr?.length; i++) {
            let currentItem = arr[i];
            let type_id = currentItem['type_id'];
            if (!uniqueValues.includes(type_id) && type_id) {
                uniqueItems.push(currentItem);
                uniqueValues.push(type_id)
            }
        }
        return uniqueItems
    }, [priseAndSize])


    const getUniqueSheens = useMemo(() => {
        const arr = priseAndSize?.type_data
        const uniqueItems = [];
        const uniqueValues = [];

        for (let i = 0; i < arr?.length; i++) {
            let currentItem = arr[i];
            let tag_id = currentItem['tag_id'];
            if (!uniqueValues.includes(tag_id) && tag_id) {
                uniqueItems.push(currentItem);
                uniqueValues.push(tag_id)
            }
        }
        return uniqueItems
    }, [priseAndSize])


    return {
        colorId,
        basketId,
        colorName,
        priseAndSize,
        deleteOrAddLoader,
        selectedShen,
        selectedType,
        handleChange,
        handleSizeChange,
        handleAddToBasket,
        handleChangeSheens,
        handleChangeTypes,
        tooltipText,
        tooltipSheenText,
        getTooltipSheenText,
        getUniqueItems,
        getUniqueSheens,
        setColorId,
        setColorName,
        count,
        handleCount,
        text,
        setText,
        borderErrors
    }
}

export default useContainer;

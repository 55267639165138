import React from 'react';
import {Result} from 'antd';
import {Link} from "react-router-dom";
import ButtonWithSvg from "views/shared/ButtonWithSvg";

const ExtraComponent = () => (
    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
        <Link to='/'><ButtonWithSvg title='Back Home'/></Link>
    </div>
)

const PageNotFound = () => (
    <div className='page-not-found'>
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={<ExtraComponent/>}
        />
    </div>
)

export default PageNotFound;
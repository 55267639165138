import React, {memo} from "react";
import {embedVideo} from "utils/helpers";
import "./style.scss";

const VideoComponent = ({video = '', image}) => {
    return (
        <div className='video-component'>
            <iframe
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                src={embedVideo(video)}
                allowFullScreen
                style={{width: '100%', height: '100%', objectFit: 'cover', border: 'none'}}
                title="Iframe Example"></iframe>
        </div>
    )
};

export default memo(VideoComponent, (prevProps, nextProps) => {
    if (prevProps.video !== nextProps.video) return false;
    return prevProps.image === nextProps.image;
});

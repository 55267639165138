import React, {useEffect} from 'react';
import {Form, Rate} from "antd";
import {FormikProvider} from "formik";

import useContainer from "./hook";
import "./style.scss";
import InputFiled from "../shared/forms/InputField";
import ButtonWithSvg from "../shared/ButtonWithSvg";

const AddressForm = ({}) => {
    // const {formik} = useContainer();
    // const messageMeta = formik.getFieldMeta('message');
    // const starsMeta = formik.getFieldMeta('stars');


    return (

        <>
            <InputFiled
                className="input"
                name="region"
                formItemClassName="form-item"
                placeholder='Country/region'
            />
            <div className='lineContainer'>
                <InputFiled
                    className="input"
                    name="first_name"
                    formItemClassName="form-item short"
                    placeholder='First name'
                />
                <InputFiled
                    className="input"
                    name="last_name"
                    formItemClassName="form-item short"
                    placeholder='Last name'
                />
            </div>
            <div className='lineContainer'>
                <InputFiled
                    className="input"
                    name="address_first"
                    formItemClassName="form-item short"
                    placeholder='Address Line 1'
                />
                <InputFiled
                    className="input"
                    name="address_last"
                    formItemClassName="form-item short"
                    placeholder='Address Line 2'
                />
            </div>
            <div className='lineContainer'>
                <InputFiled
                    className="input"
                    name="city"
                    formItemClassName="form-item short"
                    placeholder='City'
                />
                <InputFiled
                    className="input"
                    name="zip_code"
                    type='number'
                    formItemClassName="form-item short"
                    placeholder='ZIP code'
                />
            </div>
            <InputFiled
                className="input"
                name="phone"
                type='number'
                formItemClassName="form-item"
                placeholder='Phone number'
            />
        </>
    )
}

export default AddressForm;

import React, {Suspense, useEffect, useMemo, useState} from 'react';
import {Elements} from "@stripe/react-stripe-js";
import {Button, Modal, Spin} from "antd";
import {LoadingOutlined} from '@ant-design/icons';
import CheckoutForm from "./CheckoutForm";
import useContainer from "../../../views/Basket/hook";
import useContainer2 from "./hook";
import "./style.scss";
import {isEmpty} from "lodash";
import PaymentModalCard from "./PaymentModalCard";
import numeral from "numeral";
import {useDispatch, useSelector} from "react-redux";
import {getAllBasket} from "../../../state/basket/operations";
import {checkCouponPercent} from "../../../state/payment/operations";
import CouponSVG from "../../../assets/svg/CouponSVG";

const PaymentModal = ({onClose, amount, shippingMode}) => {

    const dispatch = useDispatch()
    const {basketData} = useSelector(({basket}) => basket);
    const zipData = useSelector((state) => state.zip);
    const taxData = useSelector((state) => state.zip.tax);


    const {sellPercent, message, errorMessage} = useSelector(state => state.payment)
    const [loadings, setLoadings] = useState([]);

    const enterLoading = (index) => {
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = true;
            return newLoadings;
        });
        setTimeout(() => {
            setLoadings((prevLoadings) => {
                const newLoadings = [...prevLoadings];
                newLoadings[index] = false;
                return newLoadings;
            });
        }, 1500);
    };


    useEffect(() => {
        dispatch(getAllBasket())
        return () => {
            dispatch(getAllBasket())
        }
    }, []);

    const {
        isFetchingBasket,
        opePaymentModal,
        // basketData,
        totalPrice,
        countPopupHandler,
        showCountPopup,
        setShowCountPopup,
        checkPolicy,
        checkedPolicy,
        setCoupon,
        coupon
    } = useContainer();

    const sumWithTax = useMemo(() => {

        const num = numeral(totalPrice + totalPrice * taxData?.['TaxRate']).format('0,0')
        if (zipData.deliveryTotal) {
            return numeral(totalPrice + totalPrice * taxData?.['TaxRate']+zipData.deliveryTotal).format('0,0')
        }
        return num
    }, [totalPrice, taxData])

    const handleCoupon = ({target: {value}}) => {
        setCoupon(value)
    }

    const handleApplyCoupon = async () => {
        if (coupon.length) {
            enterLoading(0)
            dispatch(checkCouponPercent({coupon}))
        }
    }


    const sumWithTaxWithPercent = useMemo(() => {
        if (!sellPercent) return {}
        const x = totalPrice + totalPrice * taxData?.['TaxRate']
        const y = sumWithTax - sumWithTax * sellPercent / 100


        return {sumWithTax: numeral(x).format('0,0'), sumWithY: numeral(y).format('0,0')}
    }, [totalPrice, taxData, sellPercent])





    const {visible, stripePromise, isFetchingStripe} = useContainer2();

    return (
        <Modal onCancel={onClose} footer={null} open width={'1140px'} className='payment-modal'>
            <Suspense fallback={<></>}>
                {isFetchingStripe ? <div className='stripe-loader'>
                        <Spin indicator={<LoadingOutlined style={{fontSize: 50, color: '#AECB7B'}} spin/>}/>
                    </div> :
                    <div className='payment-modal-main'>


                        <div className='leftSide'>
                            {isEmpty(message) && message !== 'success' && (
                                <div className='leftSideContent'>
                                    <div className='scrollContainer'>
                                        {!isEmpty(basketData.data) &&
                                            basketData.data.map(e =>
                                                <PaymentModalCard
                                                    countPopupHandler={countPopupHandler}
                                                    showCountPopup={showCountPopup}
                                                    key={e.id}
                                                    {...e}
                                                />
                                            )}
                                    </div>


                                    <div className='couponInputContainer'>
                                        <div className='card-number-content Coupon'>
                                            <div className='svgIcon'>
                                                <CouponSVG/>
                                            </div>

                                            <input type="text" className='element inputElement' onInput={handleCoupon}
                                                   placeholder='Coupon Code'/>

                                            <Button type="primary" className='couponButton' loading={loadings[0]}
                                                    onClick={handleApplyCoupon}>
                                                {loadings[0] ? '' : 'Apply'}
                                            </Button>
                                        </div>


                                        {/*<div className='couponButton' onClick={handleApplyCoupon}>*/}
                                        {/*    <span>Apply</span>*/}
                                        {/*</div>*/}

                                    </div>
                                    {errorMessage && <p className='errorMessage'>Invalid Coupon Code</p>}
                                    {sellPercent && <p className='sellMessage'>You have {sellPercent}% sell</p>}


                                    <div className='shippingContainer'>
                                        <div className='line'>
                                            <p>Subtotal</p>
                                            <span>${numeral(totalPrice).format('0,0')}</span>
                                        </div>
                                        <div className='line'>
                                            <p>Shipping</p>
                                            <span>${zipData.deliveryTotal}</span>
                                        </div>
                                        <div className='line'>
                                            <p>Tax Implantation</p>
                                            <span>${taxData?.['TaxRate'] ? numeral(totalPrice * taxData?.['TaxRate']).format('0,0') : 0}</span>
                                        </div>
                                        {/*<p>Country - {zipData?.['country abbreviation']}</p>*/}
                                        {/*<p>Tax Rate - {taxData ? taxData['TaxRate'] : ''}</p>*/}
                                        {/*<p className='stateText'>Place - {zipData?.places?.map(e =>*/}
                                        {/*    <p>{e['place name']},{e['state']}, </p>)}*/}
                                        {/*</p>*/}
                                    </div>
                                    {sellPercent ? <>
                                            <div className='line'>
                                                <del> Total:</del>
                                                <del>${sumWithTax}</del>
                                            </div>
                                            <div className='line'>
                                                <p> Total:</p>
                                                <span>${sumWithTaxWithPercent.sumWithY}</span>
                                            </div>
                                        </>
                                        :
                                        <div className='line'>
                                            <p> Total:</p>
                                            <span>${sumWithTax}</span>
                                        </div>
                                    }


                                    {/*{taxData && sellPercent ? <>*/}
                                    {/*    <p className='totalPrice'>Total*/}
                                    {/*        Price: <del>${numeral(sumWithTax).format('0,0') || 0}</del></p>*/}
                                    {/*    <p className='totalPrice'>New*/}
                                    {/*        price: <span>${sumWithTaxWithPercent.sumWithY}</span></p>*/}
                                    {/*</> : <p className='totalPrice'>Total : <span>${sumWithTax}</span></p>}*/}
                                    {/*/!*<p className='totalPrice'> Price: <span>${numeral(totalPrice).format('0,0') || 0}</span></p>}*!/*/}


                                </div>)}
                            <div className='payment-content'>
                                {visible && (
                                    <Elements stripe={stripePromise}>
                                        <CheckoutForm amount={amount} basketData={basketData}
                                                      shippingMode={shippingMode} zipData={zipData}/>
                                    </Elements>
                                )}
                            </div>
                        </div>

                    </div>
                }
            </Suspense>
        </Modal>
    )
}

export default PaymentModal;

import * as React from "react"

function CalendarIcon(props) {
    return (
        <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 122.88 122.89"
            {...props}
        >
            <path d="M81.61 4.73c0-2.61 2.58-4.73 5.77-4.73s5.77 2.12 5.77 4.73v20.72c0 2.61-2.58 4.73-5.77 4.73s-5.77-2.12-5.77-4.73V4.73zm-15.5 100.93c-.8 0-.8-10.1 0-10.1H81.9c.8 0 .8 10.1 0 10.1zM15.85 68.94c-.8 0-.8-10.1 0-10.1h15.79c.8 0 .8 10.1 0 10.1zm25.13 0c-.8 0-.8-10.1 0-10.1h15.79c.8 0 .8 10.1 0 10.1zm25.13 0c-.8 0-.8-10.1 0-10.1H81.9c.8 0 .8 10.1 0 10.1zm25.14-10.1H107c.8 0 .8 10.1 0 10.1H91.25c-.8 0-.8-10.1 0-10.1zM15.85 87.3c-.8 0-.8-10.1 0-10.1h15.79c.8 0 .8 10.1 0 10.1zm25.15 0c-.8 0-.8-10.1 0-10.1h15.77c.8 0 .8 10.1 0 10.1zm25.13 0c-.8 0-.8-10.1 0-10.1H81.9c.8 0 .8 10.1 0 10.1zm25.14 0c-.8 0-.8-10.1 0-10.1H107c.8 0 .8 10.1 0 10.1zm-75.4 18.36c-.8 0-.8-10.1 0-10.1h15.77c.8 0 .8 10.1 0 10.1zm25.13 0c-.8 0-.8-10.1 0-10.1h15.77c.8 0 .8 10.1 0 10.1zM29.61 4.73c0-2.61 2.58-4.73 5.77-4.73s5.77 2.12 5.77 4.73v20.72c0 2.61-2.58 4.73-5.77 4.73s-5.77-2.12-5.77-4.73V4.73zM6.4 43.47h110.07v-22a3 3 0 00-.86-2.07 2.92 2.92 0 00-2.07-.86H103a3.2 3.2 0 010-6.4h10.55a9.36 9.36 0 019.33 9.33v92.09a9.36 9.36 0 01-9.33 9.33H9.33A9.36 9.36 0 010 113.55V21.47a9.36 9.36 0 019.33-9.33H20.6a3.2 3.2 0 110 6.4H9.33a3 3 0 00-2.07.86 2.92 2.92 0 00-.86 2.07v22zm110.08 6.41H6.4v63.67a3 3 0 00.86 2.07 2.92 2.92 0 002.07.86h104.22a3 3 0 002.07-.86 2.92 2.92 0 00.86-2.07V49.88zM50.43 18.54a3.2 3.2 0 010-6.4h21.49a3.2 3.2 0 110 6.4z" />
        </svg>
    )
}

export default CalendarIcon

import yup from 'utils/yupLocalised';


const validationSchema = yup.object().shape({
    first_name: yup.string().min(3).required(),
    last_name: yup.string().min(3).required(),
    phone: yup.string(),
    email: yup.string().email().required(),
    services_id: yup.string().required(),
    description: yup.string().min(10).required(),
});

export default validationSchema;

import React from 'react';
import {isEmpty} from "lodash";
import OwlCarousel from "react-owl-carousel";
import OilCard from "views/shared/OilCard";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./style.scss";

const AlsoLike = ({data}) => {
    return (
        <div className='also-like'>
            <div className='title-content'>
                <h2 className='title content-title'>You may also like</h2>
            </div>
            <div className='content'>
                {!isEmpty(data) &&
                    <OwlCarousel className="owl-theme" margin={130} dots={false} responsiveClass lazyLoad nav
                                 responsive={{
                                     0: {items: 1},
                                     400: {items: 1, margin: 0},
                                     600: {items: 2, margin: 0},
                                     685: {items: 2, margin: 100},
                                     1050: {items: 3},
                                     1285: {items: 3, margin: 20},
                                     1440: {items: 4, margin: 10},
                                 }}
                    >
                        {data?.map(item => (
                            <OilCard
                                title={item?.title}
                                image={item?.img}
                                price={item?.price}
                                id={item?.id}
                                key={item?.id}
                                imageClassName='oil-card-image'
                                cardClassName='card'
                                seeMoreClassName='see-more-visible'
                                withSeeMore
                            />
                        ))}
                    </OwlCarousel>
                }
            </div>
        </div>
    )
}

export default AlsoLike;
